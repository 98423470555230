/**
 * @Description: 机构设置组件
 * @Author: Kermit
 * @Date: 2022-12-02 17:36:15
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-30 18:29:46
 */

import React from 'react';
import './InstitutionSetting.css';
import {
  addMyInstitutionGroup,
  addMyInstitutionGroupLeader,
  getGroupList,
  getInstitutionInfo,
  updateMyInstitutionInfo,
  uploadHeadPicUrl,
} from '@app/api-client';
import { Button, Divider, Form, FormInstance, Input, Menu, MenuProps, message, Select, Upload, UploadFile } from 'antd';
import { TeamOutlined, PlusOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { checkIsNeedCustomCookie, getCustomCookieHeader } from '@app/config/customize';

export interface IInstitutionSettingProps {
  institutionId: string;
}

export interface IInstitutionSettingState {
  tab: string;
  isUpdating: boolean;
  isHeadpicFileListInit: boolean;
  headpicFileList: UploadFile[];
  groupHeadpicFileList: UploadFile[];
  groupSelectorOptions: { value: string; label: string }[];

  /** 用户机构名 */
  institutionName?: string;
  /** 机构头像 */
  institutionHeadUrl?: string;
  /** 机构简介 */
  institutionDescription?: string;
  institutionWebsite?: string;
  /** 是否我的 */
  isMine?: boolean;
  /** 是否是管理员 */
  isMineLeader?: boolean;
}

type MenuItem = Required<MenuProps>['items'][number];

export default class InstitutionSetting extends React.Component<IInstitutionSettingProps, IInstitutionSettingState> {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'general',
      isUpdating: false,
      isHeadpicFileListInit: false,
      headpicFileList: [],
      groupHeadpicFileList: [],
      groupSelectorOptions: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getInstitutionInfo();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  async getInstitutionInfo() {
    const res = await getInstitutionInfo(this.props.institutionId);
    this.setState({
      institutionName: res.institution_name,
      institutionHeadUrl: res.head_url,
      institutionDescription: res.description,
      institutionWebsite: res.website,
    });

    this.state.tab === 'general' && this.initGeneralForm(res.institution_name, res.description, res.website);
    this.state.tab === 'general' && this.initUploadHeadPicFileList(res.head_url);
    this.state.tab === 'new_group_leader' && this.initGroupSelectorOptions();
  }

  getMenuItem(key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'institution'): MenuItem {
    return {
      key,
      label: this.getMenuLabel(key),
      icon,
      children,
      type,
    } as MenuItem;
  }

  getMenuLabel(key: React.Key) {
    switch (key) {
      case 'general':
        return '基本信息';
      case 'new_group':
        return '新增研究组';
      case 'new_group_leader':
        return '新增研究组管理员';
      default:
        return '';
    }
  }

  /** 菜单栏项 */
  items: MenuItem[] = [
    this.getMenuItem('general', <SettingOutlined />),
    this.getMenuItem('new_group', <TeamOutlined />),
    this.getMenuItem('new_group_leader', <UserOutlined />),
  ];

  /** 选择菜单项事件 */
  onSelectTab({ key }) {
    const tab = key;
    this.setState({ tab }, () => {
      tab === 'general' &&
        this.initGeneralForm(
          this.state.institutionName || '',
          this.state.institutionDescription || '',
          this.state.institutionWebsite || '',
        );
      tab === 'general' && this.initUploadHeadPicFileList(this.state.institutionHeadUrl || '');
      tab === 'new_group_leader' && this.initGroupSelectorOptions();
    });
  }

  /** 基本信息设置表单引用 */
  generalFormRef = React.createRef<FormInstance>();
  /** 初始化基本信息设置表单 */
  initGeneralForm(institutionName: string, description: string, website?: string) {
    this.generalFormRef.current!.setFieldsValue({
      institution_name: institutionName,
      description,
      website,
    });
  }
  /** 初始化基本信息表单 */
  async onGeneralFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      let headPic: string | undefined = undefined;
      if (this.state.headpicFileList.length > 0) {
        headPic = this.state.headpicFileList[0].url;
      }
      await updateMyInstitutionInfo(headPic, values.institution_name, values.description, values.website);
      message.success('更新成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '更新失败');
    }
  }
  initUploadHeadPicFileList(headUrl: string) {
    const headpicFileList: UploadFile[] = headUrl
      ? [
          {
            uid: '-1',
            name: 'file',
            status: 'done',
            url: headUrl,
          },
        ]
      : [];
    this.setState({ headpicFileList, isHeadpicFileListInit: true });
  }
  onHeadpicFileListChange = ({ fileList }) => {
    this.setState({
      headpicFileList: fileList.map((item) => ({
        url: item.response?.data?.filepath ? uploadHeadPicUrl + '/' + item.response?.data?.filepath : undefined,
        ...item,
      })),
    });
  };

  /** 研究组新增设置表单引用 */
  newGroupFormRef = React.createRef<FormInstance>();
  /** 初始化研究组新增表单 */
  async onNewGroupFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      let headPic: string | undefined = undefined;
      if (this.state.groupHeadpicFileList.length > 0) {
        headPic = this.state.groupHeadpicFileList[0].url;
      }
      await addMyInstitutionGroup(values.group_name, headPic, values.description);
      message.success('新增研究组成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '新增研究组失败');
    }
  }
  onGroupHeadpicFileListChange = ({ fileList }) => {
    this.setState({
      groupHeadpicFileList: fileList.map((item) => ({
        url: item.response?.data?.filepath ? uploadHeadPicUrl + '/' + item.response?.data?.filepath : undefined,
        ...item,
      })),
    });
  };
  async getGroupList() {
    const res = await getGroupList(this.props.institutionId);
    return res.list.map((group) => ({
      groupId: group.group_id,
      groupName: group.group_name,
      headUrl: group.head_url,
      description: group.description,
      isMine: group.is_mine,
    }));
  }
  async initGroupSelectorOptions() {
    const groupList = await this.getGroupList();
    this.setState({
      groupSelectorOptions: groupList.map((group) => ({
        value: group.groupId,
        label: group.groupName,
      })),
    });
  }

  /** 研究组管理员新增设置表单引用 */
  groupLeaderFormRef = React.createRef<FormInstance>();
  /** 初始化研究组管理员新增表单 */
  async onNewGroupLeaderFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      await addMyInstitutionGroupLeader(values.group_id, values.user_id);
      message.success('新增研究组管理员成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '新增研究组管理员失败');
    }
  }

  render(): React.ReactNode {
    return (
      <div className="institution-setting-container">
        <div className="institution-setting-menu">
          <Menu
            mode="inline"
            selectedKeys={[this.state.tab]}
            onSelect={this.onSelectTab.bind(this)}
            style={{ width: '100%' }}
            items={this.items}
          />
        </div>
        <div className="institution-setting-content">
          <div className="institution-setting-content-header">
            <Divider orientation="left" style={{ fontSize: 18 }}>
              {this.getMenuLabel(this.state.tab)}
            </Divider>
          </div>
          <div className="institution-setting-content-body">
            {/* 基本信息设置 */}
            {this.state.tab === 'general' && (
              <div className="form-wrapper">
                <Form
                  name="basic"
                  ref={this.generalFormRef}
                  onFinish={this.onGeneralFormFinish.bind(this)}
                  autoComplete="off"
                >
                  <div className="form-title">机构名</div>
                  <div className="form-input">
                    <Form.Item name="institution_name" rules={[{ required: true, message: '请输入机构名' }]}>
                      <Input size="middle" placeholder="输入机构名" />
                    </Form.Item>
                  </div>
                  <div className="form-title">头像</div>
                  <div className="form-upload">
                    {this.state.isHeadpicFileListInit && (
                      <Upload
                        name="file"
                        listType="picture-card"
                        accept="image/gif,image/jpeg,image/jpg,image/png"
                        withCredentials
                        defaultFileList={this.state.headpicFileList}
                        action={uploadHeadPicUrl}
                        onChange={this.onHeadpicFileListChange.bind(this)}
                        headers={checkIsNeedCustomCookie() ? getCustomCookieHeader() : {}}
                      >
                        {this.state.headpicFileList.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上传</div>
                          </div>
                        )}
                      </Upload>
                    )}
                  </div>
                  <div className="form-title">机构简介</div>
                  <div className="form-input">
                    <Form.Item name="description">
                      <Input.TextArea
                        autoSize={{ minRows: 5, maxRows: 10 }}
                        size="middle"
                        placeholder="输入机构简介（可选）"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-title">机构网站</div>
                  <div className="form-input">
                    <Form.Item name="website" rules={[{ type: 'url', message: '不是一个有效的 URL 链接' }]}>
                      <Input size="middle" placeholder="输入机构网站（可选）" />
                    </Form.Item>
                  </div>

                  <div className="btn-wrapper">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                        {this.state.isUpdating ? '更新中...' : '更新'}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}

            {/* 研究组新增 */}
            {this.state.tab === 'new_group' && (
              <div className="form-wrapper">
                <Form
                  name="basic"
                  ref={this.newGroupFormRef}
                  onFinish={this.onNewGroupFormFinish.bind(this)}
                  autoComplete="off"
                >
                  <div className="form-title">研究组名</div>
                  <div className="form-input">
                    <Form.Item name="group_name" rules={[{ required: true, message: '请输入研究组名' }]}>
                      <Input size="middle" placeholder="输入研究组名" />
                    </Form.Item>
                  </div>
                  <div className="form-title">头像</div>
                  <div className="form-upload">
                    <Upload
                      name="file"
                      listType="picture-card"
                      accept="image/gif,image/jpeg,image/jpg,image/png"
                      withCredentials
                      defaultFileList={this.state.groupHeadpicFileList}
                      action={uploadHeadPicUrl}
                      onChange={this.onGroupHeadpicFileListChange.bind(this)}
                      headers={checkIsNeedCustomCookie() ? getCustomCookieHeader() : {}}
                    >
                      {this.state.groupHeadpicFileList.length >= 1 ? null : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>上传</div>
                        </div>
                      )}
                    </Upload>
                  </div>
                  <div className="form-title">研究组简介</div>
                  <div className="form-input">
                    <Form.Item name="description">
                      <Input.TextArea
                        autoSize={{ minRows: 5, maxRows: 10 }}
                        size="middle"
                        placeholder="输入研究组简介（可选）"
                      />
                    </Form.Item>
                  </div>

                  <div className="btn-wrapper">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                        {this.state.isUpdating ? '提交中...' : '提交'}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}

            {/* 研究组管理员新增 */}
            {this.state.tab === 'new_group_leader' && (
              <div className="form-wrapper">
                <Form
                  name="basic"
                  ref={this.groupLeaderFormRef}
                  onFinish={this.onNewGroupLeaderFormFinish.bind(this)}
                  autoComplete="off"
                >
                  <div className="form-title">选择研究组</div>
                  <div className="form-input">
                    <Form.Item name="group_id" rules={[{ required: true, message: '请选择研究组' }]}>
                      <Select options={this.state.groupSelectorOptions}>
                        <Select.Option value="demo">Demo</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="form-title">输入用户名</div>
                  <div className="form-input">
                    <Form.Item name="user_id" rules={[{ required: true, message: '请输入用户名' }]}>
                      <Input size="middle" placeholder="输入用户名" />
                    </Form.Item>
                  </div>

                  <div className="btn-wrapper">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                        {this.state.isUpdating ? '提交中...' : '提交'}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
