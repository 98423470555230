/**
 * @Description:
 * @Author: Kermit
 * @Date: 2023-05-02 15:36:23
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-02 16:21:12
 */

import React from 'react';
import './EmbedComponent.css';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AlgoCard from '@app/components/algo-card/AlgoCard';
import { getQueryString } from '@app/utils';

export default function EmbedComponent(props) {
  const params = useParams();
  return <CEmbedComponent name={params.name as string} {...props}></CEmbedComponent>;
}

export interface IEmbedComponentProps {
  name: string;
}

export interface IEmbedComponentState {
  a?: string;
}

class CEmbedComponent extends React.Component<IEmbedComponentProps, IEmbedComponentState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  render(): React.ReactNode {
    return (
      <div className="p-embed-component">
        {/**
         * 算法应用卡片组件
         * 用法：/component/algo-card?name=xxx&version=xxx
         * name: 算法名称
         * version: 算法版本
         */}
        {this.props.name === 'algo-card' && (
          <>
            <Helmet>
              <title>应用卡片组件 · AlgoSpace</title>
            </Helmet>
            <AlgoCard
              name={getQueryString('name') || ''}
              version={getQueryString('version') || ''}
              isAutoFetch={true}
            ></AlgoCard>
          </>
        )}
      </div>
    );
  }
}
