/**
 * @Description:
 * @Author: Kermit
 * @Date: 2022-11-24 13:52:21
 * @LastEditors: Kermit
 * @LastEditTime: 2023-01-16 20:27:07
 */

import React, { Component } from 'react';
import './Footer.css';

export default class Footer extends Component {
  render() {
    return (
      <footer className="c-footer">
        <div className="row link">
          <span>关于我们</span>
          <span>用户协议</span>
          <span>隐私协议</span>
          <span style={{ borderRight: 'none' }}>
            <a href="http://beian.miit.gov.cn">京ICP备2022034816号-1</a>
          </span>
        </div>
      </footer>
    );
  }
}
