/**
 * @Description: 研究组主页
 * @Author: Kermit
 * @Date: 2022-11-30 16:14:31
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-03 15:15:24
 */

import React from 'react';
import './GroupPage.css';
import GlobalLayout from '@app/layout-components/global-layout/GlobalLayout';
import { useParams, useSearchParams } from 'react-router-dom';
import { Avatar, Card, Divider, Tabs, Tag, Typography } from 'antd';
import { getGroupInfo } from '@app/api-client';
import AlgoList from '@app/components/algo-list/AlgoList';
import { HddOutlined, HomeOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import AlgoRearch from '@app/components/algo-search/AlgoRearch';
import Meta from 'antd/es/card/Meta';
import MemberList from '@app/components/member-list/MemberList';
import MemberSet from '@app/components/member-set/MemberSet';
import { InstitutionPageLink } from '@app/routes/Links';
import { locationToGroupPageTab } from '@app/routes/location';
import GroupSetting from './group-setting/GroupSetting';

export default function GroupPage(props) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  return (
    <CGroupPage
      groupId={params.groupId as string}
      tab={params.tab}
      search={searchParams.get('name')}
      {...props}
    ></CGroupPage>
  );
}

export interface IGroupPageProps {
  groupId: string;
  tab?: string;
  search?: string;
}

export interface IGroupPageState {
  /** 用户研究组名 */
  groupName?: string;
  /** 研究组头像 */
  groupHeadUrl?: string;
  /** 研究组简介 */
  groupDescription?: string;
  groupWebsite?: string;
  groupTags?: string[];
  /** 机构 ID */
  institutionId?: string;
  /** 用户机构名 */
  institutionName?: string;
  /** 机构头像 */
  institutionHeadUrl?: string;
  /** 机构简介 */
  institutionDescription?: string;
  /** 是否我的 */
  isMine?: boolean;
  /** 是否是管理员 */
  isMineLeader?: boolean;
}

class CGroupPage extends React.Component<IGroupPageProps, IGroupPageState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getGroupInfo();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  async getGroupInfo() {
    const res = await getGroupInfo(this.props.groupId);
    this.setState({
      groupName: res.group_name,
      groupHeadUrl: res.head_url,
      groupDescription: res.description,
      groupWebsite: res.website,
      groupTags: res.tags,
      institutionId: res.institution?.institution_id,
      institutionName: res.institution?.institution_name,
      institutionHeadUrl: res.institution?.head_url,
      institutionDescription: res.institution?.description,
      isMine: res.is_mine,
      isMineLeader: res.is_mine_leader,
    });
  }

  onSearchNameConform(value: string) {
    locationToGroupPageTab(this.props.groupId, 'algo', { name: value });
  }

  onTabClick(key: string) {
    locationToGroupPageTab(this.props.groupId, key);
  }

  getTabItems() {
    const items = [
      {
        label: (
          <span>
            <HddOutlined />
            算法
          </span>
        ),
        key: 'algo',
        children: (
          <>
            {!this.props.search && (
              <AlgoList
                title={`置顶算法应用`}
                groupFilter={this.props.groupId}
                isPin={true}
                isHidePagination={true}
                isHideOrderBy={true}
                isDisplayNoneWhenEmpty={!this.state.isMineLeader}
                isShowEditPin={this.state.isMineLeader}
              />
            )}
            <AlgoList
              title={`${this.state.groupName}的算法应用`}
              groupFilter={this.props.groupId}
              nameFilter={this.props.search}
              isOnlyLatest={true}
            />
          </>
        ),
      },
      {
        label: (
          <span>
            <UserOutlined />
            成员
          </span>
        ),
        key: 'member',
        children: (
          <MemberList canUpdateSequence={this.state.isMineLeader} id={this.props.groupId} type="GROUP"></MemberList>
        ),
      },
    ];

    if (this.state.isMineLeader) {
      items.push({
        label: (
          <span>
            <SettingOutlined />
            设置
          </span>
        ),
        key: 'setting',
        children: <GroupSetting groupId={this.props.groupId}></GroupSetting>,
      });
    }

    return items;
  }

  render(): React.ReactNode {
    return (
      <GlobalLayout isHeaderTopicBackgroud isHeaderWhiteColor title={this.state.groupName || '研究组'}>
        <div className="p-group-page">
          {/* 顶栏 */}
          <div className="top-container">
            <div className="detail-wrapper">
              <div className="group-body-box">
                <div className="group-head-pic">
                  {this.state.groupHeadUrl ? (
                    <Avatar size="large" src={this.state.groupHeadUrl} />
                  ) : (
                    <Avatar size="small" icon={<UserOutlined />} />
                  )}
                </div>
                <div className="group-info">
                  <div className="group-title">
                    <span style={{ marginRight: '0.5em' }}>{this.state.groupName}</span>
                    {this.state.groupWebsite && (
                      <Tag icon={<HomeOutlined />}>
                        <a target="_blank" rel="noreferrer" href={this.state.groupWebsite}>
                          {this.state.groupWebsite}
                        </a>
                      </Tag>
                    )}
                    {this.state.isMine && <Tag color="blue">我的</Tag>}
                    {this.state.isMineLeader && <Tag color="blue">我管理的</Tag>}
                  </div>
                  {this.state.groupTags && this.state.groupTags.length > 0 && (
                    <div className="group-line">
                      {this.state.groupTags?.map((tag) => {
                        const [tagContent, tagColor] = tag.split('###');
                        return (
                          <Tag color={tagColor} key={tag}>
                            {tagContent}
                          </Tag>
                        );
                      })}
                    </div>
                  )}
                  <div className="group-description">
                    <Typography.Paragraph ellipsis={{ rows: 5, expandable: true, symbol: '展开' }}>
                      {this.state.groupDescription}
                    </Typography.Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-wrapper"></div>
          </div>

          {/* 主栏 */}
          <div className="group-page-container">
            {/* 左侧 */}
            <div className="side-layout">
              <AlgoRearch
                size="large"
                placeholder={`搜索${this.state.groupName}的算法应用`}
                groupFilter={this.props.groupId}
                onSearch={this.onSearchNameConform.bind(this)}
                value={this.props.search}
              />
              <div className="divider-container">
                <Divider orientation="center">成员</Divider>
              </div>
              <div className="common-card-container member-card-container">
                <MemberSet id={this.props.groupId} type="GROUP" />
              </div>
              <div className="divider-container">
                <Divider orientation="center">所属</Divider>
              </div>
              <div className="common-card-container">
                <Card style={{ width: '100%' }}>
                  <Meta
                    title={
                      <InstitutionPageLink institutionId={this.state.institutionId || ''}>
                        <span className="op-hover">{this.state.institutionName}</span>
                      </InstitutionPageLink>
                    }
                    avatar={
                      <InstitutionPageLink institutionId={this.state.institutionId || ''}>
                        <Avatar className="op-hover" src={this.state.institutionHeadUrl} />
                      </InstitutionPageLink>
                    }
                    description={
                      <Typography.Paragraph ellipsis={{ rows: 5, expandable: true, symbol: '展开' }}>
                        {this.state.institutionDescription}
                      </Typography.Paragraph>
                    }
                  />
                </Card>
              </div>
            </div>
            {/* 右侧 */}
            <div className="body-layout">
              <Tabs
                activeKey={this.props.tab || 'algo'}
                type="card"
                onTabClick={this.onTabClick.bind(this)}
                items={this.getTabItems()}
              />
            </div>
          </div>
        </div>
      </GlobalLayout>
    );
  }
}
