/**
 * @Description:
 * @Author: Kermit
 * @Date: 2022-12-26 15:03:28
 * @LastEditors: Kermit
 * @LastEditTime: 2022-12-30 17:08:28
 */

import React from 'react';
import './CloudDeploy.css';
import { Button, Steps } from 'antd';
import CloudDeployUpload from '../cloud-deploy-upload/CloudDeployUpload';
import { getAlgoCloudDeployStatus, getUploadFileList } from '@app/api-client';
import CloudDeployFile from '../cloud-deploy-file/CloudDeployFile';
import CloudDeployConfig from '../cloud-deploy-config/CloudDeployConfig';
import CloudDeployProcess from '../cloud-deploy-process/CloudDeployProcess';

export interface ICloudDeployProps {
  algoname?: string;
  version?: string;
}

export interface ICloudDeployState {
  algoname: string;
  version: string;
  currStep: number;
  isPrevActive: boolean;
  isNextActive: boolean;
  isHidePrevAndNext: boolean;

  isNeedUpload: boolean;
  isConfigComplete: boolean;
  isDeployProcessing: boolean;
}

export default class CloudDeploy extends React.Component<ICloudDeployProps, ICloudDeployState> {
  constructor(props) {
    super(props);
    this.state = {
      algoname: props.algoname || '',
      version: props.version || '',
      currStep: 0,
      isPrevActive: false,
      isNextActive: false,
      isHidePrevAndNext: false,
      isNeedUpload: true,
      isConfigComplete: false,
      isDeployProcessing: false,
    };
  }

  /** 生命周期：挂载后 */
  async componentDidMount() {
    await this.initStep();
    await this.refreshStepBtnStatus();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 获取第一步是否需要上传 */
  refreshIsNeedUpload() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if ((!this.props.algoname || !this.props.version) && (!this.state.algoname || !this.state.version)) {
          return this.setState({ isNeedUpload: true });
        }
        const { list } = await getUploadFileList(
          this.props.algoname || this.state.algoname,
          this.props.version || this.state.version,
        );
        this.setState({ isNeedUpload: !list || list.length === 0 }, () => resolve());
      } catch (err) {
        reject(err);
      }
    });
  }

  onNameChange(name: string) {
    this.setState({ algoname: name });
  }

  onVersionChange(version: string) {
    this.setState({ version: version });
  }

  async onUploadComplete() {
    await this.refreshStepBtnStatus();
  }

  /** 删除了所有文件 */
  async onDeleteAllFile() {
    await this.refreshStepBtnStatus();
  }

  /** 完成了配置填写 */
  onConfigChange(value: boolean) {
    this.setState({ isConfigComplete: value }, () => this.refreshStepBtnStatus());
  }

  /** 状态变化 */
  onProcessChange(isProcessing: boolean) {
    this.setState({ isDeployProcessing: isProcessing }, () => this.refreshStepBtnStatus());
  }

  /** 初始化当前步骤 */
  initStep() {
    return new Promise<void>(async (resolve) => {
      try {
        let currStep = 0;
        if (this.props.algoname && this.props.version) {
          const { status } = await getAlgoCloudDeployStatus(this.props.algoname, this.props.version);
          if (status === 'deployed') {
            currStep = 3;
          } else if (status !== 'unready') {
            currStep = 2;
          }
        }
        this.setState({ currStep }, () => resolve());
      } catch (err) {
        resolve();
      }
    });
  }

  /** 刷新下一步上一步按钮可用状态 */
  async refreshStepBtnStatus() {
    if (this.state.currStep === 0) {
      // 第一步
      await this.refreshIsNeedUpload();
      this.setState((state) => ({ isPrevActive: false, isNextActive: !state.isNeedUpload, isHidePrevAndNext: false }));
    }
    if (this.state.currStep === 1) {
      // 第二步
      this.setState({ isPrevActive: true, isNextActive: this.state.isConfigComplete, isHidePrevAndNext: false });
    }
    if (this.state.currStep === 2) {
      // 第三步
      this.setState({ isPrevActive: !this.state.isDeployProcessing, isNextActive: false, isHidePrevAndNext: false });
    }
    if (this.state.currStep === 3) {
      // 完成所有步骤
      this.setState({ isHidePrevAndNext: true });
    }
  }

  /** 下一步 */
  nextStep() {
    this.setState(
      (state) => ({ currStep: state.currStep + 1 }),
      () => this.refreshStepBtnStatus(),
    );
  }

  /** 上一步 */
  prevStep() {
    this.setState(
      (state) => ({ currStep: state.currStep - 1 }),
      () => this.refreshStepBtnStatus(),
    );
  }

  render(): React.ReactNode {
    const stepItems = [
      {
        title: '上传算法',
      },
      {
        title: '填写配置',
      },
      {
        title: '构建部署',
      },
    ];

    return (
      <div className="c-cloud-delpoy">
        <div className="steps-container">
          <Steps current={this.state.currStep} items={stepItems} />
        </div>
        <div className="steps-content">
          {/* 第一步 */}
          {this.state.currStep === 0 &&
            (this.state.isNeedUpload ? (
              <CloudDeployUpload
                algoname={this.props.algoname}
                version={this.props.version}
                onNameChange={this.onNameChange.bind(this)}
                onVersionChange={this.onVersionChange.bind(this)}
                onUploadComplete={this.onUploadComplete.bind(this)}
              ></CloudDeployUpload>
            ) : (
              <CloudDeployFile
                algoname={this.props.algoname || this.state.algoname}
                version={this.props.version || this.state.version}
                onDeleteAllFile={this.onDeleteAllFile.bind(this)}
              ></CloudDeployFile>
            ))}

          {/* 第二步 */}
          {this.state.currStep === 1 && (
            <CloudDeployConfig
              algoname={this.props.algoname || this.state.algoname}
              version={this.props.version || this.state.version}
              onConfigChange={this.onConfigChange.bind(this)}
            ></CloudDeployConfig>
          )}

          {/* 第三步 */}
          {(this.state.currStep === 2 || this.state.currStep === 3) && (
            <CloudDeployProcess
              algoname={this.props.algoname || this.state.algoname}
              version={this.props.version || this.state.version}
              onProcessChange={this.onProcessChange.bind(this)}
            ></CloudDeployProcess>
          )}
        </div>
        {!this.state.isHidePrevAndNext && (
          <div className="steps-action">
            <Button
              size="large"
              disabled={!(this.state.currStep > 0 && this.state.isPrevActive)}
              style={{ marginRight: '0.5em' }}
              block
              onClick={this.prevStep.bind(this)}
            >
              上一步
            </Button>
            <Button
              size="large"
              disabled={!(this.state.currStep < stepItems.length - 1 && this.state.isNextActive)}
              type="primary"
              block
              onClick={this.nextStep.bind(this)}
            >
              下一步
            </Button>
          </div>
        )}
      </div>
    );
  }
}
