/**
 * @Description: 可拖动列表
 * @Author: Kermit
 * @Date: 2022-12-06 15:46:23
 * @LastEditors: Kermit
 * @LastEditTime: 2022-12-06 17:47:18
 */

import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import React from 'react';
import { SortableItem } from './SortableItem';

export default function DragList(props: {
  containers: { id: string; children: React.ReactElement }[];
  onDragEnd: (idList: string[]) => void;
  isShowDragger?: boolean;
}) {
  const idList = props.containers.map(({ id }) => id);
  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={idList}>
        {props.containers.map(({ id, children }) => (
          <SortableItem isShowDragger={props.isShowDragger} key={id} id={id}>
            {children}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    const idList = props.containers.map(({ id }) => id);
    const activeIndex = event.active.data.current.sortable.index;
    const overIndex = event.over.data.current.sortable.index;
    if (activeIndex === overIndex) {
      return;
    }

    idList.splice(activeIndex, 1);
    idList.splice(overIndex, 0, event.active.id);

    props.onDragEnd(idList);
  }
}
