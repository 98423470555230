/**
 * @Description: 研究组主页
 * @Author: Kermit
 * @Date: 2022-11-30 16:14:31
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-03 15:14:56
 */

import React from 'react';
import './InstitutionPage.css';
import GlobalLayout, { Nav } from '@app/layout-components/global-layout/GlobalLayout';
import { useParams, useSearchParams } from 'react-router-dom';
import { Avatar, Divider, message, Popconfirm, Tabs, Tag, Typography } from 'antd';
import { getInstitutionInfo, getMyInfo, updateMyUserInfo } from '@app/api-client';
import AlgoList from '@app/components/algo-list/AlgoList';
import {
  HddOutlined,
  HomeOutlined,
  PlusCircleFilled,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import AlgoRearch from '@app/components/algo-search/AlgoRearch';
import MemberList from '@app/components/member-list/MemberList';
import MemberSet from '@app/components/member-set/MemberSet';
import { locationToInstitutionPageTab } from '@app/routes/location';
import GroupList from '@app/components/group-list/GroupList';
import InstitutionSetting from './institution-setting/IntitutionSetting';
import { getCurrInstitution } from '@app/config/customize';

export default function InstitutionPage(props) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  return (
    <CInstitutionPage
      institutionId={params.institutionId as string}
      tab={params.tab}
      search={searchParams.get('name')}
      {...props}
    ></CInstitutionPage>
  );
}

export interface IInstitutionPageProps {
  institutionId: string;
  tab?: string;
  search?: string;
}

export interface IInstitutionPageState {
  /** 用户机构名 */
  institutionName?: string;
  /** 机构头像 */
  institutionHeadUrl?: string;
  /** 机构简介 */
  institutionDescription?: string;
  institutionWebsite?: string;
  /** 是否我的 */
  isMine?: boolean;
  /** 是否是管理员 */
  isMineLeader?: boolean;

  isMineNoInstitution?: boolean;
}

class CInstitutionPage extends React.Component<IInstitutionPageProps, IInstitutionPageState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getInstitutionInfo();
    this.getMyInstitutionInfo();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  async getInstitutionInfo() {
    const res = await getInstitutionInfo(this.props.institutionId);
    this.setState({
      institutionName: res.institution_name,
      institutionHeadUrl: res.head_url,
      institutionDescription: res.description,
      institutionWebsite: res.website,
      isMine: res.is_mine,
      isMineLeader: res.is_mine_leader,
    });
  }

  async getMyInstitutionInfo() {
    const { permission, institution } = await getMyInfo();
    this.setState({
      isMineNoInstitution: !institution && permission === 'NORMAL',
    });
  }

  async addInstitution() {
    await updateMyUserInfo(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.props.institutionId,
    );
    message.success('已加入', 1, () => {
      window.location.reload();
    });
  }

  onSearchNameConform(value: string) {
    locationToInstitutionPageTab(this.props.institutionId, 'algo', { name: value });
  }

  onTabClick(key: string) {
    locationToInstitutionPageTab(this.props.institutionId, key);
  }

  getTabItems() {
    const items = [
      {
        label: (
          <span>
            <HddOutlined />
            算法
          </span>
        ),
        key: 'algo',
        children: (
          <>
            {!this.props.search && (
              <AlgoList
                title={`置顶算法应用`}
                institutionFilter={this.props.institutionId}
                isPin={true}
                isHidePagination={true}
                isHideOrderBy={true}
                isDisplayNoneWhenEmpty={!this.state.isMineLeader}
                isShowEditPin={this.state.isMineLeader}
              />
            )}
            <AlgoList
              title={`${this.state.institutionName}的算法应用`}
              institutionFilter={this.props.institutionId}
              nameFilter={this.props.search}
              isOnlyLatest={true}
            />
          </>
        ),
      },
      {
        label: (
          <span>
            <TeamOutlined />
            研究组
          </span>
        ),
        key: 'group',
        children: (
          <GroupList canUpdateSequence={this.state.isMineLeader} institution_id={this.props.institutionId}></GroupList>
        ),
      },
      {
        label: (
          <span>
            <UserOutlined />
            成员
          </span>
        ),
        key: 'member',
        children: (
          <MemberList
            canUpdateSequence={this.state.isMineLeader}
            id={this.props.institutionId}
            type="INSTITUTION"
          ></MemberList>
        ),
      },
      {
        label: (
          <span>
            <UserSwitchOutlined />
            校友
          </span>
        ),
        key: 'normal_user',
        children: (
          <MemberList canUpdateSequence={false} id={this.props.institutionId} type="INSTITUTION_NORMAL"></MemberList>
        ),
      },
    ];

    if (this.state.isMineLeader) {
      items.push({
        label: (
          <span>
            <SettingOutlined />
            设置
          </span>
        ),
        key: 'setting',
        children: <InstitutionSetting institutionId={this.props.institutionId}></InstitutionSetting>,
      });
    }

    return items;
  }

  render(): React.ReactNode {
    return (
      <GlobalLayout
        isHeaderTopicBackgroud
        isHeaderWhiteColor
        {...(getCurrInstitution() ? { activeNav: Nav.INSTITUTION } : {})}
        title={this.state.institutionName || '机构'}
      >
        <div className="p-institution-page">
          {/* 顶栏 */}
          <div className="top-container">
            <div className="detail-wrapper">
              <div className="institution-body-box">
                <div className="institution-head-pic">
                  {this.state.institutionHeadUrl ? (
                    <Avatar size="large" src={this.state.institutionHeadUrl} />
                  ) : (
                    <Avatar size="small" icon={<UserOutlined />} />
                  )}
                </div>
                <div className="institution-info">
                  <div className="institution-title">
                    <span style={{ marginRight: '0.5em' }}>{this.state.institutionName}</span>
                    {this.state.institutionWebsite && (
                      <Tag icon={<HomeOutlined />}>
                        <a target="_blank" rel="noreferrer" href={this.state.institutionWebsite}>
                          {this.state.institutionWebsite}
                        </a>
                      </Tag>
                    )}
                    {this.state.isMine && <Tag color="blue">我的</Tag>}
                    {this.state.isMineLeader && <Tag color="blue">我管理的</Tag>}
                    {this.state.isMineNoInstitution && (
                      <Popconfirm
                        title={`确认加入？即将作为校友身份加入，一旦加入则不可更改`}
                        onConfirm={this.addInstitution.bind(this)}
                        okText="确认"
                        cancelText="取消"
                      >
                        <Tag color="#5864ff" icon={<PlusCircleFilled />} style={{ cursor: 'pointer' }}>
                          加入校友
                        </Tag>
                      </Popconfirm>
                    )}
                  </div>
                  <div className="institution-description">
                    <Typography.Paragraph ellipsis={{ rows: 5, expandable: true, symbol: '展开' }}>
                      {this.state.institutionDescription}
                    </Typography.Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-wrapper"></div>
          </div>

          {/* 主栏 */}
          <div className="institution-page-container">
            {/* 左侧 */}
            <div className="side-layout">
              <AlgoRearch
                size="large"
                placeholder={`搜索${this.state.institutionName}的算法应用`}
                institutionFilter={this.props.institutionId}
                onSearch={this.onSearchNameConform.bind(this)}
                value={this.props.search}
              />
              <div className="divider-container">
                <Divider orientation="center">成员</Divider>
              </div>
              <div className="common-card-container member-card-container">
                <MemberSet id={this.props.institutionId} type="INSTITUTION" />
              </div>
            </div>
            {/* 右侧 */}
            <div className="body-layout">
              <Tabs
                activeKey={this.props.tab || 'algo'}
                type="card"
                onTabClick={this.onTabClick.bind(this)}
                items={this.getTabItems()}
              />
            </div>
          </div>
        </div>
      </GlobalLayout>
    );
  }
}
