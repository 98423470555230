/**
 * @Description:
 * @Author: Kermit
 * @Date: 2022-11-25 14:41:04
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-15 17:48:04
 */

import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { checkIsLogin, getMyInfo, loginVisitor, logout, setIsCredentialsReady } from '@app/api-client';
import { sleep } from '@app/utils';
import { Avatar, Button, Dropdown, MenuProps } from 'antd';
import React from 'react';
import LoginDrawer from '../login-drawer/LoginDrawer';
import './LoginEntry.css';
import { UserPermission } from '@app/constants/user';
import { GroupPageLink, InstitutionPageLink, PublishLink, UserPageLink, UserSettingLink } from '@app/routes/Links';

export interface ILoginEntryProps {
  a?: string;
}

export interface ILoginEntryState {
  isLogin: boolean;
  isOpenDrawer: boolean;

  userId?: string;
  nickname?: string;
  headUrl?: string;
  description?: string;
  permission?: string;
  institution?: {
    institutionId: string;
    institutionName: string;
    headUrl: string;
    description: string;
  };
  group?: {
    groupId: string;
    groupName: string;
    headUrl: string;
    description: string;
  };
}

export default class LoginEntry extends React.Component<ILoginEntryProps, ILoginEntryState> {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      isOpenDrawer: false,
    };
  }

  /** 生命周期：挂载后 */
  async componentDidMount() {
    await this.checkLoginStatus();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 检查登录状态，没登录则登录访客 */
  async checkLoginStatus() {
    try {
      await checkIsLogin();
      setIsCredentialsReady(); // 设置 Cookie 登录态准备好了
      const {
        user_id: userId,
        nickname,
        head_url: headUrl,
        description,
        permission,
        institution,
        group,
      } = await getMyInfo();
      if (permission != UserPermission.VISITOR) {
        this.setState({ isLogin: true });
      }
      this.setState({
        userId,
        nickname,
        headUrl,
        description,
        permission,
      });
      if (institution) {
        this.setState({
          institution: {
            institutionId: institution.institution_id,
            institutionName: institution.institution_name,
            headUrl: institution.head_url,
            description: institution.description,
          },
        });
      }
      if (group) {
        this.setState({
          group: {
            groupId: group.group_id,
            groupName: group.group_name,
            headUrl: group.head_url,
            description: group.description,
          },
        });
      }
    } catch (err) {
      await loginVisitor();
      setIsCredentialsReady(); // 设置 Cookie 登录态准备好了
    }
  }

  async logout() {
    await logout();
    window.location.reload();
  }

  openDrawer() {
    this.setState({ isOpenDrawer: true });
  }

  closeDrawer() {
    this.setState({ isOpenDrawer: false });
  }

  async onLogin() {
    this.setState({ isLogin: true });
    sleep(1000);
    window.location.reload();
  }

  getMenuItems() {
    const items: MenuProps['items'] = [
      {
        key: '0',
        type: 'group',
        label: (
          <PublishLink>
            <Button type="primary" icon={<PlusCircleOutlined />}>
              创建算法应用
            </Button>
          </PublishLink>
        ),
      },
      {
        key: '1',
        type: 'group',
        label: '控制台',
        children: [
          {
            key: '1-1',
            label: (
              <UserPageLink userId={this.state.userId || ''}>
                <span>我的算法应用</span>
              </UserPageLink>
            ),
          },
          {
            key: '1-2',
            disabled: !this.state.group,
            label: (
              <GroupPageLink groupId={this.state.group?.groupId || ''}>
                <span>我的研究组</span>
              </GroupPageLink>
            ),
          },
          {
            key: '1-3',
            disabled: !this.state.institution,
            label: (
              <InstitutionPageLink institutionId={this.state.institution?.institutionId || ''}>
                <span>我的机构</span>
              </InstitutionPageLink>
            ),
          },
        ],
      },
      {
        key: '2',
        type: 'group',
        label: '用户',
        children: [
          {
            key: '2-1',
            label: (
              <UserSettingLink>
                <span>设置</span>
              </UserSettingLink>
            ),
          },
          {
            key: '2-2',
            label: <a onClick={this.logout.bind(this)}>退出</a>,
          },
        ],
      },
    ];
    return items;
  }

  render(): React.ReactNode {
    return (
      <>
        <div className="c-login-entry">
          {this.state.isLogin ? (
            <Dropdown menu={{ items: this.getMenuItems() }} placement="bottomLeft">
              {this.state.headUrl ? (
                <Avatar src={this.state.headUrl} />
              ) : (
                <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
              )}
            </Dropdown>
          ) : (
            <Avatar
              icon={<UserOutlined />}
              style={{ backgroundColor: '#bfbfbf' }}
              onClick={this.openDrawer.bind(this)}
            />
          )}
        </div>
        <LoginDrawer
          isOpen={this.state.isOpenDrawer}
          onLogin={this.onLogin.bind(this)}
          onClose={this.closeDrawer.bind(this)}
        ></LoginDrawer>
      </>
    );
  }
}
