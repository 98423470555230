/**
 * @Description:
 * @Author: Kermit
 * @Date: 2022-12-26 15:50:37
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-07 10:37:05
 */

import React from 'react';
import './CloudDeployUpload.css';
import { Form, FormInstance, Input, Progress, Upload, UploadFile, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { enrollAlgo, uploadAlgoFile, uploadAlgoFileUrl } from '@app/api-client';
import { sleep } from '@app/utils';

export interface ICloudDeployUploadProps {
  algoname?: string;
  version?: string;
  onNameChange: (name: string) => void;
  onVersionChange: (version: string) => void;
  onUploadComplete: () => void;
}

export interface ICloudDeployUploadState {
  algoname: string;
  version: string;
  isEnrolled: boolean;
  fileNum: number;
  uploadedFileNum: number;
}

export default class CloudDeployUpload extends React.Component<ICloudDeployUploadProps, ICloudDeployUploadState> {
  constructor(props) {
    super(props);
    this.state = {
      algoname: props.algoname || '',
      version: props.version || '',
      isEnrolled: false,
      fileNum: 0,
      uploadedFileNum: 0,
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 代码上传设置表单引用 */
  uploadFormRef = React.createRef<FormInstance>();
  /** 初始化代码上传设置表单 */
  initGeneralForm(userId: string, nickname: string, description: string) {
    this.uploadFormRef.current!.setFieldsValue({
      user_id: userId,
      nickname,
      description,
    });
  }

  onNameChange(e) {
    this.setState({ algoname: e.target.value }, () => this.props.onNameChange(this.state.algoname));
  }

  onVersionChange(e) {
    this.setState({ version: e.target.value }, () => this.props.onVersionChange(this.state.version));
  }

  private isEnrolling = false;
  private isEnrolled = false;
  private isEnrollFailed = false;
  private readonly maxUploadFileNum = 5; // 最大上传文件数
  private currUploadFileNum = 0; // 当前上传文件数
  /** 上传前注册算法，并避免同时上传个数过多 */
  async onBeforeFileUpload() {
    try {
      await this.uploadFormRef.current!.validateFields();
    } catch (err) {
      return false;
    }

    if (!this.isEnrolling && !this.isEnrolled) {
      try {
        this.isEnrolling = true;
        this.isEnrolled = false;
        this.isEnrollFailed = false;
        await enrollAlgo(this.state.algoname, this.state.version);
        this.isEnrolling = false;
        this.isEnrolled = true;
        this.isEnrollFailed = false;
        this.setState({ isEnrolled: true });
      } catch (err: any) {
        this.isEnrolling = false;
        this.isEnrolled = false;
        this.isEnrollFailed = true;
        message.error(`注册算法失败: ${err?.message}`, 10);
        return false;
      }
    } else {
      while (!this.isEnrolled) {
        if (this.isEnrollFailed) {
          return false;
        }
        await sleep(1000);
      }
    }

    if (this.currUploadFileNum >= this.maxUploadFileNum) {
      return false;
    } else {
      this.currUploadFileNum += 1;
      return true;
    }
  }

  getUploadBodyData(file: UploadFile | File) {
    let realFile: File;
    if (file instanceof File) {
      realFile = file;
    } else {
      realFile = (file as UploadFile).originFileObj as File;
    }

    return {
      dir_path: realFile.webkitRelativePath.split('/').slice(1, -1).join('/'),
      is_cover: true,
    };
  }

  private fileList: UploadFile[] = []; // 上传文件列表
  /** 监听文件夹上传 */
  async onFileChange(info: UploadChangeParam<UploadFile>) {
    if (this.state.fileNum === 0 && ['uploading', 'done', 'error'].includes(info.file.status || '')) {
      this.fileList = info.fileList; // 有文件上传时才更新 fileList，全部被拦截时不会更新
      this.setState({ fileNum: info.fileList.length });
    }
    if (info.file.status === 'done') {
      // 上传成功
      this.currUploadFileNum -= 1;
      this.setState(
        (state) => ({ uploadedFileNum: state.uploadedFileNum + 1 }),
        () => this.checkUploadComplete(),
      );
    }
    if (info.file.status === 'error' || info.file.status == undefined) {
      // 上传错误则重传，被拦截看情况重传
      // 被 beforeUpload 拦截的文件直接是 File 对象，且没有 status 属性
      let realFile: RcFile;
      if (info.file.status == undefined) {
        // 被 beforeUpload 拦截的文件 File 对象属性不全，需要从 fileList 中找到对应的 UploadFile 对象

        // fileList 一直找不到表示 beforeUpload 拦截了所有文件，此时不需要重传
        let currFile: UploadFile | undefined;
        let waitingTimes = 0;
        while (!(currFile = this.fileList.find((item) => item.uid === info.file.uid)) && waitingTimes < 2) {
          await sleep(Math.random() * 1000);
          waitingTimes += 1;
        }
        if (!currFile) {
          return;
        }

        realFile = currFile.originFileObj as RcFile;
      } else {
        realFile = info.file.originFileObj as RcFile;
      }
      const recursionUpload = async () => {
        try {
          // 重传前等待
          while (this.currUploadFileNum >= this.maxUploadFileNum) {
            await sleep(Math.random() * 1000);
          }
          this.currUploadFileNum += 1;

          await uploadAlgoFile(
            this.state.algoname,
            this.state.version,
            realFile,
            realFile.webkitRelativePath.split('/').slice(1, -1).join('/'),
            true,
          );
          // 重传成功
          this.currUploadFileNum -= 1;
          this.setState(
            (state) => ({ uploadedFileNum: state.uploadedFileNum + 1 }),
            () => this.checkUploadComplete(),
          );
        } catch (err) {
          this.currUploadFileNum -= 1;
          console.error(err);
          await sleep(1000);
          await recursionUpload();
        }
      };
      await recursionUpload();
    }
  }

  checkUploadComplete() {
    if (this.state.fileNum === this.state.uploadedFileNum) {
      // 上传完成
      this.props.onUploadComplete();
    }
  }

  render(): React.ReactNode {
    return (
      <div className="c-cloud-deploy-upload">
        <Form name="basic" ref={this.uploadFormRef} autoComplete="off">
          {!this.props.algoname && (
            <>
              <div className="form-title">算法应用名</div>
              <div className="form-input">
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: '请输入算法应用名' },
                    {
                      pattern: new RegExp('^[a-zA-Z0-9_\\-@]+$'),
                      message: '算法应用名只能包含字母、数字和 _ - @ 字符',
                    },
                  ]}
                >
                  <Input
                    disabled={Boolean(this.props.algoname) || this.state.fileNum > 0 || this.state.isEnrolled}
                    placeholder="算法应用名"
                    size="middle"
                    onChange={this.onNameChange.bind(this)}
                  />
                </Form.Item>
              </div>
            </>
          )}
          {!this.props.version && (
            <>
              <div className="form-title">版本号</div>
              <div className="form-input">
                <Form.Item
                  name="version"
                  rules={[
                    { required: true, message: '请输入版本号' },
                    {
                      pattern: new RegExp('^[a-zA-Z0-9\\.]+$'),
                      message: '版本号只能包含字母、数字和 . 字符',
                    },
                  ]}
                >
                  <Input
                    disabled={Boolean(this.props.version) || this.state.fileNum > 0 || this.state.isEnrolled}
                    placeholder="版本号"
                    size="middle"
                    onChange={this.onVersionChange.bind(this)}
                  />
                </Form.Item>
              </div>
            </>
          )}

          {(!this.props.algoname || !this.props.version) && <div className="form-title">算法代码</div>}
          <div className="form-upload">
            <Upload.Dragger
              name="file"
              action={uploadAlgoFileUrl(this.state.algoname, this.state.version)}
              directory
              withCredentials
              disabled={this.state.fileNum > 0}
              showUploadList={false}
              beforeUpload={this.onBeforeFileUpload.bind(this)}
              data={this.getUploadBodyData.bind(this)}
              onChange={this.onFileChange.bind(this)}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">上传算法文件夹</p>
              <p className="ant-upload-hint">点击选择或拖拽算法文件夹到此处</p>
            </Upload.Dragger>
          </div>
          {this.state.fileNum > 0 && (
            <div className="form-progress">
              <Progress percent={Math.floor(100 * (this.state.uploadedFileNum / this.state.fileNum))} status="active" />
            </div>
          )}
        </Form>
      </div>
    );
  }
}
