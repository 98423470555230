/**
 * @Description:
 * @Author: Kermit
 * @Date: 2023-03-15 17:37:25
 * @LastEditors: Kermit
 * @LastEditTime: 2023-03-15 18:51:49
 */

import { getAlgoList, updateMyGroupInfo, updateMyInstitutionInfo, updateMyUserInfo } from '@app/api-client';
import { AlgoScope } from '@app/constants/algo';
import { Checkbox, Input, message, Modal, Pagination, Tag } from 'antd';
import React from 'react';
import './AlgoPinModal.css';

export interface IAlgoPinModalProps {
  isModalOpen: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  onCompleted?: () => void;

  institutionFilter?: string;
  groupFilter?: string;
  userFilter?: string;
}

export interface IAlgoPinModalState {
  nameFilter: string;

  page: number;
  perpage: number;
  pages: number;
  total: number;

  pinList: {
    name: string;
    version: string;
  }[];
  /** 算法列表 */
  list: {
    /** 算法名 */
    name: string;
    /** 算法版本 */
    version: string;
    /** 是否可以调用 */
    canCall: boolean;
    /** 算法描述 */
    description: string;
    /** 用户名 */
    username: string;
    /** 用户昵称 */
    usernickname: string;
    /** 用户头像 */
    userHeadUrl: string;
    /** 机构 ID */
    institutionId: string;
    /** 用户机构名 */
    institutionName: string;
    /** 机构头像 */
    institutionHeadUrl: string;
    /** 机构简介 */
    institutionDescription: string;
    /** 研究组 ID */
    groupId: string;
    /** 用户研究组名 */
    groupName: string;
    /** 研究组头像 */
    groupHeadUrl: string;
    /** 研究组简介 */
    groupDescription: string;
    /** 算法可见范围 */
    scope: AlgoScope;
    /** 算法任务领域 */
    tasks?: Task[];
    /** 创建时间 */
    createTime: string;
    /** 中文名 */
    chineseName: string;
    /** 是否是我的 */
    isMine: boolean;
  }[];

  confirmLoading?: boolean;
}

type Task = {
  id: string;
  name: string;
  classify: string;
  description: string;
  tag_color: string;
  icon: string;
};

export default class AlgoPinModal extends React.Component<IAlgoPinModalProps, IAlgoPinModalState> {
  constructor(props) {
    super(props);
    this.state = {
      nameFilter: '',
      page: 1,
      perpage: 10,
      pages: 0,
      total: 0,
      pinList: [],
      list: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.handleSearch();
    this.getPinList();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 获取置顶列表 */
  async getPinList() {
    const { list } = await getAlgoList(1, 100, {
      ...(this.props.institutionFilter ? { institution_id: this.props.institutionFilter } : {}),
      ...(this.props.institutionFilter ? { is_institution_pin: true } : {}),
      ...(this.props.groupFilter ? { group_id: this.props.groupFilter } : {}),
      ...(this.props.groupFilter ? { is_group_pin: true } : {}),
      ...(this.props.userFilter ? { user_id: this.props.userFilter } : {}),
      ...(this.props.userFilter ? { is_user_pin: true } : {}),
    });
    this.setState({
      pinList: list.map((item) => ({
        name: item.name,
        version: item.version,
      })),
    });
  }

  /** 搜索 */
  async handleSearch() {
    const { list, page, perpage, pages, total } = await getAlgoList(this.state.page, this.state.perpage, {
      ...(this.state.nameFilter ? { name: this.state.nameFilter } : {}),
      ...(this.props.institutionFilter ? { institution_id: this.props.institutionFilter } : {}),
      ...(this.props.groupFilter ? { group_id: this.props.groupFilter } : {}),
      ...(this.props.userFilter ? { user_id: this.props.userFilter } : {}),
    });
    this.setState({
      page,
      perpage,
      pages,
      total,
      list: list.map((item) => ({
        name: item.name,
        version: item.version,
        canCall: item.can_call,
        description: item.description,
        username: item.user.user_id,
        usernickname: item.user.nickname,
        userHeadUrl: item.user.head_url,
        institutionId: item.user.institution?.id,
        institutionName: item.user.institution?.institution_name,
        institutionHeadUrl: item.user.institution?.head_url,
        institutionDescription: item.user.institution?.description,
        groupId: item.user.group?.id,
        groupName: item.user.group?.group_name,
        groupHeadUrl: item.user.group?.head_url,
        groupDescription: item.user.group?.description,
        scope: item.scope,
        tasks: item.tasks,
        createTime: item.create_day,
        chineseName: item.chinese_name,
        isMine: item.is_mine,
      })),
    });
  }

  async handleOk() {
    try {
      this.setState({ confirmLoading: true });
      const pinAlgo = this.state.pinList.map((item) => `${item.name}###${item.version}`);
      if (this.props.userFilter) {
        await updateMyUserInfo(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          pinAlgo,
        );
      } else if (this.props.groupFilter) {
        await updateMyGroupInfo(undefined, undefined, undefined, undefined, undefined, pinAlgo);
      } else if (this.props.institutionFilter) {
        await updateMyInstitutionInfo(undefined, undefined, undefined, undefined, pinAlgo);
      }
      this.setState({ confirmLoading: false });
      this.props.onOk?.();
      this.props.onCompleted?.();
    } catch (e) {
      message.error((e as any).message);
      this.setState({ confirmLoading: false });
    }
  }

  handleCancel() {
    this.props.onCancel?.();
    this.props.onCompleted?.();
  }

  onSeachInput(e) {
    this.setState({ nameFilter: e.target.value, page: 1 }, () => this.handleSearch());
  }

  onCheckboxChange(checked: boolean, name: string, version: string) {
    if (this.state.pinList.length >= 20 && checked) {
      return message.error('最多只能置顶 20 个算法应用');
    }

    if (checked) {
      this.setState({ pinList: [...this.state.pinList, { name, version }] });
    } else {
      this.setState({ pinList: this.state.pinList.filter((item) => item.name !== name || item.version !== version) });
    }
  }

  onPageChange(page: number, perpage: number) {
    this.setState({ page, perpage }, () => this.handleSearch());
  }

  render(): React.ReactNode {
    return (
      <Modal
        className="c-algo-pin-modal"
        title="选择要置顶的算法应用"
        open={this.props.isModalOpen}
        onOk={this.handleOk.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        confirmLoading={this.state.confirmLoading || false}
      >
        <div className="search-container">
          <Input placeholder="搜索算法应用" onInput={this.onSeachInput.bind(this)} />
        </div>

        <div className="checkbox-container">
          {this.state.list.map((item) => (
            <div key={item.name + '_' + item.version} className="checkbox-item">
              <Checkbox
                onChange={(e) => this.onCheckboxChange(e.target.checked, item.name, item.version)}
                checked={this.state.pinList.some((i) => i.name === item.name && i.version === item.version)}
              >
                <span style={{ marginRight: '0.5em' }}>{item.chineseName || item.name}</span>
                <Tag>{item.version}</Tag>
              </Checkbox>
            </div>
          ))}
        </div>

        <div className="pagi-container">
          <Pagination
            responsive
            size="small"
            onChange={this.onPageChange.bind(this)}
            pageSize={this.state.perpage}
            current={this.state.page}
            total={this.state.total}
          />
        </div>
      </Modal>
    );
  }
}
