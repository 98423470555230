/**
 * @Description API
 * @Author Kermit
 * @Date 2022-11-23 17:17:12
 * @LastEditors Kermit
 * @LastEditTime 2023-05-15 18:08:34
 */

import { WrappedAxios } from './WrappedAxios';
import { domain, rucDomain, sessionCookieName } from '@app/config';
import qs from 'qs';
import { UserPermission } from '@app/constants/user';
import { delCookie } from '@app/utils/cookie';
import { checkIsNeedCustomCookie, deleteCustomCookieOfSession } from '@app/config/customize';
import { checkIsNeedGradioComponentSession, deleteGradioComponentSession } from '@app/config/gradioComponent';

const wrappedAxios = new WrappedAxios(domain);

export const requestGetRaw = async (url: string) => {
  const res = await WrappedAxios.oriRequest({
    url,
    method: 'GET',
  });
  return res.data;
};

export const setIsCredentialsReady = () => {
  wrappedAxios.setIsCredentialsReady();
};

export const checkIsLogin = () => {
  return wrappedAxios.request(
    {
      url: '/core/auth/login/is_login',
      method: 'GET',
    },
    true,
  );
};

export const login = (user_id: string, password: string, vcode: string = '') => {
  return wrappedAxios.request(
    {
      url: '/core/auth/login',
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        user_id: user_id,
        password: password,
        vcode,
      }),
    },
    true,
  );
};

export const loginVisitor = () => {
  return wrappedAxios.request(
    {
      url: '/core/auth/login/visitor',
      method: 'POST',
    },
    true,
  );
};

export const logout = () => {
  return wrappedAxios
    .request({
      url: '/core/auth/login/logout',
    })
    .then(() => {
      if (checkIsNeedCustomCookie()) {
        deleteCustomCookieOfSession();
      } else {
        delCookie(sessionCookieName);
      }
      if (checkIsNeedGradioComponentSession()) {
        deleteGradioComponentSession();
      }
    });
};

export const signUp = (userId: string, password: string, vcode: string) => {
  return wrappedAxios.request({
    url: '/core/auth/user/sign_up',
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({
      user_id: userId,
      password: password,
      vcode,
    }),
  });
};

export const verifyUserId = (userId: string) => {
  return wrappedAxios.request({
    url: '/core/auth/user/sign_up/verify_user_id',
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({
      user_id: userId,
    }),
  });
};

export const getVcode = () => {
  return wrappedAxios.request({
    url: '/core/auth/login/vcode',
  });
};

export const getCurrVcode = () => {
  return wrappedAxios.request({
    url: '/core/auth/login/curr_vcode',
  });
};

export const resetPassword = (oldPassword: string, newPassword: string) => {
  return wrappedAxios.request({
    url: '/core/auth/user/password',
    method: 'PUT',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({
      old_password: oldPassword,
      new_password: newPassword,
    }),
  });
};

export const createSecret = (name: string, privilege: string) => {
  return wrappedAxios.request({
    url: '/core/auth/user/secret',
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({
      name,
      privilege,
    }),
  });
};

export const getSecrets = () => {
  return wrappedAxios.request({
    url: '/core/auth/user/secret',
  });
};

export const deleteSecret = (name: string) => {
  return wrappedAxios.request({
    url: '/core/auth/user/secret',
    method: 'DELETE',
    params: {
      name,
    },
  });
};

export const getMyInfo = () => {
  return wrappedAxios.request({
    url: '/core/auth/login/my_info',
  });
};

export const getAlgoTaskList = () => {
  return wrappedAxios.request({
    url: '/core/algo/task',
  });
};

export const getAlgoItem = (name: string, version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/item',
    params: {
      name,
      version,
    },
  });
};

export const getAlgoItemInfo = (name: string, version: string) => {
  return wrappedAxios.request(
    {
      url: '/core/algo/item/info',
      params: {
        name,
        version,
      },
    },
    true,
  );
};

export const updateAlgoItem = (
  name: string,
  version: string,
  info: {
    description?: string;
    cover_url?: string;
    scope?: string;
    chinese_name?: string;
    document?: string;
    paper?: string;
    github?: string;
    task_ids?: string[];
    tags?: string[];
    request_per_second_for_public?: number;
    request_max_parallel_for_public?: number;
    request_per_second_for_private?: number;
    request_max_parallel_for_private?: number;
  },
) => {
  return wrappedAxios.request({
    url: '/core/algo/item',
    method: 'PUT',
    data: {
      name,
      version,
      ...info,
    },
  });
};

export const deleteAlgoItem = (name: string, version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/item',
    method: 'DELETE',
    params: {
      name,
      version,
    },
  });
};

export const getAlgoVersion = (name: string) => {
  return wrappedAxios.request({
    url: '/core/algo/version',
    params: {
      name,
    },
  });
};

export const getAlgoLocalPublishGuide = () => {
  return wrappedAxios.request({
    url: '/core/algo/publish/guide/local',
  });
};

export const getAlgoDockerPublishGuide = () => {
  return wrappedAxios.request({
    url: '/core/algo/publish/guide/docker',
  });
};

export const getAlgoList = (
  page: number,
  perpage: number,
  searchOptions?: {
    name?: string;
    can_call?: boolean;
    is_hot?: boolean;
    only_latest?: boolean;
    only_in_my_institution?: boolean;
    only_in_my_group?: boolean;
    only_mine?: boolean;
    order_by?: string;
    institution_id?: string;
    group_id?: string;
    user_id?: string;
  },
) => {
  return wrappedAxios.request({
    url: '/core/algo/list',
    params: {
      page,
      perpage,
      ...searchOptions,
    },
  });
};

export const getAlgoSearch = (
  name: string,
  limit: number = 10,
  searchOptions?: {
    can_call?: boolean;
    only_in_my_institution?: boolean;
    only_in_my_group?: boolean;
    only_mine?: boolean;
    institution_id?: string;
    group_id?: string;
    user_id?: string;
  },
) => {
  return wrappedAxios.request({
    url: '/core/algo/search',
    params: {
      name,
      limit,
      ...searchOptions,
    },
  });
};

export const getUserInfo = (user_id: string) => {
  return wrappedAxios.request({
    url: '/core/auth/user/info',
    params: {
      user_id,
    },
  });
};

export const updateMyUserInfo = (
  head_url?: string,
  nickname?: string,
  description?: string,
  email?: string,
  github?: string,
  website?: string,
  tags?: string[],
  institution_id?: string,
  pin_algo?: string[],
) => {
  return wrappedAxios.request({
    url: '/core/auth/user/info',
    method: 'PUT',
    data: {
      head_url,
      nickname,
      description,
      email,
      github,
      website,
      tags,
      institution_id,
      pin_algo,
    },
  });
};

export const getUserList = (
  page: number,
  perpage: number,
  searchOptions?: {
    name?: string;
    institution_id?: string;
    group_id?: string;
    permission?: string;
  },
) => {
  return wrappedAxios.request({
    url: '/core/auth/user/list',
    params: {
      page,
      perpage,
      ...searchOptions,
    },
  });
};

export const getUserSearch = (
  name: string,
  limit: number = 10,
  searchOptions?: {
    institution_id?: string;
    group_id?: string;
    permission?: string;
  },
) => {
  return wrappedAxios.request({
    url: '/core/auth/user/search',
    params: {
      name,
      limit,
      ...searchOptions,
    },
  });
};

export const getGroupInfo = (group_id: string) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/group/info',
    params: {
      group_id,
    },
  });
};

export const updateMyGroupInfo = (
  head_url?: string,
  group_name?: string,
  description?: string,
  website?: string,
  tags?: string[],
  pin_algo?: string[],
) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/group/info',
    method: 'PUT',
    data: {
      head_url,
      group_name,
      description,
      website,
      tags,
      pin_algo,
    },
  });
};

export const addMyGroupMember = (user_id: string) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/group/member',
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({ user_id }),
  });
};

export const getGroupList = (institution_id: string) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/group/list',
    params: {
      institution_id,
    },
  });
};

export const updateGroupSequence = (id_list: string[]) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/group/list/sequence',
    method: 'POST',
    data: {
      id_list,
    },
  });
};

export const updateGroupMemberSequence = (
  id_list: string[],
  role: UserPermission.GROUP_LEADER | UserPermission.MEMBER,
) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/group/member/sequence',
    method: 'POST',
    data: {
      id_list,
      role,
    },
  });
};

export const updateInstitutionMemberSequence = (
  id_list: string[],
  role: UserPermission.INSTITUTION_LEADER | UserPermission.GROUP_LEADER | UserPermission.MEMBER,
) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/member/sequence',
    method: 'POST',
    data: {
      id_list,
      role,
    },
  });
};

export const getInstitutionInfo = (institution_id: string) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/info',
    params: {
      institution_id,
    },
  });
};

export const updateMyInstitutionInfo = (
  head_url?: string,
  institution_name?: string,
  description?: string,
  website?: string,
  pin_algo?: string[],
) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/info',
    method: 'PUT',
    data: {
      head_url,
      institution_name,
      description,
      website,
      pin_algo,
    },
  });
};

export const addMyInstitutionGroupLeader = (group_id: string, user_id: string) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/group/leader',
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({ group_id, user_id }),
  });
};

export const addMyInstitutionGroup = (group_name: string, head_url?: string, description?: string) => {
  return wrappedAxios.request({
    url: '/core/auth/institution/sign_up_group',
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({ group_name, head_url, description }),
  });
};

export const getInstitutionList = () => {
  return wrappedAxios.request({
    url: '/core/auth/institution/list',
  });
};

export const uploadHeadPicUrl = domain + '/core/storage/head';
export const uploadCoverPicUrl = domain + '/core/storage/cover';

export const enrollAlgo = (algoname: string, version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/pypi/enroll',
    method: 'POST',
    data: {
      name: algoname,
      version: version,
    },
  });
};

export const getAlgoCloudDeployStatus = (name: string, version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/pypi/cloud/status',
    params: {
      algoname: name,
      version,
    },
  });
};

export const uploadAlgoFileUrl = (name: string, version: string) =>
  `${rucDomain}/core/algo/builder/file/${name}/${version}`;
export const uploadAlgoFile = (name: string, version: string, file: File, dirPath: string, is_cover?: boolean) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('dir_path', dirPath);
  is_cover && formData.append('is_cover', 'true');
  return wrappedAxios.request({
    url: `${rucDomain}/core/algo/builder/file/${name}/${version}`,
    method: 'POST',
    headers: { 'content-type': 'multipart/form-data' },
    data: formData,
  });
};

export const getUploadFileList = (name: string, version: string, dir_path: string = '') => {
  return wrappedAxios.request({
    url: `${rucDomain}/core/algo/builder/file/${name}/${version}`,
    params: {
      dir_path,
    },
  });
};

export const getUploadFileContent = (name: string, version: string, file_path: string) => {
  return wrappedAxios.request({
    url: `${rucDomain}/core/algo/builder/file/${name}/${version}/content`,
    params: {
      file_path,
    },
  });
};

export const updateUploadFileContent = (name: string, version: string, file_path: string, content: string) => {
  return wrappedAxios.request({
    url: `${rucDomain}/core/algo/builder/file/${name}/${version}/content`,
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({ file_path, content }),
  });
};

export const deleteUploadFile = (name: string, version: string, file_path: string) => {
  return wrappedAxios.request({
    url: `${rucDomain}/core/algo/builder/file/${name}/${version}`,
    method: 'DELETE',
    params: {
      file_path,
    },
  });
};

export const getAlgoConfig = (name: string, version: string) => {
  return wrappedAxios.request({
    url: `${rucDomain}/core/algo/builder/config/${name}/${version}`,
  });
};

export const updateAlgoConfig = (
  name: string,
  version: string,
  options: {
    service_filepath;
    service_function;
    service_input;
    service_output;
    service_input_examples;
    service_timeout;
    service_max_parallel;
    base_image;
    requirements;
    pre_command;
    gradio_launch_filepath;
    gradio_launch_function;
    gradio_launch_host;
    gradio_launch_port;
  },
) => {
  return wrappedAxios.request({
    url: `${rucDomain}/core/algo/builder/config/${name}/${version}`,
    method: 'POST',
    data: options,
  });
};

export const getAlgoBuildLogWsUrl = (domain + '/core/algo/build/ws/log').replace(/^http/i, 'ws');
export const getAlgoDeployLogWsUrl = (domain + '/core/algo/deploy/ws/log').replace(/^http/i, 'ws');

export const startAlgoBuildImage = (algoname: string, version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/build/start',
    method: 'POST',
    data: {
      algoname,
      version,
    },
  });
};

export const resetAlgoBuildImage = (algoname: string, version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/build/reset',
    method: 'POST',
    data: {
      algoname,
      version,
    },
  });
};

export const startAlgoDeploy = (algoname: string, version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/deploy/start',
    method: 'POST',
    data: {
      algoname,
      version,
    },
  });
};

export const resetAlgoDeployed = (algoname: string, version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/deploy/reset',
    method: 'POST',
    data: {
      algoname,
      version,
    },
  });
};

export const getAlgoStatisticCallCurve = (
  is_independent_component: boolean,
  algorithm_name: string,
  algorithm_version: string,
  type: '' | 'gradio' | 'api',
  range: number,
  unit: string,
) => {
  return wrappedAxios.request({
    url: !is_independent_component
      ? '/core/algo/component/statistic/call_curve'
      : `/algo/${algorithm_name}/${algorithm_version}/statistic/call_curve`,
    params: {
      ...(!is_independent_component ? { algorithm_name, algorithm_version } : {}),
      type,
      range,
      unit,
    },
  });
};

export const getAlgoStatisticSuccessCurve = (
  is_independent_component: boolean,
  algorithm_name: string,
  algorithm_version: string,
  type: '' | 'gradio' | 'api',
  range: number,
  unit: string,
) => {
  return wrappedAxios.request({
    url: !is_independent_component
      ? '/core/algo/component/statistic/success_curve'
      : `/algo/${algorithm_name}/${algorithm_version}/statistic/success_curve`,
    params: {
      ...(!is_independent_component ? { algorithm_name, algorithm_version } : {}),
      type,
      range,
      unit,
    },
  });
};

export const getAlgoStatisticUserRank = (
  is_independent_component: boolean,
  algorithm_name: string,
  algorithm_version: string,
  type: '' | 'gradio' | 'api',
  range: number,
  unit: string,
  limit: number,
) => {
  return wrappedAxios.request({
    url: !is_independent_component
      ? '/core/algo/component/statistic/user_rank'
      : `/algo/${algorithm_name}/${algorithm_version}/statistic/user_rank`,
    params: {
      ...(!is_independent_component ? { algorithm_name, algorithm_version } : {}),
      type,
      range,
      unit,
      limit,
    },
  });
};

export const getAlgoServiceResourceCurve = (algorithm_name: string, algorithm_version: string) => {
  return wrappedAxios.request({
    url: '/core/algo/monitor/curve',
    params: { algorithm_name, algorithm_version },
  });
};
