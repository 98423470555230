/**
 * @Description 
 * @Author Kermit
 * @Date 2022-11-29 11:31:56
 * @LastEditors Kermit
 * @LastEditTime 2022-11-29 11:32:01
 */

export enum UserPermission {
  VISITOR = 'VISITOR',
  NORMAL = 'NORMAL',
  MEMBER = 'MEMBER',
  GROUP_LEADER = 'GROUP_LEADER',
  INSTITUTION_LEADER = 'INSTITUTION_LEADER',
  ADMINISTER = 'ADMINISTER',
}
