/**
 * @Description: 云端托管算法文件管理器
 * @Author: Kermit
 * @Date: 2022-12-27 11:29:39
 * @LastEditors: Kermit
 * @LastEditTime: 2023-01-06 18:00:42
 */

import React from 'react';
import './CloudDeployFile.css';
import {
  deleteUploadFile,
  getUploadFileContent,
  getUploadFileList,
  updateUploadFileContent,
  uploadAlgoFile,
  uploadAlgoFileUrl,
} from '@app/api-client';
import { FileOutlined, FolderTwoTone } from '@ant-design/icons';
import { Button, Empty, Input, Popconfirm, Progress, Upload, UploadFile, message } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { sleep } from '@app/utils';

export interface ICloudDeployFileProps {
  algoname: string;
  version: string;
  onDeleteAllFile?: () => void;

  isSelectMode?: boolean;
  onSelectFile?: (filePath: string) => void;
}

export interface ICloudDeployFileState {
  crumbList: {
    name: string;
    isDir: boolean;
  }[];
  isDir: boolean; // 当前是否是文件夹
  isLoading: boolean;
  fileList: {
    name: string;
    size: string;
    createTime: string;
    modifyTime: string;
    isDir: boolean;
  }[];
  fileContent: string;
  canGetFileContent: boolean; // 是否可以获取文件内容

  isEditing: boolean; // 文件是否处于编辑态

  isUploading: boolean; // 是否上传中
  uploadPercent: number;
  uploadFileNum: number; // 上传文件夹的总文件数
  uploadedFileNum: number; // 上传文件夹的已上传数
}

export default class CloudDeployFile extends React.Component<ICloudDeployFileProps, ICloudDeployFileState> {
  constructor(props) {
    super(props);
    this.state = {
      crumbList: [],
      isDir: true,
      isLoading: false,
      fileList: [],
      fileContent: '',
      canGetFileContent: false,
      isEditing: false,
      isUploading: false,
      uploadPercent: 0,
      uploadFileNum: 0,
      uploadedFileNum: 0,
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getFileList();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 获取文件列表 */
  getFileList(dirPath: string = '') {
    this.setState({ fileList: [], isLoading: true }, async () => {
      try {
        const { list } = await getUploadFileList(this.props.algoname, this.props.version, dirPath);
        this.setState({
          fileList: list.map((item) => ({
            name: item.name,
            size: item.size,
            createTime: item.create_time,
            modifyTime: item.modify_time,
            isDir: item.is_dir,
          })),
          isLoading: false,
        });
      } catch (err) {
        this.setState({ isLoading: false });
      }
    });
  }

  getFileContent(filePath: string) {
    this.setState({ canGetFileContent: true, fileContent: '', isLoading: true, isEditing: false }, async () => {
      try {
        const { content } = await getUploadFileContent(this.props.algoname, this.props.version, filePath);
        this.setState({ fileContent: content, isLoading: false });
      } catch (err) {
        this.setState({ canGetFileContent: false, isLoading: false });
      }
    });
  }

  /** 获取当前文件(夹)路径 */
  getCurrPath() {
    return this.state.crumbList.map((item) => item.name).join('/');
  }

  /** 进入列表下的某个文件(夹) */
  goFileByName(name: string, isDir: boolean) {
    const crumbList = this.state.crumbList.concat({ name, isDir });
    this.setState({ crumbList, isDir }, () =>
      isDir ? this.getFileList(this.getCurrPath()) : this.getFileContent(this.getCurrPath()),
    );
  }

  /** 根据面包屑索引进入文件(夹) */
  goFileByIndex(index: number) {
    const crumbList = this.state.crumbList.slice(0, index + 1);
    const isDir = crumbList.length > 0 ? crumbList[crumbList.length - 1].isDir : true;
    this.setState({ crumbList, isDir }, () =>
      isDir ? this.getFileList(this.getCurrPath()) : this.getFileContent(this.getCurrPath()),
    );
  }

  /** 监听文件上传 */
  onFileChange(info: UploadChangeParam<UploadFile>) {
    if (info.file.status === 'uploading') {
      this.setState({ isUploading: true, uploadPercent: info.file.percent || this.state.uploadPercent });
    }
    if (info.file.status === 'done') {
      // 上传成功
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        this.setState({ isUploading: false, uploadPercent: 0 });
        this.getFileList(this.getCurrPath());
      }, 1000);
    }
    if (info.file.status === 'error') {
      // 上传错误
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        message.error('上传文件失败');
        this.setState({ isUploading: false, uploadPercent: 0 });
        this.getFileList(this.getCurrPath());
      }, 1000);
    }
  }

  private readonly maxUploadFileNum = 5; // 最大上传文件数
  private currUploadFileNum = 0; // 当前上传文件数
  /** 监听文件夹上传前，避免同时上传个数过多 */
  onBeforeFileDirUpload() {
    if (this.currUploadFileNum >= this.maxUploadFileNum) {
      return false;
    } else {
      this.currUploadFileNum += 1;
      return true;
    }
  }
  private fileList: UploadFile[] = []; // 上传文件列表
  /** 监听文件夹上传 */
  async onFileDirChange(info: UploadChangeParam<UploadFile>) {
    if (this.state.uploadFileNum === 0 && ['uploading', 'done', 'error'].includes(info.file.status || '')) {
      this.fileList = info.fileList;
      this.setState({ isUploading: true, uploadPercent: 0, uploadFileNum: info.fileList.length });
    }
    if (info.file.status === 'done') {
      // 上传成功
      this.currUploadFileNum -= 1;
      this.setState(
        (state) => ({
          uploadedFileNum: state.uploadedFileNum + 1,
          uploadPercent: 100 * ((state.uploadedFileNum + 1) / state.uploadFileNum),
        }),
        () => this.checkUploadFileDirComplete(),
      );
    }
    if (info.file.status === 'error' || info.file.status == undefined) {
      // 上传错误或被拦截则重传
      // 被 beforeUpload 拦截的文件直接是 File 对象，且没有 status 属性
      let realFile: RcFile;
      if (info.file.status == undefined) {
        // 被 beforeUpload 拦截的文件 File 对象属性不全，需要从 fileList 中找到对应的 UploadFile 对象
        let currFile: UploadFile | undefined;
        while (!(currFile = this.fileList.find((item) => item.uid === info.file.uid))) {
          await sleep(Math.random() * 1000);
        }
        realFile = currFile.originFileObj as RcFile;
      } else {
        realFile = info.file.originFileObj as RcFile;
      }
      const recursionUpload = async () => {
        try {
          // 重传前等待
          while (this.currUploadFileNum >= this.maxUploadFileNum) {
            await sleep(Math.random() * 1000);
          }
          this.currUploadFileNum += 1;

          const currDirPath = this.getCurrPath();
          await uploadAlgoFile(
            this.props.algoname,
            this.props.version,
            realFile,
            (currDirPath ? currDirPath + '/' : '') + realFile.webkitRelativePath.split('/').slice(0, -1).join('/'),
            true,
          );
          // 重传成功
          this.currUploadFileNum -= 1;
          this.setState(
            (state) => ({
              uploadedFileNum: state.uploadedFileNum + 1,
              uploadPercent: Math.floor(100 * ((state.uploadedFileNum + 1) / state.uploadFileNum)),
            }),
            () => this.checkUploadFileDirComplete(),
          );
        } catch (err) {
          this.currUploadFileNum -= 1;
          console.error(err);
          await sleep(1000);
          await recursionUpload();
        }
      };
      await recursionUpload();
    }
  }

  /** 检测文件夹上传情况 */
  checkUploadFileDirComplete() {
    if (this.state.uploadFileNum === this.state.uploadedFileNum) {
      // 上传完成
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        this.setState({ isUploading: false, uploadPercent: 0, uploadFileNum: 0, uploadedFileNum: 0 });
        this.getFileList(this.getCurrPath());
      }, 1000);
    }
  }

  openFileEditing() {
    this.setState({ isEditing: true });
  }

  onFileContentChange(e) {
    this.setState({ fileContent: e.target.value });
  }

  async completeFileEditing() {
    try {
      await updateUploadFileContent(
        this.props.algoname,
        this.props.version,
        this.getCurrPath(),
        this.state.fileContent,
      );
      this.setState({ isEditing: false });
      message.success('已保存');
    } catch (err) {
      message.error('保存失败');
    }
  }

  /** 删除当前文件 */
  async deleteCurrFile() {
    const filePath = this.getCurrPath();
    try {
      await deleteUploadFile(this.props.algoname, this.props.version, filePath);
      if (this.state.crumbList.length > 0) {
        this.goFileByIndex(this.state.crumbList.length - 2);
      } else {
        this.props.onDeleteAllFile?.();
      }
      message.success('已删除');
    } catch (err) {
      message.error('删除失败');
    }
  }

  onSelectCurrFile() {
    this.props.onSelectFile?.(this.getCurrPath());
  }

  render(): React.ReactNode {
    return (
      <div className="c-cloud-deploy-file">
        <div className="file-menu">
          <div className="file-crumb">
            {this.state.crumbList.length === 0 ? (
              <>
                <span className="crumb-item op-hover active">根目录</span>
                <span className="crumb-divider">/</span>
              </>
            ) : (
              <>
                <span className="crumb-item op-hover" onClick={() => this.goFileByIndex(-1)}>
                  根目录
                </span>
                {this.state.crumbList.map((item, index) => (
                  <React.Fragment key={item.name}>
                    <span className="crumb-divider">/</span>
                    <span className="crumb-item op-hover" onClick={() => this.goFileByIndex(index)}>
                      {item.name}
                    </span>
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
          {!this.props.isSelectMode ? (
            <div className="btn-wrap">
              {this.state.isDir ? (
                <>
                  <Upload
                    name="file"
                    action={uploadAlgoFileUrl(this.props.algoname, this.props.version)}
                    withCredentials
                    disabled={this.state.isUploading}
                    showUploadList={false}
                    data={() => ({
                      dir_path: this.getCurrPath(),
                      is_cover: true,
                    })}
                    onChange={this.onFileChange.bind(this)}
                  >
                    <Button style={{ margin: '0 4px', fontSize: 13 }} disabled={this.state.isUploading}>
                      上传文件
                    </Button>
                  </Upload>
                  <Upload
                    name="file"
                    action={uploadAlgoFileUrl(this.props.algoname, this.props.version)}
                    withCredentials
                    directory
                    disabled={this.state.isUploading}
                    showUploadList={false}
                    beforeUpload={this.onBeforeFileDirUpload.bind(this)}
                    data={(file: UploadFile | File) => {
                      let realFile: File;
                      if (file instanceof File) {
                        realFile = file;
                      } else {
                        realFile = (file as UploadFile).originFileObj as File;
                      }
                      const currDirPath = this.getCurrPath();
                      return {
                        dir_path:
                          (currDirPath ? currDirPath + '/' : '') +
                          realFile.webkitRelativePath.split('/').slice(0, -1).join('/'),
                        is_cover: true,
                      };
                    }}
                    onChange={this.onFileDirChange.bind(this)}
                  >
                    <Button style={{ margin: '0 4px', fontSize: 13 }} disabled={this.state.isUploading}>
                      上传文件夹
                    </Button>
                  </Upload>
                </>
              ) : (
                this.state.canGetFileContent &&
                (this.state.isEditing ? (
                  <Popconfirm
                    title="确认保存文件？"
                    onConfirm={() => this.completeFileEditing()}
                    okText="确认"
                    cancelText="取消"
                  >
                    <Button style={{ margin: '0 4px', fontSize: 13 }} type="primary">
                      保存
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button style={{ margin: '0 4px', fontSize: 13 }} onClick={this.openFileEditing.bind(this)}>
                    编辑
                  </Button>
                ))
              )}
              <Popconfirm
                title={`确认删除${
                  this.state.crumbList.length > 0
                    ? '"' + this.state.crumbList[this.state.crumbList.length - 1].name + '"'
                    : '全部文件'
                }？`}
                onConfirm={() => this.deleteCurrFile()}
                okText="确认"
                cancelText="取消"
              >
                <Button style={{ margin: '0 4px', fontSize: 13 }} disabled={this.state.isUploading} danger>
                  删除
                </Button>
              </Popconfirm>
            </div>
          ) : (
            !this.state.isDir && (
              <Button
                style={{ margin: '0 4px', fontSize: 13 }}
                type="primary"
                onClick={this.onSelectCurrFile.bind(this)}
              >
                选择
              </Button>
            )
          )}
        </div>
        {this.state.isUploading && <Progress percent={this.state.uploadPercent} status="active" />}

        {this.state.isDir ? (
          <div className="file-list-container">
            {this.state.crumbList.length > 0 && (
              <div className="file-list-item-box" onClick={() => this.goFileByIndex(this.state.crumbList.length - 2)}>
                <div className="file-list-item">
                  <div className="file-icon">
                    <FolderTwoTone className="file-folder" />
                  </div>
                  <div className="file-name text-cut">..</div>
                  <div className="file-size"></div>
                  <div className="file-time"></div>
                </div>
              </div>
            )}
            {this.state.fileList.map((item) => (
              <div
                className="file-list-item-box"
                key={item.name}
                onClick={
                  item.isDir ? () => this.goFileByName(item.name, true) : () => this.goFileByName(item.name, false)
                }
              >
                <div className="file-list-item">
                  <div className="file-icon">
                    {item.isDir ? <FolderTwoTone className="file-folder" /> : <FileOutlined className="file-file" />}
                  </div>
                  <div className="file-name text-cut">{item.name}</div>
                  <div className="file-size">{item.isDir ? '' : item.size}</div>
                  <div className="file-time">{item.modifyTime}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="file-content-container">
            {this.state.canGetFileContent ? (
              <div className="file-content-box">
                {!this.state.isLoading && (
                  <Input.TextArea
                    autoSize={{ minRows: 10 }}
                    size="middle"
                    disabled={!this.state.isEditing}
                    defaultValue={this.state.fileContent}
                    placeholder="文件内容为空"
                    onChange={this.onFileContentChange.bind(this)}
                  />
                )}
              </div>
            ) : (
              <div className="file-content-disable">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>无法读取文件文本内容</span>} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
