/**
 * @Description: Router
 * @Author: Kermit
 * @Date: 2022-11-24 12:43:24
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-02 15:37:17
 */

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Index from '@app/pages/index/Index';
import NotFound from '@app/pages/not-found/NotFound';
import Institution from '@app/pages/institution/Institution';
import Algorithm from '@app/pages/algorithm/Algorithm';
import AlgorithmDetail from '@app/pages/algorithm-detail/AlgorithmDetail';
import UserPage from '@app/pages/user-page/UserPage';
import GroupPage from '@app/pages/group-page/GroupPage';
import InstitutionPage from '@app/pages/institution-page/InstitutionPage';
import Publish from '@app/pages/publish/Publish';
import UserSetting from '@app/pages/user-setting/UserSetting';
import EmbedComponent from '@app/pages/embed-component/EmbedComponent';

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/institution" element={<Institution />}></Route>
        <Route path="/algorithm" element={<Algorithm />}></Route>
        <Route path="/algorithm/:name/:version" element={<AlgorithmDetail />}></Route>
        <Route path="/algorithm/:name/:version/:tab" element={<AlgorithmDetail />}></Route>
        <Route path="/user/:userId" element={<UserPage />}></Route>
        <Route path="/user/:userId/:tab" element={<UserPage />}></Route>
        <Route path="/group/:groupId" element={<GroupPage />}></Route>
        <Route path="/group/:groupId/:tab" element={<GroupPage />}></Route>
        <Route path="/institution/:institutionId" element={<InstitutionPage />}></Route>
        <Route path="/institution/:institutionId/:tab" element={<InstitutionPage />}></Route>
        <Route path="/publish" element={<Publish />}></Route>
        <Route path="/publish/:tab" element={<Publish />}></Route>
        <Route path="/setting/user" element={<UserSetting />}></Route>
        <Route path="/component/:name" element={<EmbedComponent />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
