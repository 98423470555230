/**
 * @Description:
 * @Author: Kermit
 * @Date: 2023-05-01 14:31:55
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-05 18:14:58
 */

import React from 'react';
import './AlgoEmbedModal.css';
import { Modal, Radio, Typography, message } from 'antd';
import Markdown from '@app/base-components/markdown/Markdown';
import { copyText } from '@app/utils';

export interface IAlgoEmbedModalProps {
  gradioUrl: string;
  cardUrl: string;
  isModalOpen: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  onCompleted?: () => void;
}

export interface IAlgoEmbedModalState {
  tab: 'gradio' | 'card';
}

export default class AlgoEmbedModal extends React.Component<IAlgoEmbedModalProps, IAlgoEmbedModalState> {
  constructor(props) {
    super(props);
    this.state = { tab: 'gradio' };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  onRadioGroupChange(e) {
    this.setState({ tab: e.target.value });
  }

  getGradioUrl() {
    return {
      copyText: `<iframe src="${this.props.gradioUrl}" width="100%" height="100%" frameborder="0"></iframe>`,
      showText: `\`\`\`HTML
<iframe
  src="${this.props.gradioUrl}"
  width="100%"
  height="100%"
  frameborder="0"
></iframe>
\`\`\``,
    };
  }

  getCardUrl() {
    return {
      copyText: `<iframe src="${this.props.cardUrl}" width="100%" height="279" frameborder="0"></iframe>`,
      showText: `\`\`\`HTML
<iframe
  src="${this.props.cardUrl}"
  width="100%"
  height="279"
  frameborder="0"
></iframe>
\`\`\``,
    };
  }

  async handleOk() {
    if (this.state.tab === 'gradio') copyText(this.getGradioUrl().copyText);
    else if (this.state.tab === 'card') copyText(this.getCardUrl().copyText);
    message.success('复制成功');
    this.props.onOk?.();
    this.props.onCompleted?.();
  }

  handleCancel() {
    this.props.onCancel?.();
    this.props.onCompleted?.();
  }

  render(): React.ReactNode {
    return (
      <Modal
        className="c-algo-pin-modal"
        title="嵌入我自己的网站"
        okText="复制代码"
        open={this.props.isModalOpen}
        onOk={this.handleOk.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        mask={false}
      >
        <div className="type-selector">
          <Radio.Group value={this.state.tab} onChange={this.onRadioGroupChange.bind(this)}>
            <Radio.Button value="gradio">演示应用</Radio.Button>
            <Radio.Button value="card">预览卡片</Radio.Button>
          </Radio.Group>
        </div>

        {this.state.tab === 'gradio' && (
          <Typography>
            <Typography.Paragraph>
              复制下面 iframe 标签代码到我的网站中，将<b>演示应用</b>嵌入网站任意位置。
            </Typography.Paragraph>
            <div className="gradio-exp-container">
              <iframe src={this.props.gradioUrl} width="100%" height="100%" frameBorder="0"></iframe>
            </div>
            <Typography.Paragraph>
              <Markdown>{this.getGradioUrl().showText}</Markdown>
            </Typography.Paragraph>
          </Typography>
        )}

        {this.state.tab === 'card' && (
          <Typography>
            <Typography.Paragraph>
              复制下面 iframe 标签代码到我的网站中，将<b>预览卡片</b>嵌入网站任意位置。
            </Typography.Paragraph>
            <iframe src={this.props.cardUrl} width="100%" height="279" frameBorder="0"></iframe>
            <Typography.Paragraph>
              <Markdown>{this.getCardUrl().showText}</Markdown>
            </Typography.Paragraph>
          </Typography>
        )}
      </Modal>
    );
  }
}
