/**
 * @Description: 可拖动项
 * @Author: Kermit
 * @Date: 2022-12-06 15:39:07
 * @LastEditors: Kermit
 * @LastEditTime: 2022-12-06 17:43:26
 */

import React, { CSSProperties } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'antd';
import { SwapOutlined } from '@ant-design/icons';

export function SortableItem(props: { id: UniqueIdentifier; children: React.ReactElement; isShowDragger?: boolean }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative',
  };
  const draggerStyle: CSSProperties = {
    top: 0,
    right: 0,
    position: 'absolute',
    zIndex: 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {props.isShowDragger && (
        <div style={draggerStyle} {...listeners} {...attributes}>
          <Button size="small" shape="circle" icon={<SwapOutlined style={{ fontSize: 11, lineHeight: '100%' }} />} />
        </div>
      )}
      {props.children}
    </div>
  );
}
