/**
 * @Description:
 * @Author: Kermit
 * @Date: 2022-11-24 13:01:15
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-03 16:02:33
 */

import React from 'react';
import './p-index.bootstrap.min.css';
import './Index.css';
import GlobalLayout, { Nav } from '@app/layout-components/global-layout/GlobalLayout';
import { getAlgoList } from '@app/api-client';
import { getCurrInstitution } from '@app/config/customize';

export default class Index extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      demoAlgo: null,
    };
  }

  componentDidMount(): void {
    this.initIndex();
    this.getDemoAlgo();
  }

  componentWillUnmount(): void {
    this.destroyIndex();
  }

  titleKeywordInterval: any;
  titleKeywordList = ['算法作品', 'ML模型', '科研成果'];
  titleKeywordIndex = 0;
  initIndex() {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      (document as any).querySelector('.preloader').style.opacity = '0';
      const timeout2 = setTimeout(() => {
        clearTimeout(timeout2);
        (document as any).querySelector('.preloader').style.display = 'none';
      }, 300);
    }, 50);

    // Sticky
    window.onscroll = function () {
      // show or hide the back-top-top button
      const backToTo: any = document.querySelector('.scroll-top');
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        backToTo.style.display = 'flex';
      } else {
        backToTo.style.display = 'none';
      }
    };

    this.titleKeywordInterval = setInterval(() => {
      const titleKeyword: any = document.querySelector('#title-keyword');
      titleKeyword.style.opacity = 0;
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        this.titleKeywordIndex = (this.titleKeywordIndex + 1) % this.titleKeywordList.length;
        titleKeyword.innerText = this.titleKeywordList[this.titleKeywordIndex];
        titleKeyword.style.opacity = 1;
      }, 250);
    }, 4000);
  }

  destroyIndex() {
    window.onscroll = null;
    clearInterval(this.titleKeywordInterval);
  }

  async getDemoAlgo() {
    const { list } = await getAlgoList(1, 1, {
      is_hot: true,
      only_latest: true,
    });
    this.setState({
      demoAlgo: {
        name: list[0].name,
        version: list[0].version,
        canCall: list[0].can_call,
        description: list[0].description,
        username: list[0].user.user_id,
        usernickname: list[0].user.nickname,
        userHeadUrl: list[0].user.head_url,
        institutionId: list[0].user.institution?.id,
        institutionName: list[0].user.institution?.institution_name,
        institutionHeadUrl: list[0].user.institution?.head_url,
        institutionDescription: list[0].user.institution?.description,
        groupId: list[0].user.group?.id,
        groupName: list[0].user.group?.group_name,
        groupHeadUrl: list[0].user.group?.head_url,
        groupDescription: list[0].user.group?.description,
        scope: list[0].scope,
        tasks: list[0].tasks,
        createTime: list[0].create_day,
        chineseName: list[0].chinese_name,
        isMine: list[0].is_mine,
      },
    });
  }

  render(): React.ReactNode {
    return (
      <GlobalLayout activeNav={Nav.INDEX} showFooter cancelContainer isHeaderWhiteColor>
        <div className="p-index">
          {/* <!-- ======== preloader start ======== --> */}
          <div className="preloader">
            <div className="loader">
              <div className="spinner">
                <div className="spinner-container">
                  <div className="spinner-rotator">
                    <div className="spinner-left">
                      <div className="spinner-circle"></div>
                    </div>
                    <div className="spinner-right">
                      <div className="spinner-circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- preloader end --> */}

          {/* <!-- ======== hero-section start ======== --> */}
          <section id="home" className="hero-section">
            <div className="container">
              <div className="row align-items-center position-relative">
                <div className="col-lg-6">
                  <div className="hero-content">
                    <h1 className="wow fadeInUp" data-wow-delay=".4s">
                      打造{getCurrInstitution()?.shortName || '我的'}
                      <span id="title-keyword" className="hero-emphasis">
                        算法作品
                      </span>
                      集
                    </h1>
                    <p className="wow fadeInUp" data-wow-delay=".6s">
                      简单几步，为机器学习算法模型创建一个生产级应用。
                    </p>
                    {!getCurrInstitution() ? (
                      <a href="/algorithm" className="main-btn border-btn btn-hover wow fadeInUp" data-wow-delay=".6s">
                        前往算法社区
                      </a>
                    ) : (
                      <a href="/algorithm" className="main-btn border-btn btn-hover wow fadeInUp" data-wow-delay=".6s">
                        前往{getCurrInstitution()?.shortName}算法池
                      </a>
                    )}
                    <a href="/publish" className="main-btn border-btn btn-hover wow fadeInUp" data-wow-delay=".6s">
                      创建算法应用
                    </a>
                    <a href="#about" className="scroll-bottom">
                      <i className="lni lni-arrow-down"></i>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                    <img src={require('@app/static/images/index-page/hero/hero-img.jpg')} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ======== hero-section end ======== --> */}

          {/* <!-- ======== about-section start ======== --> */}
          <section id="about" className="about-section pt-100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6">
                  <div className="about-img">
                    <img
                      src={require('@app/static/images/index-page/about/about-1.png')}
                      alt=""
                      className="w-100 img-body"
                    />
                    <img
                      src={require('@app/static/images/index-page/about/about-left-shape.svg').default}
                      alt=""
                      className="shape shape-1"
                    />
                    <img
                      src={require('@app/static/images/index-page/about/left-dots.svg').default}
                      alt=""
                      className="shape shape-2"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="about-content">
                    <div className="section-title mb-30">
                      <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                        操作简单、一键生成
                      </h2>
                      <p className="wow fadeInUp" data-wow-delay=".4s">
                        无需编写额外代码，简单填写算法配置即可一键生成应用。适用任意推理框架，不管是
                        MNN、NCNN、TensorFlow-Lite 还是 ONNXRuntime 推理框架，AlgoSpace 都不在话下。
                      </p>
                    </div>
                    {/* <a
                      href="javascript:void(0)"
                      className="main-btn btn-hover border-btn wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      如何运行的？
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ======== about-section end ======== --> */}

          {/* <!-- ======== about2-section start ======== --> */}
          <section id="about" className="about-section pt-100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6">
                  <div className="about-content">
                    <div className="section-title mb-30">
                      <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                        演示界面 + 调用 API
                      </h2>
                      <p className="wow fadeInUp" data-wow-delay=".4s">
                        生成的算法应用不仅提供演示界面，可向团队成员、面试官、投资人快速演示算法模型。还提供生产级的远程调用
                        API，可将算法模型直接用于任何框架语言的业务流程。权限可控、调用次数可控，保证具有商业价值的算法模型不被滥用。
                      </p>
                    </div>
                    {/* <ul className="wow fadeInUp" data-wow-delay=".2s">
                      <li>Quick Access</li>
                      <li>Easily to Manage</li>
                      <li>24/7 Support</li>
                    </ul> */}
                    <a
                      target="_blank"
                      href="https://www.wolai.com/algospace/2eUfozRR8sUph3r3dNiUfS"
                      rel="noreferrer"
                      className="main-btn btn-hover border-btn wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      原理是什么？
                    </a>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 order-first order-lg-last">
                  <div className="about-img-2">
                    <img
                      src={require('@app/static/images/index-page/about/about-2.png')}
                      alt=""
                      className="w-100 img-body"
                    />
                    <img
                      src={require('@app/static/images/index-page/about/about-right-shape.svg').default}
                      alt=""
                      className="shape shape-1"
                    />
                    <img
                      src={require('@app/static/images/index-page/about/right-dots.svg').default}
                      alt=""
                      className="shape shape-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ======== about2-section end ======== --> */}

          {/* <!-- ======== feature-section start ======== --> */}
          {/* <section id="features" className="feature-section pt-120">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-feature">
                    <div className="icon">
                      <i className="lni lni-hammer"></i>
                    </div>
                    <div className="content">
                      <h3>操作简单、一键生成</h3>
                      <p>无需编写额外代码，简单填写算法配置即可一键为算法生成应用。</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-feature">
                    <div className="icon">
                      <i className="lni lni-lock"></i>
                    </div>
                    <div className="content">
                      <h3>本地部署、绝对保密</h3>
                      <p>可选择在本地部署算法应用，无需将代码上传至 AlgoSpace，绝对保密你的机密算法。</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-8 col-sm-10">
                  <div className="single-feature">
                    <div className="icon">
                      <i className="lni lni-cool"></i>
                    </div>
                    <div className="content">
                      <h3>演示界面 & 调用 API</h3>
                      <p>生成的算法应用不仅拥有生动的可视化界面，还拥有直观的代码远程调用 API，实现生产级部署。</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <!-- ======== feature-section end ======== --> */}

          {/* <!-- ======== feature-section start ======== --> */}
          <section id="why" className="feature-extended-section pt-100">
            <div className="feature-extended-wrapper">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
                    <div className="section-title text-center mb-60">
                      <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                        AlgoSpace
                      </h2>
                      <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                        为我做了什么？省去了我哪些工作？
                      </h2>
                      {/* <p className="wow fadeInUp" data-wow-delay=".4s">
                        AlgoSpace 省去了我哪些工作？
                      </p> */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="single-feature-extended">
                      <div className="icon">
                        <i className="lni lni-display"></i>
                      </div>
                      <div className="content">
                        <h3>服务器搭建</h3>
                        <p>
                          <ul>
                            <li>使用 AlgoSpace 一键部署</li>
                            <li>无需公网 IP</li>
                            <li>无需配置服务器环境</li>
                            <li>无需自行搭建架构</li>
                            <li>无需编写接口逻辑</li>
                          </ul>
                          {/* 通常，把算法模型部署为 API 过程极为复杂，需要一台拥有公网 IP
                          的服务器、配置服务器环境、搭建架构和自行编写接口逻辑。使用 AlgoSpace
                          省去繁琐的服务器搭建工作。 */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-feature-extended">
                      <div className="icon">
                        <i className="lni lni-rocket"></i>
                      </div>
                      <div className="content">
                        <h3>流量控制</h3>
                        <p>
                          <ul>
                            <li>基于 AlgoSpace 平稳运行</li>
                            <li>无需顾虑高并发</li>
                            <li>无需顾虑 DDoS 攻击</li>
                          </ul>
                          {/* 服务器部署应用，总是会有瞬时高并发、DDoS 攻击等顾虑。别担心，AlgoSpace
                          为你扛下一切，你的算法模型将平稳地运行在任何地方。 */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-feature-extended">
                      <div className="icon">
                        <i className="lni lni-key"></i>
                      </div>
                      <div className="content">
                        <h3>权限管理</h3>
                        <p>
                          <ul>
                            <li>使用 AlgoSpace 管理用户调用</li>
                            <li>支持不同用户组权限管理</li>
                          </ul>
                          {/* 具有商业价值的算法模型，不应该被任何人随意调用。AlgoSpace
                          提供的鉴权能力能够让你指定用户调用，并且有限度地调用。 */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-feature-extended">
                      <div className="icon">
                        <i className="lni lni-lock-alt"></i>
                      </div>
                      <div className="content">
                        <h3>算法模型保密</h3>
                        <p>
                          <ul>
                            <li>支持本地部署算法模型</li>
                            <li>由 AlgoSpace 代理转发调用请求</li>
                            <li>无需上传代码、模型</li>
                          </ul>
                          {/* 研究不易，选择不公开算法模型的用户与机构不必担心。可将算法模型部署在本地，无需将代码上传至
                          AlgoSpace，绝对保密你的机密算法。AlgoSpace 为你代理转发请求。 */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-feature-extended">
                      <div className="icon">
                        <i className="lni lni-cloud-sync"></i>
                      </div>
                      <div className="content">
                        <h3>算法模型托管</h3>
                        <p>
                          <ul>
                            <li>支持部署于 AlgoSpace 托管服务器</li>
                            <li>无需担忧硬件资源问题</li>
                          </ul>
                          {/* 找不到用于本地部署的机器？不用担心，AlgoSpace
                          的托管服务能够托管你的算法模型，长期为你提供稳定的算力，并且将严格保密你的算法模型。 */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-feature-extended">
                      <div className="icon">
                        <i className="lni lni-docker"></i>
                      </div>
                      <div className="content">
                        <h3>独立部署</h3>
                        <p>
                          <ul>
                            <li>支持申请 AlgoSpace “独立部署代理组件”</li>
                            <li>热门算法可使用独立的数据通路，支持更高连接数</li>
                          </ul>
                          {/* 对于有更高同时连接数要求的算法应用，可申请 AlgoSpace 提供 “独立部署的代理组件”
                          选项，享受独立的数据通路。 */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ======== feature-section end ======== --> */}

          {/* <!-- ======== subscribe-section start ======== --> */}
          <section id="contact" className="subscribe-section pt-120 pb-120">
            <div className="container">
              <div className="subscribe-wrapper img-bg">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-7">
                    <div className="section-title mb-15">
                      <h2 className="text-white mb-25">立即体验</h2>
                      <p className="text-white pr-5">点击旁边卡片，立即体验演示应用</p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-5">
                    {this.state.demoAlgo && (
                      <iframe
                        src={`/component/algo-card?name=${encodeURIComponent(
                          this.state.demoAlgo.name,
                        )}&version=${encodeURIComponent(this.state.demoAlgo.version)}`}
                        width="100%"
                        height="279"
                        frameBorder="0"
                      ></iframe>
                    )}
                    {/* <form action="#" className="subscribe-form">
                      <input type="email" name="subs-email" id="subs-email" placeholder="Your Email" />
                      <button type="submit" className="main-btn btn-hover">
                        注册
                      </button>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- ======== subscribe-section end ======== --> */}

          {/* <!-- ======== footer start ======== --> */}
          {/* <footer className="footer">
            <div className="container">
              <div className="widget-wrapper">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="footer-widget">
                      <div className="logo mb-30">
                        <a href="index.html">
                          <img src={require('@app/static/images/index-page/logo/logo.svg').default} alt="" />
                        </a>
                      </div>
                      <p className="desc mb-30 text-white">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dinonumy eirmod tempor invidunt.
                      </p>
                      <ul className="socials">
                        <li>
                          <a href="jvascript:void(0)">
                            <i className="lni lni-facebook-filled"></i>
                          </a>
                        </li>
                        <li>
                          <a href="jvascript:void(0)">
                            <i className="lni lni-twitter-filled"></i>
                          </a>
                        </li>
                        <li>
                          <a href="jvascript:void(0)">
                            <i className="lni lni-instagram-filled"></i>
                          </a>
                        </li>
                        <li>
                          <a href="jvascript:void(0)">
                            <i className="lni lni-linkedin-original"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-6">
                    <div className="footer-widget">
                      <h3>About Us</h3>
                      <ul className="links">
                        <li>
                          <a href="javascript:void(0)">Home</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Feature</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">About</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Testimonials</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="footer-widget">
                      <h3>Features</h3>
                      <ul className="links">
                        <li>
                          <a href="javascript:void(0)">How it works</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Privacy policy</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Terms of service</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Refund policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <div className="footer-widget">
                      <h3>Other Products</h3>
                      <ul className="links">
                        <li>
                          <a href="jvascript:void(0)">Accounting Software</a>
                        </li>
                        <li>
                          <a href="jvascript:void(0)">Billing Software</a>
                        </li>
                        <li>
                          <a href="jvascript:void(0)">Booking System</a>
                        </li>
                        <li>
                          <a href="jvascript:void(0)">Tracking System</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer> */}
          {/* <!-- ======== footer end ======== --> */}

          {/* <!-- ======== scroll-top ======== --> */}
          <a href="#" className="scroll-top btn-hover">
            <i className="lni lni-chevron-up"></i>
          </a>
        </div>
      </GlobalLayout>
    );
  }
}
