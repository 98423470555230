/**
 * @Description: 个人主页
 * @Author: Kermit
 * @Date: 2022-11-30 10:58:53
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-03 15:10:11
 */

import React from 'react';
import './UserPage.css';
import GlobalLayout from '@app/layout-components/global-layout/GlobalLayout';
import { useParams, useSearchParams } from 'react-router-dom';
import AlgoRearch from '@app/components/algo-search/AlgoRearch';
import AlgoList from '@app/components/algo-list/AlgoList';
import { Avatar, Card, Divider, Tabs, Tag, Timeline, Typography } from 'antd';
import { GithubOutlined, HddOutlined, HomeOutlined, MailOutlined } from '@ant-design/icons';
import { getUserInfo } from '@app/api-client';
import { UserPermission } from '@app/constants/user';
import Meta from 'antd/es/card/Meta';
import { GroupPageLink, InstitutionPageLink } from '@app/routes/Links';
import { locationToUserPageTab } from '@app/routes/location';

export default function UserPage(props) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  return (
    <CUserPage
      userId={params.userId as string}
      tab={params.tab}
      search={searchParams.get('name')}
      {...props}
    ></CUserPage>
  );
}

export interface IUserPageProps {
  userId: string;
  tab?: string;
  search?: string;
}

export interface IUserPageState {
  /** 用户昵称 */
  usernickname?: string;
  /** 用户头像 */
  userHeadUrl?: string;
  /** 用户描述 */
  userDescription?: string;
  userEmail?: string;
  userGithub?: string;
  userWebsite?: string;
  userTags?: string[];
  /** 用户身份 */
  userPermission?: UserPermission;
  /** 机构 ID */
  institutionId?: string;
  /** 用户机构名 */
  institutionName?: string;
  /** 机构头像 */
  institutionHeadUrl?: string;
  /** 机构简介 */
  institutionDescription?: string;
  /** 研究组 ID */
  groupId?: string;
  /** 用户研究组名 */
  groupName?: string;
  /** 研究组头像 */
  groupHeadUrl?: string;
  /** 研究组简介 */
  groupDescription?: string;
  /** 是我？ */
  isMine?: boolean;
}

class CUserPage extends React.Component<IUserPageProps, IUserPageState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getInfo();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  async getInfo() {
    const res = await getUserInfo(this.props.userId);
    this.setState({
      usernickname: res.nickname,
      userHeadUrl: res.head_url,
      userDescription: res.description,
      userPermission: res.permission,
      userEmail: res.email,
      userGithub: res.github,
      userWebsite: res.website ? (res.website.startsWith('http') ? res.website : `http://${res.website}`) : undefined,
      userTags: res.tags,
      institutionId: res.institution?.institution_id,
      institutionName: res.institution?.institution_name,
      institutionHeadUrl: res.institution?.head_url,
      institutionDescription: res.institution?.description,
      groupId: res.group?.group_id,
      groupName: res.group?.group_name,
      groupHeadUrl: res.group?.head_url,
      groupDescription: res.group?.description,
      isMine: res.is_mine,
    });
  }

  onSearchNameConform(value: string) {
    locationToUserPageTab(this.props.userId, 'algo', { name: value });
  }

  onTabClick(key: string) {
    locationToUserPageTab(this.props.userId, key);
  }

  render(): React.ReactNode {
    return (
      <GlobalLayout isHeaderTopicBackgroud isHeaderWhiteColor title={this.state.usernickname || this.props.userId}>
        <div className="p-user-page">
          <div className="layout-container">
            <div className="side-layout">
              <div className="user-card-container">
                <Card
                  style={{ width: '100%' }}
                  cover={
                    <img
                      className="user-head"
                      alt="头像"
                      src={
                        this.state.userHeadUrl ||
                        'https://img2.baidu.com/it/u=3457961156,2781103929&fm=253&fmt=auto&app=138&f=JPEG?w=460&h=461'
                      }
                    />
                  }
                >
                  <Meta
                    title={
                      this.state.usernickname ? (
                        <span>
                          {this.state.usernickname}
                          <span className="user-subname">{' ' + this.props.userId}</span>
                        </span>
                      ) : (
                        <span>{this.props.userId}</span>
                      )
                    }
                    description={
                      <Typography.Paragraph ellipsis={{ rows: 3, expandable: true, symbol: '展开' }}>
                        {this.state.userDescription}
                      </Typography.Paragraph>
                    }
                  />
                  {this.state.userTags && this.state.userTags.length > 0 && (
                    <div className="tag-line">
                      {this.state.userTags?.map((tag) => {
                        const [tagContent, tagColor] = tag.split('###');
                        return (
                          <Tag color={tagColor} key={tag}>
                            {tagContent}
                          </Tag>
                        );
                      })}
                    </div>
                  )}
                  {this.state.userEmail && (
                    <div className="detail-line text-cut">
                      <MailOutlined />
                      <span className="detail-text">
                        <a href={`mailto:${this.state.userEmail}`}>{this.state.userEmail}</a>
                      </span>
                    </div>
                  )}
                  {this.state.userGithub && (
                    <div className="detail-line text-cut">
                      <GithubOutlined />
                      <span className="detail-text">
                        <a target="_blank" rel="noreferrer" href={`https://github.com/${this.state.userGithub}`}>
                          {this.state.userGithub}
                        </a>
                      </span>
                    </div>
                  )}
                  {this.state.userWebsite && (
                    <div className="detail-line text-cut">
                      <HomeOutlined />
                      <span className="detail-text">
                        <a target="_blank" rel="noreferrer" href={this.state.userWebsite}>
                          {this.state.userWebsite}
                        </a>
                      </span>
                    </div>
                  )}
                </Card>
              </div>
              <AlgoRearch
                size="large"
                placeholder={`搜索${this.state.isMine ? '我的' : ' Ta 的'}算法`}
                userFilter={this.props.userId}
                onSearch={this.onSearchNameConform.bind(this)}
                value={this.props.search}
              />
              {this.state.institutionId && (
                <>
                  <div className="divider-container">
                    <Divider orientation="center">所属</Divider>
                  </div>
                  <div className="common-card-container">
                    <Card style={{ width: '100%' }}>
                      <Meta
                        title={
                          <InstitutionPageLink institutionId={this.state.institutionId}>
                            <span className="op-hover">{this.state.institutionName}</span>
                          </InstitutionPageLink>
                        }
                        avatar={
                          <InstitutionPageLink institutionId={this.state.institutionId}>
                            <Avatar className="op-hover" src={this.state.institutionHeadUrl} />
                          </InstitutionPageLink>
                        }
                        description={
                          <Typography.Paragraph ellipsis={{ rows: 3, expandable: true, symbol: '展开' }}>
                            {this.state.institutionDescription}
                          </Typography.Paragraph>
                        }
                      />
                    </Card>
                  </div>
                  {this.state.groupId && (
                    <div className="common-card-container">
                      <Card style={{ width: '100%' }}>
                        <Meta
                          title={
                            <GroupPageLink groupId={this.state.groupId || ''}>
                              <span className="op-hover">{this.state.groupName}</span>
                            </GroupPageLink>
                          }
                          avatar={
                            <GroupPageLink groupId={this.state.groupId || ''}>
                              <Avatar className="op-hover" src={this.state.groupHeadUrl} />
                            </GroupPageLink>
                          }
                          description={
                            <Typography.Paragraph ellipsis={{ rows: 5, expandable: true, symbol: '展开' }}>
                              {this.state.groupDescription}
                            </Typography.Paragraph>
                          }
                        />
                      </Card>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="body-layout">
              <Tabs
                activeKey={this.props.tab || 'algo'}
                type="card"
                onTabClick={this.onTabClick.bind(this)}
                items={[
                  {
                    label: (
                      <span>
                        <HddOutlined />
                        算法
                      </span>
                    ),
                    key: 'algo',
                    children: (
                      <>
                        {!this.props.search && (
                          <AlgoList
                            title={`置顶算法应用`}
                            userFilter={this.props.userId}
                            isPin={true}
                            isHidePagination={true}
                            isHideOrderBy={true}
                            isDisplayNoneWhenEmpty={!this.state.isMine}
                            isShowEditPin={this.state.isMine}
                          />
                        )}
                        <AlgoList
                          title={`${this.state.isMine ? '我的' : ' Ta 的'}算法应用`}
                          userFilter={this.props.userId}
                          nameFilter={this.props.search}
                        />
                      </>
                    ),
                  },
                  {
                    label: (
                      <span>
                        <HddOutlined />
                        动态
                      </span>
                    ),
                    key: 'dynamic',
                    children: (
                      <Timeline>
                        <Timeline mode="left">
                          {/* <Timeline.Item label="2022-11-20">来到了 VSource</Timeline.Item>
                        <Timeline.Item label="2022-11-20">来到了 VSource</Timeline.Item>
                        <Timeline.Item label="2022-11-20">来到了 VSource</Timeline.Item>
                        <Timeline.Item label="2022-11-20">来到了 VSource</Timeline.Item> */}
                        </Timeline>
                      </Timeline>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </GlobalLayout>
    );
  }
}
