/**
 * @Description
 * @Author Kermit
 * @Date 2023-01-06 18:12:36
 * @LastEditors Kermit
 * @LastEditTime 2023-05-15 18:13:18
 */

// 设置cookie
export function setCookie(c_name: string, value: string, expiremMinutes: number) {
  const exdate = new Date();
  exdate.setTime(exdate.getTime() + expiremMinutes * 60 * 1000);
  document.cookie = c_name + '=' + escape(value) + ';expires=' + exdate.toUTCString();
}

// 读取cookie
export function getCookie(c_name: string, cookie?: string) {
  if (!cookie) cookie = document.cookie;
  if (cookie.length > 0) {
    let c_start = cookie.indexOf(c_name + '=');
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = cookie.indexOf(';', c_start);
      if (c_end == -1) {
        c_end = cookie.length;
      }
      return unescape(cookie.substring(c_start, c_end));
    }
  }
  return '';
}

// 删除cookie
export function delCookie(c_name: string) {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(c_name);
  if (cval != null) {
    document.cookie = c_name + '=' + cval + ';expires=' + exp.toUTCString();
    document.cookie =
      c_name + '=' + cval + ';expires=' + exp.toUTCString() + ';domain=' + window.location.hostname + ';path=/';
  }
}
