/**
 * @Description: 机构页
 * @Author: Kermit
 * @Date: 2022-11-24 13:01:15
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-03 15:15:04
 */

import React from 'react';
import './Institution.css';
import GlobalLayout, { Nav } from '@app/layout-components/global-layout/GlobalLayout';
import { Avatar, Card, Divider, Typography } from 'antd';
import { getInstitutionList } from '@app/api-client';
import { InstitutionPageLink } from '@app/routes/Links';
import { UserOutlined } from '@ant-design/icons';

export interface IInstitutionState {
  institutionList: {
    /** 机构 ID */
    institutionId: string;
    /** 用户机构名 */
    institutionName: string;
    /** 机构头像 */
    headUrl: string;
    /** 机构简介 */
    description: string;
    /** 是否我的 */
    isMine: boolean;
  }[];
}

export default class Institution extends React.Component<Record<string, unknown>, IInstitutionState> {
  constructor(props) {
    super(props);
    this.state = {
      institutionList: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getInstitutionList();
  }

  async getInstitutionList() {
    const res = await getInstitutionList();
    this.setState({
      institutionList: res.list.map((institution) => ({
        institutionId: institution.institution_id,
        institutionName: institution.institution_name,
        headUrl: institution.head_url,
        description: institution.description,
        isMine: institution.is_mine,
      })),
    });
  }

  render(): React.ReactNode {
    return (
      <GlobalLayout activeNav={Nav.INSTITUTION} isHeaderTopicBackgroud isHeaderWhiteColor title="入驻机构">
        <div className="p-institution">
          <div className="institution-divider">
            <Divider orientation="center" style={{ fontSize: 26 }}>
              入驻 AlgoSpace 的机构
            </Divider>
          </div>
          <div className="institution-container">
            {this.state.institutionList.map((institution) => (
              <div key={institution.institutionId} className="institution-item">
                <InstitutionPageLink institutionId={institution.institutionId}>
                  <Card hoverable style={{ width: '100%' }}>
                    <div className="institution-body-box">
                      <div className="institution-head-pic">
                        {institution.headUrl ? (
                          <Avatar size="large" src={institution.headUrl} />
                        ) : (
                          <Avatar size="large" icon={<UserOutlined />} />
                        )}
                      </div>
                      <div className="institution-info">
                        <div className="institution-title">{institution.institutionName}</div>
                        <div className="institution-description">
                          <Typography.Paragraph ellipsis={{ rows: 5 }}>{institution.description}</Typography.Paragraph>
                        </div>
                      </div>
                    </div>
                  </Card>
                </InstitutionPageLink>
              </div>
            ))}
          </div>
        </div>
      </GlobalLayout>
    );
  }
}
