/**
 * @Description: 用户信息卡片组件
 * @Author: Kermit
 * @Date: 2022-11-29 11:18:20
 * @LastEditors: Kermit
 * @LastEditTime: 2023-01-27 18:50:17
 */

import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { UserPermission } from '@app/constants/user';
import { GroupPageLink, InstitutionPageLink, UserPageLink } from '@app/routes/Links';
import { Avatar } from 'antd';
import React from 'react';
import './UserCard.css';

export interface IUserCardProps {
  /** 用户名 */
  username?: string;
  /** 用户昵称 */
  usernickname?: string;
  /** 用户头像 */
  userHeadUrl?: string;
  /** 用户描述 */
  userDescription?: string;
  /** 用户身份 */
  userPermission?: UserPermission;
  /** 机构 ID */
  institutionId?: string;
  /** 用户机构名 */
  institutionName?: string;
  /** 机构头像 */
  institutionHeadUrl?: string;
  /** 机构简介 */
  institutionDescription?: string;
  /** 研究组 ID */
  groupId?: string;
  /** 用户研究组名 */
  groupName?: string;
  /** 研究组头像 */
  groupHeadUrl?: string;
  /** 研究组简介 */
  groupDescription?: string;
}

export interface IUserCardState {
  a?: string;
}

export default class UserCard extends React.Component<IUserCardProps, IUserCardState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  render(): React.ReactNode {
    return (
      <div className="c-user-card">
        <UserPageLink userId={this.props.username || ''}>
          <div className="user-card-title op-hover">
            <div className="user-head-pic">
              {this.props.userHeadUrl ? (
                <Avatar size="large" src={this.props.userHeadUrl} />
              ) : (
                <Avatar size="large" icon={<UserOutlined />} />
              )}
            </div>
            <div className="user-name">
              <div className="text-cut">
                {this.props.usernickname}
                {this.props.usernickname && <span className="user-subname">{' ' + this.props.username}</span>}
              </div>
              {this.props.userDescription && <div className="user-subname">{this.props.userDescription}</div>}
            </div>
          </div>
        </UserPageLink>

        {this.props.institutionName && (
          <div className="text-cut">
            <div className="institution-container">
              <InstitutionPageLink institutionId={this.props.institutionId || ''}>
                <span className="institution-card op-hover">
                  <span className="institution-head-pic">
                    {this.props.institutionHeadUrl ? (
                      <Avatar size="small" src={this.props.institutionHeadUrl} />
                    ) : (
                      <Avatar size="small" icon={<TeamOutlined />} />
                    )}
                  </span>
                  {this.props.institutionName}
                </span>
              </InstitutionPageLink>
              {this.props.groupName && (
                <>
                  <span className="divider">/</span>
                  <GroupPageLink groupId={this.props.groupId || ''}>
                    <span className="group-card op-hover">
                      {this.props.groupHeadUrl && (
                        <span className="group-head-pic">
                          <Avatar size="small" src={this.props.groupHeadUrl} />
                        </span>
                      )}
                      {this.props.groupName}
                    </span>
                  </GroupPageLink>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
