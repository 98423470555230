/**
 * @Description: 根组件
 * @Author: Kermit
 * @Date: 2022-11-23 12:43:27
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-03 14:46:16
 */

import React from 'react';
import './App.css';
import { Router } from './routes';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider } from 'antd';
import ErrorBoundary from '@app/base-components/ErrorBoundary';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <React.StrictMode>
      <ConfigProvider
        locale={zhCN}
        theme={{
          // 参见：https://ant.design/docs/react/customize-theme-cn
          // 颜色梯度计算：https://www.colorhexa.com
          token: {
            colorPrimary: '#5864ff', // 主题色
            colorInfo: '#5864ff', // 用于表示操作信息的 Token 序列，如 Alert 、Tag、 Progress 等组件都有用到该组梯度变量。
            colorInfoText: '#5864ff',
            colorPrimaryHover: '#5864ff', // 主色梯度下的悬浮态，使用频率很高
            colorPrimaryText: '#5864ff', // 主色梯度下的文本颜色
            colorLink: '#5864ff',
            colorLinkActive: '#5864ff',
            colorLinkHover: '#5864ff',
            controlItemBgActiveHover: '#edf5fa',

            colorText: '#222222', // 最深的文本色
          },
        }}
      >
        <ErrorBoundary>
          <Helmet>
            <title>AlgoSpace · 打造我的算法模型集</title>
          </Helmet>
          <Router />
        </ErrorBoundary>
      </ConfigProvider>
    </React.StrictMode>
  );
}

export default App;
