/**
 * @Description
 * @Author Kermit
 * @Date 2023-02-19 21:25:38
 * @LastEditors Kermit
 * @LastEditTime 2023-05-15 18:15:51
 */

import { gradioComponentHost, gradioComponentSessionStorageKey } from '.';

export function checkIsNeedGradioComponentSession() {
  const host = window.location.host;
  return gradioComponentHost === host;
}

export function getGradioComponentSession() {
  return localStorage.getItem(gradioComponentSessionStorageKey);
}

export function setGradioComponentSession(value: string) {
  if (!value) return;
  localStorage.setItem(gradioComponentSessionStorageKey, value);
}

export function deleteGradioComponentSession() {
  localStorage.removeItem(gradioComponentSessionStorageKey);
}
