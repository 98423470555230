/**
 * @Description: 页面跳转 Link 组件们
 * @Author: Kermit
 * @Date: 2022-11-29 13:57:15
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-02 16:36:00
 */

import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type BasicProps = {
  children: React.ReactNode;
  reloadDocument?: boolean;
};

/** 算法详情页的 Link 组件 */
export function AlgorithmDetailLink(
  props: BasicProps &
    Omit<LinkProps & React.RefAttributes<HTMLAnchorElement>, 'to'> & {
      name: string;
      version: string;
    },
) {
  const { name, version, children, ...restProps } = props;
  return (
    <Link to={`/algorithm/${name}/${version}`} {...restProps}>
      {children}
    </Link>
  );
}

/** 个人主页的 Link 组件 */
export function UserPageLink(props: BasicProps & { userId: string; tab?: string }) {
  const { userId, tab, children, ...restProps } = props;
  return (
    <Link to={`/user/${userId}${tab ? `/${tab}` : ''}`} {...restProps}>
      {children}
    </Link>
  );
}

/** 研究组主页的 Link 组件 */
export function GroupPageLink(props: BasicProps & { groupId: string }) {
  const { groupId, children, ...restProps } = props;
  return (
    <Link to={`/group/${groupId}`} {...restProps}>
      {children}
    </Link>
  );
}

/** 机构主页的 Link 组件 */
export function InstitutionPageLink(props: BasicProps & { institutionId: string }) {
  const { institutionId, children, ...restProps } = props;
  return (
    <Link to={`/institution/${institutionId}`} {...restProps}>
      {children}
    </Link>
  );
}

/** 发布算法的 Link 组件 */
export function PublishLink(props: BasicProps & { tab?: string }) {
  const { tab, children, ...restProps } = props;
  return (
    <Link to={`/publish${tab ? `/${tab}` : ''}`} {...restProps}>
      {children}
    </Link>
  );
}

/** 用户设置的 Link 组件 */
export function UserSettingLink(props: BasicProps) {
  const { children, ...restProps } = props;
  return (
    <Link to={'/setting/user'} {...restProps}>
      {children}
    </Link>
  );
}
