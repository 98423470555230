/**
 * @Description: 算法搜索器
 * @Author: Kermit
 * @Date: 2022-11-24 18:11:28
 * @LastEditors: Kermit
 * @LastEditTime: 2023-01-27 18:39:13
 */

import { getAlgoSearch } from '@app/api-client';
import { sleep } from '@app/utils';
import { Input, Menu } from 'antd';
import React, { Component } from 'react';
import './AlgoRearch.css';

export interface IAlgoRearchProps {
  size?: 'small' | 'middle' | 'large';
  onSearch: (value: string) => void;
  placeholder?: string;
  value?: string;

  statusFilter?: '' | 'CAN_CALL';
  scopeFilter?: '' | 'ONLY_INSTITUTION' | 'ONLY_GROUP' | 'ONLY_MINE';
  institutionFilter?: string;
  groupFilter?: string;
  userFilter?: string;
}

export interface IAlgoRearchState {
  searchList: { name: string }[];
  value: string;
  isFocus: boolean;
}

export default class AlgoRearch extends Component<IAlgoRearchProps, IAlgoRearchState> {
  constructor(props) {
    super(props);
    this.state = {
      searchList: [],
      value: props.value || '',
      isFocus: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<IAlgoRearchProps>): void {
    if (prevProps.value !== this.props.value && this.props.value != undefined) {
      this.setState({ value: this.props.value });
    }
  }

  onSearch(value: string) {
    this.props.onSearch(value);
  }

  async onInput(e) {
    const value = e.target.value;
    this.setState({ value });
    if (value) {
      const { list } = await getAlgoSearch(value, 10, {
        ...(this.props.statusFilter === 'CAN_CALL' ? { can_call: true } : {}),
        ...(this.props.scopeFilter === 'ONLY_INSTITUTION' ? { only_in_my_institution: true } : {}),
        ...(this.props.scopeFilter === 'ONLY_GROUP' ? { only_in_my_group: true } : {}),
        ...(this.props.scopeFilter === 'ONLY_MINE' ? { only_mine: true } : {}),
        ...(this.props.institutionFilter ? { institution_id: this.props.institutionFilter } : {}),
        ...(this.props.groupFilter ? { group_id: this.props.groupFilter } : {}),
        ...(this.props.userFilter ? { user_id: this.props.userFilter } : {}),
      });
      this.setState({
        searchList: list.map((item) => ({ name: item })),
      });
    } else {
      this.setState({
        searchList: [],
      });
    }
  }

  getMenuItems() {
    return this.state.searchList.map((item) => ({
      key: item.name,
      label: <span className="text-cut">{item.name}</span>,
    }));
  }

  chooseMunu(e) {
    this.onSearch(e.key);
    this.setState({ value: e.key, searchList: [] });
  }

  onBlur() {
    if (!this.state.value) this.onSearch('');
    sleep(100).then(() => this.setState({ isFocus: false }));
  }

  onFocus() {
    this.setState({ isFocus: true });
  }

  render(): React.ReactNode {
    return (
      <div className="c-algo-rearch">
        <Input.Search
          placeholder={this.props.placeholder || '搜索算法应用'}
          value={this.state.value}
          onSearch={this.onSearch.bind(this)}
          onInput={this.onInput.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onFocus={this.onFocus.bind(this)}
          size={this.props.size}
          style={{ width: '100%' }}
        />
        {this.state.isFocus && this.state.searchList.length > 0 && (
          <div className="tick-menu">
            <Menu
              onClick={this.chooseMunu.bind(this)}
              style={{ width: '100%' }}
              mode="vertical"
              items={this.getMenuItems()}
            />
          </div>
        )}
      </div>
    );
  }
}
