/**
 * @Description: 云端托管算法配置填写器
 * @Author: Kermit
 * @Date: 2022-12-28 09:59:29
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-22 19:27:05
 */

import React from 'react';
import './CloudDeployConfig.css';
import { Button, Collapse, Divider, Drawer, Form, FormInstance, Input, Select, Tooltip, message } from 'antd';
import CloudDeployFile from '../cloud-deploy-file/CloudDeployFile';
import { DownOutlined, MinusCircleOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { AlgoParamType } from '@app/constants/algo';
import { getAlgoConfig, updateAlgoConfig } from '@app/api-client';

export interface ICloudDeployConfigProps {
  algoname: string;
  version: string;
  onConfigChange?: (isComplete: boolean) => void;
}

export interface ICloudDeployConfigState {
  isUpdating: boolean;
  isEdited: boolean;
  selectFileDrawerTitle: string;
  isOpenSelectFileDrawer: boolean;
  isOpenSelectGradioFileDrawer: boolean;
  isCollapseOptionalField: boolean;
  serviceInputParamIndexList: number[];
  serviceOutputParamIndexList: number[];
  serviceInputExamplesIndexList: number[];
  onSelectFile: (filePath: string) => void;
}

export default class CloudDeployConfig extends React.Component<ICloudDeployConfigProps, ICloudDeployConfigState> {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      isEdited: true,
      selectFileDrawerTitle: '',
      isOpenSelectFileDrawer: false,
      isOpenSelectGradioFileDrawer: false,
      isCollapseOptionalField: true,
      serviceInputParamIndexList: [0],
      serviceOutputParamIndexList: [0],
      serviceInputExamplesIndexList: [],
      onSelectFile: () => {},
    };
  }

  /** 生命周期：挂载后 */
  async componentDidMount() {
    await this.initGeneralForm();
    this.checkConfigComplete();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 基本信息设置表单引用 */
  generalFormRef = React.createRef<FormInstance>();
  /** 可选选项的属性名（如果有初始值就要展开） */
  private readonly optionalFieldNameList = ['gradio_launch_filepath', 'gradio_launch_function'];
  /** 初始化基本信息设置表单 */
  async initGeneralForm() {
    try {
      const config = await getAlgoConfig(this.props.algoname, this.props.version);
      const {
        service_filepath,
        service_function,
        service_input: ori_service_input,
        service_output: ori_service_output,
        service_input_examples: ori_service_input_examples,
        service_timeout,
        service_max_parallel,
        base_image,
        requirements: ori_requirements,
        pre_command: ori_pre_command,
        gradio_launch_filepath,
        gradio_launch_function,
        gradio_launch_host,
        gradio_launch_port,
      } = config;
      const requirements = ori_requirements?.join('\n');
      const pre_command = ori_pre_command?.join('\n');

      if (this.optionalFieldNameList.some((fieldName) => config[fieldName])) {
        // 展开可选选项
        this.uncollapseOptionalFields();
      }

      this.generalFormRef.current!.setFieldsValue({
        ...(service_filepath ? { service_filepath } : {}),
        ...(service_function ? { service_function } : {}),
        ...(service_timeout ? { service_timeout } : {}),
        ...(service_max_parallel ? { service_max_parallel } : {}),
        ...(base_image ? { base_image } : {}),
        ...(requirements ? { requirements } : {}),
        ...(pre_command ? { pre_command } : {}),
        ...(gradio_launch_filepath ? { gradio_launch_filepath } : {}),
        ...(gradio_launch_function ? { gradio_launch_function } : {}),
        ...(gradio_launch_host ? { gradio_launch_host } : {}),
        ...(gradio_launch_port ? { gradio_launch_port } : {}),
      });

      if (ori_service_input) {
        const serviceInputParamIndexList = [...Array(Object.keys(ori_service_input).length)].map((_, index) => index);
        this.setState({ serviceInputParamIndexList }, () => {
          for (const paramIndex of serviceInputParamIndexList) {
            this.generalFormRef.current!.setFieldsValue({
              [`service_input_key_${paramIndex}`]: Object.keys(ori_service_input)[paramIndex],
              [`service_input_type_${paramIndex}`]: ori_service_input[Object.keys(ori_service_input)[paramIndex]].type,
              [`service_input_describe_${paramIndex}`]:
                ori_service_input[Object.keys(ori_service_input)[paramIndex]].describe,
            });
          }
        });
      }
      if (ori_service_output) {
        const serviceOutputParamIndexList = [...Array(Object.keys(ori_service_output).length)].map((_, index) => index);
        this.setState({ serviceOutputParamIndexList }, () => {
          for (const paramIndex of serviceOutputParamIndexList) {
            this.generalFormRef.current!.setFieldsValue({
              [`service_output_key_${paramIndex}`]: Object.keys(ori_service_output)[paramIndex],
              [`service_output_type_${paramIndex}`]:
                ori_service_output[Object.keys(ori_service_output)[paramIndex]].type,
              [`service_output_describe_${paramIndex}`]:
                ori_service_output[Object.keys(ori_service_output)[paramIndex]].describe,
            });
          }
        });
      }
      if (ori_service_input_examples) {
        const serviceInputExamplesIndexList = [...Array(Object.keys(ori_service_input_examples).length)].map(
          (_, index) => index,
        );
        this.setState({ serviceInputExamplesIndexList }, () => {
          for (const expIndex of serviceInputExamplesIndexList) {
            const service_input_example = {};
            for (const paramIndex in Object.keys(ori_service_input)) {
              const key = Object.keys(ori_service_input)[paramIndex];
              service_input_example[`service_input_example_${expIndex}_${paramIndex}`] =
                ori_service_input_examples[expIndex][key];
            }
            this.generalFormRef.current!.setFieldsValue(service_input_example);
          }
        });
      }

      this.setState({ isEdited: Object.keys(config).length === 0 }, () => this.checkConfigComplete());
    } catch (err) {}
  }
  /** 监听基本信息表单变化 */
  async onGeneralFormChange() {
    this.setState({ isEdited: true }, () => this.checkConfigComplete());
  }
  /** 监听基本信息表单完成 */
  async onGeneralFormFinish(values) {
    try {
      // 获取参数
      const {
        service_filepath,
        service_function,
        service_timeout,
        service_max_parallel,
        base_image,
        gradio_launch_filepath,
        gradio_launch_function,
        gradio_launch_host,
        gradio_launch_port,
      } = values;
      const requirements = values.requirements?.split('\n') || [];
      const pre_command = values.pre_command?.split('\n') || [];
      const service_input = {};
      const service_output = {};
      const service_input_examples: Record<string, any>[] = [];
      for (const paramIndex of this.state.serviceInputParamIndexList) {
        service_input[values[`service_input_key_${paramIndex}`]] = {
          type: values[`service_input_type_${paramIndex}`],
          describe: values[`service_input_describe_${paramIndex}`],
        };
      }
      for (const paramIndex of this.state.serviceOutputParamIndexList) {
        service_output[values[`service_output_key_${paramIndex}`]] = {
          type: values[`service_output_type_${paramIndex}`],
          describe: values[`service_output_describe_${paramIndex}`],
        };
      }
      for (const expIndex of this.state.serviceInputExamplesIndexList) {
        const example = {};
        for (const paramIndex of this.state.serviceInputParamIndexList) {
          example[values[`service_input_key_${paramIndex}`]] =
            values[`service_input_example_${expIndex}_${paramIndex}`];
        }
        service_input_examples.push(example);
      }

      this.setState({ isUpdating: true });

      await updateAlgoConfig(this.props.algoname, this.props.version, {
        service_filepath,
        service_function,
        service_input,
        service_output,
        service_input_examples,
        service_timeout,
        service_max_parallel,
        base_image,
        requirements,
        pre_command,
        gradio_launch_filepath,
        gradio_launch_function,
        gradio_launch_host,
        gradio_launch_port,
      });

      message.success('提交成功');
      this.setState({ isUpdating: false, isEdited: false }, () => this.checkConfigComplete());
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '提交失败');
    }
  }
  checkConfigComplete() {
    this.props.onConfigChange?.(!this.state.isEdited);
  }

  openSelectFileDrawer(title: string, onSelectFile: (filePath: string) => void) {
    this.setState({ isOpenSelectFileDrawer: true, selectFileDrawerTitle: title, onSelectFile });
  }

  closeSelectFileDrawer() {
    this.setState({ isOpenSelectFileDrawer: false });
  }

  onSelectServiceFile(filePath: string) {
    this.generalFormRef.current!.setFieldsValue({
      service_filepath: filePath,
    });
    this.setState({ isEdited: true });
    this.closeSelectFileDrawer();
  }

  addServiceInputParamNum() {
    this.setState((state) => ({
      serviceInputParamIndexList: state.serviceInputParamIndexList.concat(
        state.serviceInputParamIndexList[state.serviceInputParamIndexList.length - 1] + 1,
      ),
    }));
  }

  minusServiceInputParamNum(index: number) {
    if (this.state.serviceInputParamIndexList.length === 1) {
      return message.warning('最少需要一个输入参数');
    }
    const serviceInputParamIndexList = this.state.serviceInputParamIndexList;
    serviceInputParamIndexList.splice(index, 1);
    this.setState({ serviceInputParamIndexList });
  }

  addServiceOutputParamNum() {
    this.setState((state) => ({
      serviceOutputParamIndexList: state.serviceOutputParamIndexList.concat(
        state.serviceOutputParamIndexList[state.serviceOutputParamIndexList.length - 1] + 1,
      ),
    }));
  }

  minusServiceOutputParamNum(index: number) {
    if (this.state.serviceOutputParamIndexList.length === 1) {
      return message.warning('最少需要一个输出参数');
    }
    const serviceOutputParamIndexList = this.state.serviceOutputParamIndexList;
    serviceOutputParamIndexList.splice(index, 1);
    this.setState({ serviceOutputParamIndexList });
  }

  addServiceInputExamplesNum() {
    this.setState((state) => ({
      serviceInputExamplesIndexList: state.serviceInputExamplesIndexList.concat(
        state.serviceInputExamplesIndexList[state.serviceInputExamplesIndexList.length - 1] + 1,
      ),
    }));
  }

  getInputByParamIndex(paramIndex: number) {
    return {
      key: this.generalFormRef.current!.getFieldValue(`service_input_key_${paramIndex}`),
      type: this.generalFormRef.current!.getFieldValue(`service_input_type_${paramIndex}`),
      describe: this.generalFormRef.current!.getFieldValue(`service_input_describe_${paramIndex}`),
    };
  }

  onSelectServiceInputExampleFile(formName: string, filePath: string) {
    this.generalFormRef.current!.setFieldsValue({
      [formName]: filePath,
    });
    this.setState({ isEdited: true });
    this.closeSelectFileDrawer();
  }

  minusServiceInputExamplesNum(index: number) {
    const serviceInputExamplesIndexList = this.state.serviceInputExamplesIndexList;
    serviceInputExamplesIndexList.splice(index, 1);
    this.setState({ serviceInputExamplesIndexList });
  }

  openSelectGradioFileDrawer() {
    this.setState({ isOpenSelectGradioFileDrawer: true });
  }

  closeSelectGradioFileDrawer() {
    this.setState({ isOpenSelectGradioFileDrawer: false });
  }

  onSelectGradioFile(filePath: string) {
    this.generalFormRef.current!.setFieldsValue({
      gradio_launch_filepath: filePath,
    });
    this.setState({ isEdited: true });
    this.closeSelectGradioFileDrawer();
  }

  uncollapseOptionalFields() {
    this.setState({ isCollapseOptionalField: false });
  }

  collapseOptionalFields() {
    this.setState({ isCollapseOptionalField: true });
  }

  render(): React.ReactNode {
    return (
      <div className="c-cloud-deploy-config">
        <div className="form-wrapper">
          <Form
            name="basic"
            ref={this.generalFormRef}
            onChange={this.onGeneralFormChange.bind(this)}
            onFinish={this.onGeneralFormFinish.bind(this)}
            autoComplete="off"
          >
            <div className="form-flex-wrapper">
              <div className="form-flex-item">
                <div className="form-title">算法服务函数所在文件</div>
                <div className="form-input">
                  <div
                    className="select-file"
                    onClick={() =>
                      this.openSelectFileDrawer('选择算法服务函数所在文件', this.onSelectServiceFile.bind(this))
                    }
                  >
                    <Form.Item
                      name="service_filepath"
                      rules={[{ required: true, message: '请选择算法服务函数所在文件' }]}
                    >
                      <Input placeholder="选择算法服务函数所在文件" suffix={<DownOutlined />} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="form-flex-item">
                <div className="form-title">算法服务函数名</div>
                <div className="form-input">
                  <Form.Item name="service_function" rules={[{ required: true, message: '请输入算法服务函数名' }]}>
                    <Input size="middle" placeholder="输入算法服务函数名" />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="form-title">
              函数输入参数
              <Tooltip title="每个参数对应函数传参的一项">
                <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div className="form-input">
              {this.state.serviceInputParamIndexList.map((paramIndex, index) => (
                <div className="service-param-wrapper" key={paramIndex}>
                  <div className="service-param-icon" onClick={this.minusServiceInputParamNum.bind(this, index)}>
                    <MinusCircleOutlined />
                  </div>
                  <div className="service-param-title">参数 {index + 1}</div>
                  <Form.Item
                    name={`service_input_key_${paramIndex}`}
                    rules={[{ required: true, message: '请输入函数输入参数' }]}
                  >
                    <Input size="middle" placeholder="输入参数名" />
                  </Form.Item>
                  <Form.Item
                    name={`service_input_type_${paramIndex}`}
                    rules={[{ required: true, message: '请选择参数类型' }]}
                  >
                    <Select
                      placeholder="选择参数类型"
                      options={[
                        {
                          value: AlgoParamType.str,
                          label: '字符串',
                        },
                        {
                          value: AlgoParamType.int,
                          label: '定点数',
                        },
                        {
                          value: AlgoParamType.float,
                          label: '浮点数',
                        },
                        {
                          value: AlgoParamType.imagePath,
                          label: '图片本地路径',
                        },
                        {
                          value: AlgoParamType.videoPath,
                          label: '视频本地路径',
                        },
                        {
                          value: AlgoParamType.voicePath,
                          label: '音频本地路径',
                        },
                      ]}
                      onSelect={() => this.forceUpdate()}
                    ></Select>
                  </Form.Item>
                  <Form.Item name={`service_input_describe_${paramIndex}`}>
                    <Input size="middle" placeholder="输入参数简要描述（可选）" />
                  </Form.Item>
                </div>
              ))}
              <Button
                type="dashed"
                style={{ width: '100%', marginBottom: '25px' }}
                onClick={this.addServiceInputParamNum.bind(this)}
              >
                <PlusCircleOutlined />
                添加一个参数
              </Button>
            </div>

            <div className="form-title">
              函数输出参数
              <Tooltip title="每个参数对应函数返回的字典中的一项">
                <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div className="form-input">
              {this.state.serviceOutputParamIndexList.map((paramIndex, index) => (
                <div className="service-param-wrapper" key={paramIndex}>
                  <div className="service-param-icon" onClick={this.minusServiceOutputParamNum.bind(this, index)}>
                    <MinusCircleOutlined />
                  </div>
                  <div className="service-param-title">参数 {index + 1}</div>
                  <Form.Item
                    name={`service_output_key_${paramIndex}`}
                    rules={[{ required: true, message: '请输入函数输出参数' }]}
                  >
                    <Input size="middle" placeholder="输入参数名" />
                  </Form.Item>
                  <Form.Item
                    name={`service_output_type_${paramIndex}`}
                    rules={[{ required: true, message: '请选择参数类型' }]}
                  >
                    <Select
                      placeholder="选择参数类型"
                      options={[
                        {
                          value: AlgoParamType.str,
                          label: '字符串',
                        },
                        {
                          value: AlgoParamType.int,
                          label: '定点数',
                        },
                        {
                          value: AlgoParamType.float,
                          label: '浮点数',
                        },
                        {
                          value: AlgoParamType.imagePath,
                          label: '图片本地路径',
                        },
                        {
                          value: AlgoParamType.videoPath,
                          label: '视频本地路径',
                        },
                        {
                          value: AlgoParamType.voicePath,
                          label: '音频本地路径',
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                  <Form.Item name={`service_output_describe_${paramIndex}`}>
                    <Input size="middle" placeholder="输入参数简要描述（可选）" />
                  </Form.Item>
                </div>
              ))}
              <Button
                type="dashed"
                style={{ width: '100%', marginBottom: '25px' }}
                onClick={this.addServiceOutputParamNum.bind(this)}
              >
                <PlusCircleOutlined />
                添加一个参数
              </Button>
            </div>

            <div className="form-title">
              函数输入参数示例
              <Tooltip title="用于展示给用户输入用例">
                <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div className="form-input">
              {this.state.serviceInputExamplesIndexList.map((expIndex, index) => (
                <div className="service-param-wrapper" key={expIndex}>
                  <div className="service-param-icon" onClick={this.minusServiceInputExamplesNum.bind(this, index)}>
                    <MinusCircleOutlined />
                  </div>
                  <div className="service-param-title">示例 {index + 1}</div>
                  <div className="service-param-block">
                    {this.state.serviceInputParamIndexList.map((paramIndex) => (
                      <div className="service-param-block-wrapper" key={paramIndex}>
                        <div className="service-param-block-title text-cut">
                          {this.getInputByParamIndex(paramIndex).key || '未命名参数'}
                        </div>
                        {this.getInputByParamIndex(paramIndex).type === AlgoParamType.str && (
                          <Form.Item
                            name={`service_input_example_${expIndex}_${paramIndex}`}
                            rules={[{ required: true, message: '请输入函数输入示例' }]}
                          >
                            <Input size="middle" placeholder="输入参数示例值" />
                          </Form.Item>
                        )}
                        {this.getInputByParamIndex(paramIndex).type === AlgoParamType.int && (
                          <Form.Item
                            name={`service_input_example_${expIndex}_${paramIndex}`}
                            rules={[{ required: true, message: '请输入函数输入示例' }]}
                          >
                            <Input size="middle" type="number" placeholder="输入参数示例值" />
                          </Form.Item>
                        )}
                        {this.getInputByParamIndex(paramIndex).type === AlgoParamType.float && (
                          <Form.Item
                            name={`service_input_example_${expIndex}_${paramIndex}`}
                            rules={[{ required: true, message: '请输入函数输入示例' }]}
                          >
                            <Input size="middle" type="number" placeholder="输入参数示例值" />
                          </Form.Item>
                        )}
                        {this.getInputByParamIndex(paramIndex).type === AlgoParamType.imagePath && (
                          <div
                            className="service-param-select-file"
                            onClick={() =>
                              this.openSelectFileDrawer(
                                '选择示例文件',
                                this.onSelectServiceInputExampleFile.bind(
                                  this,
                                  `service_input_example_${expIndex}_${paramIndex}`,
                                ),
                              )
                            }
                          >
                            <Form.Item
                              name={`service_input_example_${expIndex}_${paramIndex}`}
                              rules={[{ required: true, message: '请输入函数输入示例' }]}
                            >
                              <Input placeholder="选择参数示例值" suffix={<DownOutlined />} />
                            </Form.Item>
                          </div>
                        )}
                        {this.getInputByParamIndex(paramIndex).type === AlgoParamType.videoPath && (
                          <div
                            className="service-param-select-file"
                            onClick={() =>
                              this.openSelectFileDrawer(
                                '选择示例文件',
                                this.onSelectServiceInputExampleFile.bind(
                                  this,
                                  `service_input_example_${expIndex}_${paramIndex}`,
                                ),
                              )
                            }
                          >
                            <Form.Item
                              name={`service_input_example_${expIndex}_${paramIndex}`}
                              rules={[{ required: true, message: '请输入函数输入示例' }]}
                            >
                              <Input placeholder="选择参数示例值" suffix={<DownOutlined />} />
                            </Form.Item>
                          </div>
                        )}
                        {this.getInputByParamIndex(paramIndex).type === AlgoParamType.voicePath && (
                          <div
                            className="service-param-select-file"
                            onClick={() =>
                              this.openSelectFileDrawer(
                                '选择示例文件',
                                this.onSelectServiceInputExampleFile.bind(
                                  this,
                                  `service_input_example_${expIndex}_${paramIndex}`,
                                ),
                              )
                            }
                          >
                            <Form.Item
                              name={`service_input_example_${expIndex}_${paramIndex}`}
                              rules={[{ required: true, message: '请输入函数输入示例' }]}
                            >
                              <Input placeholder="选择参数示例值" suffix={<DownOutlined />} />
                            </Form.Item>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <Button
                type="dashed"
                style={{ width: '100%', marginBottom: '25px' }}
                onClick={this.addServiceInputExamplesNum.bind(this)}
              >
                <PlusCircleOutlined />
                添加一个示例
              </Button>
            </div>

            <div className="form-flex-wrapper">
              <div className="form-flex-item">
                <div className="form-title">
                  超时时间
                  <Tooltip title="单位: 秒">
                    <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
                  </Tooltip>
                </div>
                <div className="form-input">
                  <Form.Item
                    name="service_timeout"
                    initialValue={60}
                    rules={[{ required: true, message: '请输入超时时间' }]}
                  >
                    <Input size="middle" type="number" min={1} placeholder="输入超时时间" />
                  </Form.Item>
                </div>
              </div>
              <div className="form-flex-item">
                <div className="form-title">
                  最大同时处理请求数
                  <Tooltip title="若大于 1，则需要保证函数处理时的线程安全">
                    <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
                  </Tooltip>
                </div>
                <div className="form-input">
                  <Form.Item
                    name="service_max_parallel"
                    initialValue={1}
                    rules={[{ required: true, message: '请输入最大同时处理请求数' }]}
                  >
                    <Input size="middle" type="number" min={1} placeholder="输入最大同时处理请求数" />
                  </Form.Item>
                </div>
              </div>
            </div>

            <Divider style={{ marginTop: 30 }}>容器构建配置</Divider>

            <div className="form-flex-item">
              <div className="form-title">基础镜像</div>
              <div className="form-input">
                <Form.Item
                  name="base_image"
                  initialValue={'python:3.9'}
                  rules={[{ required: true, message: '请输入构建容器镜像使用的基础镜像' }]}
                >
                  <Input prefix="FROM " size="middle" placeholder="输入构建容器镜像使用的基础镜像" />
                </Form.Item>
              </div>
            </div>

            <div className="form-title">
              Python 依赖包（可选）
              <Tooltip title="同 requirement.txt 格式">
                <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div className="form-input">
              <Form.Item name="requirements">
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 10 }}
                  size="middle"
                  placeholder="输入所需 Python 依赖包，同 requirement.txt 格式"
                />
              </Form.Item>
            </div>

            <div className="form-title">
              软件安装命令（可选）
              <Tooltip title="用于配置环境的命令，构建镜像时依次执行，如 apt install -y libgl1-mesa-glx，每一行是一条命令">
                <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <div className="form-input">
              <Form.Item name="pre_command">
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 10 }}
                  size="middle"
                  placeholder="输入构建容器镜像时软件安装命令，如 apt install -y libgl1-mesa-glx，每一行是一条命令"
                />
              </Form.Item>
            </div>

            <Divider style={{ paddingTop: 30 }}>可选配置</Divider>
            <Collapse
              style={{ marginBottom: 60 }}
              activeKey={this.state.isCollapseOptionalField ? '0' : '1'}
              onChange={
                this.state.isCollapseOptionalField
                  ? this.uncollapseOptionalFields.bind(this)
                  : this.collapseOptionalFields.bind(this)
              }
            >
              <Collapse.Panel header="展开可选配置" key="1">
                <Divider>
                  自实现 Gradio 配置
                  <Tooltip title="需要自主实现更复杂的 Gradio 应用时，可使用以下选项替代自动生成的 Gradio 应用">
                    <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
                  </Tooltip>
                </Divider>
                <div className="form-flex-wrapper">
                  <div className="form-flex-item">
                    <div className="form-title">Gradio 入口函数所在文件</div>
                    <div className="form-input">
                      <div className="select-file" onClick={this.openSelectGradioFileDrawer.bind(this)}>
                        <Form.Item name="gradio_launch_filepath">
                          <Input placeholder="选择 Gradio 入口函数所在文件" suffix={<DownOutlined />} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="form-flex-item">
                    <div className="form-title">Gradio 入口函数</div>
                    <div className="form-input">
                      <Form.Item name="gradio_launch_function">
                        <Input size="middle" placeholder="输入 Gradio 入口函数" />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="form-flex-wrapper">
                  <div className="form-flex-item">
                    <div className="form-title">
                      Gradio 地址
                      <Tooltip title="同 Gradio 的 GRADIO_SERVER_NAME 配置">
                        <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                    <div className="form-input">
                      <Form.Item
                        name="gradio_launch_host"
                        initialValue={'127.0.0.1'}
                        rules={[{ required: true, message: '请输入 Gradio 地址' }]}
                      >
                        <Input size="middle" placeholder="输入 Gradio 地址" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="form-flex-item">
                    <div className="form-title">
                      Gradio 端口号
                      <Tooltip title="同 Gradio 的 GRADIO_SERVER_PORT 配置">
                        <QuestionCircleOutlined style={{ marginLeft: '0.5em', cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                    <div className="form-input">
                      <Form.Item
                        name="gradio_launch_port"
                        initialValue={7860}
                        rules={[{ required: true, message: '请输入 Gradio 端口号' }]}
                      >
                        <Input size="middle" type="number" min={1} placeholder="输入 Gradio 端口号" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Collapse.Panel>
            </Collapse>

            <div className="btn-wrapper">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  disabled={this.state.isUpdating || !this.state.isEdited}
                >
                  {this.state.isUpdating ? '提交中...' : '提交'}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>

        <Drawer
          title={this.state.selectFileDrawerTitle}
          placement="bottom"
          height="80vh"
          onClose={this.closeSelectFileDrawer.bind(this)}
          open={this.state.isOpenSelectFileDrawer}
        >
          <div className="cloud-deploy-file-wrapper">
            <CloudDeployFile
              algoname={this.props.algoname}
              version={this.props.version}
              isSelectMode={true}
              onSelectFile={this.state.onSelectFile.bind(this)}
            ></CloudDeployFile>
          </div>
        </Drawer>

        <Drawer
          title="选择 Gradio 入口函数所在文件"
          placement="bottom"
          height="80vh"
          onClose={this.closeSelectGradioFileDrawer.bind(this)}
          open={this.state.isOpenSelectGradioFileDrawer}
        >
          <div className="cloud-deploy-file-wrapper">
            <CloudDeployFile
              algoname={this.props.algoname}
              version={this.props.version}
              isSelectMode={true}
              onSelectFile={this.onSelectGradioFile.bind(this)}
            ></CloudDeployFile>
          </div>
        </Drawer>
      </div>
    );
  }
}
