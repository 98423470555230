/**
 * @Description
 * @Author Kermit
 * @Date 2023-02-17 21:56:56
 * @LastEditors Kermit
 * @LastEditTime 2023-05-15 17:58:31
 */

import { delCookie } from '@app/utils/cookie';
import { customCookieHeaderCookie, customCookieHeaderSetCookie, customCookieName, sessionCookieName } from '.';

const institutionList = [
  {
    id: '63847bdd63a016065e0f9378',
    name: '中国人民大学',
    shortName: '人大',
    enShortName: 'RUC',
    hosts: ['algospace.ruc.edu.cn', 'ruc.preview.algospace.top'],
  },
];

export function getCurrInstitution() {
  const host = window.location.host;
  return institutionList.find((item) => item.hosts.includes(host));
}

export function checkIsNeedCustomCookie() {
  return !!getCurrInstitution() || window.location.host.includes('localhost');
}

function setCustomCookie(cookie: string) {
  document.cookie = cookie
    .replace(new RegExp(`(^|;)( )?${sessionCookieName}=`), `$1$2${customCookieName}=`)
    .replace(/(^|;)( )?Domain=.*?($|;)/, `$1$2Domain=${window.location.host.replace(/:\d+$/, '')}$3`)
    .replace(/(^|;)( )?SameSite=.*?($|;)/, `$3`)
    .replace(/(^|;)( )?Secure($|;)/, '$3')
    .replace(/(^|;)( )?HttpOnly($|;)/, '$3');
}

function getCustomCookie() {
  return document.cookie.replace(new RegExp(`(^|;)( )?${customCookieName}=`), `$1$2${sessionCookieName}=`);
}

export function setCustomCookieByHeader(header: Record<string, string>) {
  if (!header[customCookieHeaderSetCookie]) return;
  setCustomCookie(header[customCookieHeaderSetCookie]);
}

export function getCustomCookieHeader() {
  return {
    [customCookieHeaderCookie]: getCustomCookie(),
  };
}

export function deleteCustomCookieOfSession() {
  delCookie(customCookieName);
}
