/**
 * @Description:
 * @Author: Kermit
 * @Date: 2023-03-11 19:24:18
 * @LastEditors: Kermit
 * @LastEditTime: 2023-03-29 13:05:20
 */

import React from 'react';
import './AlgorithmStatistic.css';
import * as echarts from 'echarts';
import { Radio } from 'antd';
import { getAlgoStatisticCallCurve, getAlgoStatisticSuccessCurve, getAlgoStatisticUserRank } from '@app/api-client';

export interface IAlgorithmStatisticProps {
  isIndependentComponent?: boolean;
  algoname: string;
  version: string;
}

export interface IAlgorithmStatisticState {
  tab: 'minutely' | 'daily' | 'monthly' | 'yearly';
}

export default class AlgorithmStatistic extends React.Component<IAlgorithmStatisticProps, IAlgorithmStatisticState> {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'monthly',
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.init();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  init() {
    this.getAlgoStatisticCallCurve('gradio');
    this.getAlgoStatisticCallCurve('api');
    this.getAlgoStatisticSuccessCurve('gradio');
    this.getAlgoStatisticSuccessCurve('api');
    ['monthly', 'yearly'].includes(this.state.tab) && this.getAlgoStatisticUserRank('gradio');
    ['monthly', 'yearly'].includes(this.state.tab) && this.getAlgoStatisticUserRank('api');
  }

  getRangeAndUnit() {
    if (this.state.tab === 'minutely') {
      return {
        range: 30,
        unit: 'minute',
      };
    } else if (this.state.tab === 'daily') {
      return {
        range: 24,
        unit: 'hour',
      };
    } else if (this.state.tab === 'monthly') {
      return {
        range: 30,
        unit: 'day',
      };
    } else if (this.state.tab === 'yearly') {
      return {
        range: 12,
        unit: 'month',
      };
    } else {
      return {
        range: 24,
        unit: 'hour',
      };
    }
  }

  async getAlgoStatisticCallCurve(type: 'gradio' | 'api') {
    const { list } = await getAlgoStatisticCallCurve(
      this.props.isIndependentComponent || false,
      this.props.algoname,
      this.props.version,
      type,
      this.getRangeAndUnit().range,
      this.getRangeAndUnit().unit,
    );
    this.renderCallCurve(
      type,
      list.map((item) => ({ x: item.date, y: item.count })),
    );
  }

  async getAlgoStatisticSuccessCurve(type: 'gradio' | 'api') {
    const { list } = await getAlgoStatisticSuccessCurve(
      this.props.isIndependentComponent || false,
      this.props.algoname,
      this.props.version,
      type,
      this.getRangeAndUnit().range,
      this.getRangeAndUnit().unit,
    );
    this.renderSuccessCurve(
      type,
      list.map((item) => ({ x: item.date, y: item.success_rate })),
    );
  }
  async getAlgoStatisticUserRank(type: 'gradio' | 'api') {
    const { list } = await getAlgoStatisticUserRank(
      this.props.isIndependentComponent || false,
      this.props.algoname,
      this.props.version,
      type,
      this.getRangeAndUnit().range,
      this.getRangeAndUnit().unit,
      10,
    );
    this.renderUserRank(
      type,
      list.map((item) => ({
        x: item.nickname || (item.user_id.startsWith('VISITOR_') ? '访客' : item.user_id),
        y: item.count,
      })),
    );
  }

  renderCallCurve(type: 'gradio' | 'api', data: { x: string; y: number }[]) {
    const chart = echarts.init(document.getElementById(`call-curve-${type}`) as HTMLElement);
    chart.setOption({
      title: {
        text: type === 'gradio' ? '演示应用调用次数' : 'API 服务调用次数',
      },
      tooltip: {},
      xAxis: {
        data: data.map((item) => item.x),
      },
      yAxis: {},
      series: [
        {
          name: '时间',
          type: 'line',
          data: data.map((item) => item.y),
          smooth: true,
          itemStyle: {
            color: '#5864ff',
          },
          lineStyle: {
            normal: {
              color: '#5864ff',
              width: 2,
            },
          },
        },
      ],
    });
  }

  renderSuccessCurve(type: 'gradio' | 'api', data: { x: string; y: number }[]) {
    const chart = echarts.init(document.getElementById(`success-curve-${type}`) as HTMLElement);
    chart.setOption({
      title: {
        text: type === 'gradio' ? '演示应用完成率' : 'API 服务完成率',
      },
      tooltip: {},
      xAxis: {
        data: data.map((item) => item.x),
      },
      yAxis: {},
      series: [
        {
          name: '时间',
          type: 'line',
          data: data.map((item) => item.y),
          smooth: true,
          itemStyle: {
            color: '#5864ff',
          },
          lineStyle: {
            normal: {
              color: '#5864ff',
              width: 2,
            },
          },
        },
      ],
    });
  }

  renderUserRank(type: 'gradio' | 'api', data: { x: string; y: number }[]) {
    const chart = echarts.init(document.getElementById(`user-rank-${type}`) as HTMLElement);
    chart.setOption({
      title: {
        text: type === 'gradio' ? '演示应用前 10 名调用用户' : 'API 服务前 10 名调用用户',
      },
      tooltip: {},
      xAxis: {
        data: data.map((item) => item.x),
      },
      yAxis: {},
      series: [
        {
          name: '用户',
          type: 'bar',
          data: data.map((item) => item.y),
          itemStyle: {
            color: '#5864ff',
          },
        },
      ],
    });
  }

  onRadioGroupChange(e) {
    this.setState({ tab: e.target.value }, () => this.init());
  }

  render(): React.ReactNode {
    return (
      <div className="c-algorithm-statistic">
        <div className="range-selector">
          <Radio.Group value={this.state.tab} onChange={this.onRadioGroupChange.bind(this)}>
            <Radio.Button value="minutely">最近 30 分</Radio.Button>
            <Radio.Button value="daily">最近 1 天</Radio.Button>
            <Radio.Button value="monthly">最近 1 月</Radio.Button>
            <Radio.Button value="yearly">最近 1 年</Radio.Button>
          </Radio.Group>
        </div>

        <div className="chart-container">
          <div className="chart-item">
            <div className="chart" id="call-curve-gradio" />
          </div>
          <div className="chart-item">
            <div className="chart" id="call-curve-api" />
          </div>
          <div className="chart-item">
            <div className="chart" id="success-curve-gradio" />
          </div>
          <div className="chart-item">
            <div className="chart" id="success-curve-api" />
          </div>
          {['monthly', 'yearly'].includes(this.state.tab) && (
            <>
              <div className="chart-item">
                <div className="chart" id="user-rank-gradio" />
              </div>
              <div className="chart-item">
                <div className="chart" id="user-rank-api" />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
