/**
 * @Description: 算法页
 * @Author: Kermit
 * @Date: 2022-11-24 13:01:15
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-03 15:16:29
 */

import React from 'react';
import './Algorithm.css';
import GlobalLayout, { Nav } from '@app/layout-components/global-layout/GlobalLayout';
import AlgoRearch from '@app/components/algo-search/AlgoRearch';
import AlgoSelector from '@app/components/algo-selector/AlgoSelector';
import AlgoList from '@app/components/algo-list/AlgoList';
import { getCurrInstitution } from '@app/config/customize';

export interface IAlgorithmProps {
  a?: string;
}

export interface IAlgorithmState {
  nameFilter: string;
  taskFilter: string;
  statusFilter: '' | 'CAN_CALL';
  scopeFilter: '' | 'ONLY_INSTITUTION' | 'ONLY_GROUP' | 'ONLY_MINE';
  institutionFilter: string;

  isSearchMode: boolean;
}

export default class Algorithm extends React.Component<IAlgorithmProps, IAlgorithmState> {
  constructor(props) {
    super(props);
    this.state = {
      nameFilter: '',
      taskFilter: '',
      statusFilter: '',
      scopeFilter: '',
      institutionFilter: getCurrInstitution()?.id || '',
      isSearchMode: false,
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  onSearchNameConform(value: string) {
    this.setState({ nameFilter: value }, () => this.checkIsSearchMode());
  }

  onTaskSelect(taskId: string) {
    this.setState({ taskFilter: taskId }, () => this.checkIsSearchMode());
  }

  onStatusSelect(value: '' | 'CAN_CALL') {
    this.setState({ statusFilter: value }, () => this.checkIsSearchMode());
  }

  onScopeSelect(value: '' | 'ONLY_INSTITUTION' | 'ONLY_GROUP' | 'ONLY_MINE') {
    this.setState({ scopeFilter: value }, () => this.checkIsSearchMode());
  }

  onInstitutionSelect(value: string) {
    this.setState({ institutionFilter: value }, () => this.checkIsSearchMode());
  }

  checkIsSearchMode() {
    const isSearchMode = Boolean(
      this.state.nameFilter ||
        this.state.taskFilter ||
        this.state.statusFilter ||
        this.state.scopeFilter ||
        this.state.institutionFilter,
    );
    this.setState({ isSearchMode });
  }

  render(): React.ReactNode {
    return (
      <GlobalLayout
        activeNav={Nav.ALGORITHM}
        isHeaderTopicBackgroud
        isHeaderWhiteColor
        title={!getCurrInstitution() ? '算法社区' : getCurrInstitution()?.shortName + '算法池'}
      >
        <div className="p-algorithm">
          <div className="layout-container">
            <div className="side-layout">
              <AlgoRearch
                size="large"
                onSearch={this.onSearchNameConform.bind(this)}
                statusFilter={this.state.statusFilter}
                scopeFilter={this.state.scopeFilter}
                institutionFilter={this.state.institutionFilter}
              />
              <AlgoSelector
                onTaskSelect={this.onTaskSelect.bind(this)}
                onStatusSelect={this.onStatusSelect.bind(this)}
                onScopeSelect={this.onScopeSelect.bind(this)}
                onInstitutionSelect={this.onInstitutionSelect.bind(this)}
              />
            </div>
            <div className="body-layout">
              {!this.state.isSearchMode && (
                <AlgoList
                  title="热门算法应用"
                  isHidePagination={true}
                  isHideOrderBy={true}
                  isHot={true}
                  isOnlyLatest={true}
                />
              )}
              <AlgoList
                title="全部算法应用"
                nameFilter={this.state.nameFilter}
                taskFilter={this.state.taskFilter}
                statusFilter={this.state.statusFilter}
                scopeFilter={this.state.scopeFilter}
                institutionFilter={this.state.institutionFilter}
                isOnlyLatest={true}
              />
            </div>
          </div>
        </div>
      </GlobalLayout>
    );
  }
}
