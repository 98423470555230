/**
 * @Description: 成员列表组件
 * @Author: Kermit
 * @Date: 2022-11-30 19:28:03
 * @LastEditors: Kermit
 * @LastEditTime: 2023-03-13 15:47:47
 */

import { getUserList, updateInstitutionMemberSequence, updateGroupMemberSequence } from '@app/api-client';
import DragList from '@app/base-components/drag-list/DragList';
import { UserPermission } from '@app/constants/user';
import { UserPageLink } from '@app/routes/Links';
import { Avatar, Card, Divider, Empty } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';
import './MemberList.css';

export interface IMemberListProps {
  id: string;
  type: 'GROUP' | 'INSTITUTION' | 'INSTITUTION_NORMAL';
  canUpdateSequence?: boolean;
}

export interface IMemberListState {
  normalList: {
    userId: string;
    nickname: string;
    headUrl: string;
    description: string;
    isMine: boolean;
  }[];
  memberList: {
    userId: string;
    nickname: string;
    headUrl: string;
    description: string;
    isMine: boolean;
  }[];
  middleLeaderList: {
    userId: string;
    nickname: string;
    headUrl: string;
    description: string;
    isMine: boolean;
  }[];
  leaderList: {
    userId: string;
    nickname: string;
    headUrl: string;
    description: string;
    isMine: boolean;
  }[];
}

export default class MemberList extends React.Component<IMemberListProps, IMemberListState> {
  constructor(props) {
    super(props);
    this.state = {
      normalList: [],
      memberList: [],
      middleLeaderList: [],
      leaderList: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getLeaderList();
    this.getMiddleLeaderList();
    this.getMemberList();
    this.getNormalList();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 获取普通用户列表 */
  async getNormalList() {
    if (this.props.type === 'INSTITUTION_NORMAL') {
      const res = await getUserList(1, 1000, {
        institution_id: this.props.id,
        permission: UserPermission.NORMAL,
      });
      this.setState({
        normalList: res.list.map((user) => ({
          userId: user.user_id,
          nickname: user.nickname,
          headUrl: user.head_url,
          description: user.description,
          isMine: user.is_mine,
        })),
      });
    }
  }

  /** 获取普通成员列表 */
  async getMemberList() {
    if (this.props.type === 'GROUP' || this.props.type === 'INSTITUTION') {
      const res = await getUserList(1, 1000, {
        ...(this.props.type === 'GROUP' ? { group_id: this.props.id } : {}),
        ...(this.props.type === 'INSTITUTION' ? { institution_id: this.props.id } : {}),
        permission: UserPermission.MEMBER,
      });
      this.setState({
        memberList: res.list.map((user) => ({
          userId: user.user_id,
          nickname: user.nickname,
          headUrl: user.head_url,
          description: user.description,
          isMine: user.is_mine,
        })),
      });
    }
  }

  /** 获取中间领导成员列表 */
  async getMiddleLeaderList() {
    if (this.props.type === 'INSTITUTION') {
      // 机构的中间领导成员是研究组领导
      const res = await getUserList(1, 1000, {
        institution_id: this.props.id,
        permission: UserPermission.GROUP_LEADER,
      });
      this.setState({
        middleLeaderList: res.list.map((user) => ({
          userId: user.user_id,
          nickname: user.nickname,
          headUrl: user.head_url,
          description: user.description,
          isMine: user.is_mine,
        })),
      });
    }
  }

  /** 获取领导成员列表 */
  async getLeaderList() {
    if (this.props.type === 'INSTITUTION' || this.props.type === 'GROUP') {
      const res = await getUserList(1, 1000, {
        ...(this.props.type === 'GROUP' ? { group_id: this.props.id } : {}),
        ...(this.props.type === 'INSTITUTION' ? { institution_id: this.props.id } : {}),
        permission: this.props.type === 'GROUP' ? UserPermission.GROUP_LEADER : UserPermission.INSTITUTION_LEADER,
      });
      this.setState({
        leaderList: res.list.map((user) => ({
          userId: user.user_id,
          nickname: user.nickname,
          headUrl: user.head_url,
          description: user.description,
          isMine: user.is_mine,
        })),
      });
    }
  }

  updateLeaderSequence(idList: string[]) {
    this.setState({
      leaderList: this.state.leaderList.sort((a, b) => idList.indexOf(a.userId) - idList.indexOf(b.userId)),
    });
    if (this.props.type === 'INSTITUTION') {
      updateInstitutionMemberSequence(idList, UserPermission.INSTITUTION_LEADER);
    } else if (this.props.type === 'GROUP') {
      updateGroupMemberSequence(idList, UserPermission.GROUP_LEADER);
    }
  }

  updateMiddleLeaderSequence(idList: string[]) {
    this.setState({
      middleLeaderList: this.state.middleLeaderList.sort((a, b) => idList.indexOf(a.userId) - idList.indexOf(b.userId)),
    });
    if (this.props.type === 'INSTITUTION') {
      updateInstitutionMemberSequence(idList, UserPermission.GROUP_LEADER);
    }
  }

  updateMemberSequence(idList: string[]) {
    this.setState({
      memberList: this.state.memberList.sort((a, b) => idList.indexOf(a.userId) - idList.indexOf(b.userId)),
    });
    if (this.props.type === 'INSTITUTION') {
      updateInstitutionMemberSequence(idList, UserPermission.MEMBER);
    } else if (this.props.type === 'GROUP') {
      updateGroupMemberSequence(idList, UserPermission.MEMBER);
    }
  }

  render(): React.ReactNode {
    return (
      <div className="c-member-list">
        {this.state.leaderList.length > 0 && (
          <>
            <div className="title-divider">
              <div className="divider">
                <Divider orientation="left" style={{ fontSize: 20 }}>
                  管理员
                </Divider>
              </div>
              <div className="selector"></div>
            </div>
            <div className="member-container">
              <DragList
                isShowDragger={this.props.canUpdateSequence}
                onDragEnd={this.updateLeaderSequence.bind(this)}
                containers={this.state.leaderList.map((member) => ({
                  id: member.userId,
                  children: (
                    <div key={member.userId} className="member-item">
                      <UserPageLink userId={member.userId}>
                        <Card hoverable style={{ width: '100%' }}>
                          <Meta
                            avatar={<Avatar size="large" src={member.headUrl}></Avatar>}
                            title={
                              member.nickname ? (
                                <span>
                                  {member.nickname}
                                  <span className="user-subname">{' ' + member.userId}</span>
                                </span>
                              ) : (
                                <span>{member.userId}</span>
                              )
                            }
                            description={<div className="text-cut-1">{member.description}</div>}
                          />
                        </Card>
                      </UserPageLink>
                    </div>
                  ),
                }))}
              />
            </div>
          </>
        )}
        {this.state.middleLeaderList.length > 0 && (
          <>
            <div className="title-divider">
              <div className="divider">
                <Divider orientation="left" style={{ fontSize: 20 }}>
                  研究组管理员
                </Divider>
              </div>
              <div className="selector"></div>
            </div>
            <div className="member-container">
              <DragList
                isShowDragger={this.props.canUpdateSequence}
                onDragEnd={this.updateMiddleLeaderSequence.bind(this)}
                containers={this.state.middleLeaderList.map((member) => ({
                  id: member.userId,
                  children: (
                    <div key={member.userId} className="member-item">
                      <UserPageLink userId={member.userId}>
                        <Card hoverable style={{ width: '100%' }}>
                          <Meta
                            avatar={<Avatar size="large" src={member.headUrl}></Avatar>}
                            title={
                              member.nickname ? (
                                <span>
                                  {member.nickname}
                                  <span className="user-subname">{' ' + member.userId}</span>
                                </span>
                              ) : (
                                <span>{member.userId}</span>
                              )
                            }
                            description={<div className="text-cut-1">{member.description}</div>}
                          />
                        </Card>
                      </UserPageLink>
                    </div>
                  ),
                }))}
              />
            </div>
          </>
        )}
        {this.state.memberList.length > 0 && (
          <>
            <div className="title-divider">
              <div className="divider">
                <Divider orientation="left" style={{ fontSize: 20 }}>
                  成员
                </Divider>
              </div>
              <div className="selector"></div>
            </div>
            <div className="member-container">
              <DragList
                isShowDragger={this.props.canUpdateSequence}
                onDragEnd={this.updateMemberSequence.bind(this)}
                containers={this.state.memberList.map((member) => ({
                  id: member.userId,
                  children: (
                    <div key={member.userId} className="member-item">
                      <UserPageLink userId={member.userId}>
                        <Card hoverable style={{ width: '100%' }}>
                          <Meta
                            avatar={<Avatar size="large" src={member.headUrl}></Avatar>}
                            title={
                              member.nickname ? (
                                <span>
                                  {member.nickname}
                                  <span className="user-subname">{' ' + member.userId}</span>
                                </span>
                              ) : (
                                <span>{member.userId}</span>
                              )
                            }
                            description={<div className="text-cut-1">{member.description}</div>}
                          />
                        </Card>
                      </UserPageLink>
                    </div>
                  ),
                }))}
              />
            </div>
          </>
        )}
        {this.state.normalList.length > 0 && (
          <>
            <div className="title-divider">
              <div className="divider">
                <Divider orientation="left" style={{ fontSize: 20 }}>
                  校友
                </Divider>
              </div>
              <div className="selector"></div>
            </div>
            <div className="member-container">
              <DragList
                isShowDragger={this.props.canUpdateSequence}
                onDragEnd={() => {}}
                containers={this.state.normalList.map((member) => ({
                  id: member.userId,
                  children: (
                    <div key={member.userId} className="member-item">
                      <UserPageLink userId={member.userId}>
                        <Card hoverable style={{ width: '100%' }}>
                          <Meta
                            avatar={<Avatar size="large" src={member.headUrl}></Avatar>}
                            title={
                              member.nickname ? (
                                <span>
                                  {member.nickname}
                                  <span className="user-subname">{' ' + member.userId}</span>
                                </span>
                              ) : (
                                <span>{member.userId}</span>
                              )
                            }
                            description={<div className="text-cut-1">{member.description}</div>}
                          />
                        </Card>
                      </UserPageLink>
                    </div>
                  ),
                }))}
              />
            </div>
          </>
        )}
        {this.state.leaderList.length === 0 &&
          this.state.middleLeaderList.length === 0 &&
          this.state.memberList.length === 0 &&
          this.state.normalList.length === 0 && (
            <div className="member-empty">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>暂无用户</span>} />
            </div>
          )}
      </div>
    );
  }
}
