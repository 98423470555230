/**
 * @Description: 算法设置组件
 * @Author: Kermit
 * @Date: 2022-12-02 15:34:54
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-30 18:27:02
 */

import {
  ApiOutlined,
  BookOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { getAlgoItem, updateAlgoItem, getAlgoTaskList, deleteAlgoItem, uploadCoverPicUrl } from '@app/api-client';
import Markdown from '@app/base-components/markdown/Markdown';
import { AlgoScope } from '@app/constants/algo';
import { UserPermission } from '@app/constants/user';
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  Menu,
  MenuProps,
  Select,
  message,
  Drawer,
  Tag,
  InputNumber,
  Popconfirm,
  Upload,
  UploadFile,
} from 'antd';
import React from 'react';
import './AlgorithmSetting.css';
import { UploadChangeParam } from 'antd/es/upload';
import { checkIsNeedCustomCookie, getCustomCookieHeader } from '@app/config/customize';

export interface IAlgorithmSettingProps {
  algoname: string;
  version: string;
}

export interface IAlgorithmSettingState {
  tab: string;
  isUpdating: boolean;
  openDocumentPreview: boolean;
  documentPreview: string;
  isCoverpicFileListInit: boolean;
  coverpicFileList: UploadFile[];

  /** 是否可以调用 */
  canCall?: boolean;
  /** 算法描述 */
  description?: string;
  /** 封面 */
  coverUrl?: string;
  /** 用户名 */
  username?: string;
  /** 用户昵称 */
  usernickname?: string;
  /** 用户头像 */
  userHeadUrl?: string;
  /** 用户描述 */
  userDescription?: string;
  /** 用户身份 */
  userPermission?: UserPermission;
  /** 机构 ID */
  institutionId?: string;
  /** 用户机构名 */
  institutionName?: string;
  /** 机构头像 */
  institutionHeadUrl?: string;
  /** 机构简介 */
  institutionDescription?: string;
  /** 研究组 ID */
  groupId?: string;
  /** 用户研究组名 */
  groupName?: string;
  /** 研究组头像 */
  groupHeadUrl?: string;
  /** 研究组简介 */
  groupDescription?: string;
  /** 算法可见范围 */
  scope?: AlgoScope;
  /** 创建时间 */
  createTime?: string;
  /** Gradio 应用链接 */
  gradioUrl?: string;
  /** 输入参数 */
  inputParam?: any[];
  /** 输出参数 */
  outputParam?: any[];
  /** 中文名 */
  chineseName?: string;
  paper?: string;
  github?: string;
  tasks?: Task[];
  tags?: string[];
  /** 文档 */
  document?: string;
  /** 调用方法 */
  apiMd?: string;
  /** 调用示例 */
  example?: string;
  request_per_second_for_public?: number;
  request_max_parallel_for_public?: number;
  request_per_second_for_private?: number;
  request_max_parallel_for_private?: number;
  /** 调用输出示例 */
  outputExample?: string;
  /** 是否是我的 */
  isMine?: boolean;

  tagsIndexList: number[];
  algoTaskList: TaskListItem[];
}

type MenuItem = Required<MenuProps>['items'][number];
type Task = {
  id: string;
  name: string;
  classify: string;
  description: string;
  tag_color: string;
  icon: string;
};
type TaskListItem = {
  classify: string;
  list: {
    id: string;
    name: string;
    description: string;
    tag_color: string;
    icon: string;
    is_root: boolean;
  }[];
  count: number;
};

export default class AlgorithmSetting extends React.Component<IAlgorithmSettingProps, IAlgorithmSettingState> {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'general',
      isUpdating: false,
      openDocumentPreview: false,
      documentPreview: '',
      isCoverpicFileListInit: false,
      coverpicFileList: [],
      tagsIndexList: [],
      algoTaskList: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getAlgoInfo();
    this.getAlgoTaskList();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 获取算法信息 */
  async getAlgoInfo() {
    const res = await getAlgoItem(this.props.algoname, this.props.version);
    this.setState({
      canCall: res.can_call,
      description: res.description,
      coverUrl: res.cover_url,
      username: res.user.user_id,
      usernickname: res.user.nickname,
      userHeadUrl: res.user.head_url,
      userDescription: res.user.description,
      userPermission: res.user.permission,
      institutionId: res.user.institution?.id,
      institutionName: res.user.institution?.institution_name,
      institutionHeadUrl: res.user.institution?.head_url,
      institutionDescription: res.user.institution?.description,
      groupId: res.user.group?.id,
      groupName: res.user.group?.group_name,
      groupHeadUrl: res.user.group?.head_url,
      groupDescription: res.user.group?.description,
      scope: res.scope,
      createTime: res.create_day,
      gradioUrl: res.gradio_url,
      inputParam: res.input_param,
      outputParam: res.output_param,
      chineseName: res.chinese_name,
      paper: res.paper,
      github: res.github,
      tasks: res.tasks,
      tags: res.tags,
      document: res.document,
      apiMd: res.api_md,
      example: res.example,
      outputExample: res.output_example,
      request_per_second_for_public: res.request_per_second_for_public,
      request_max_parallel_for_public: res.request_max_parallel_for_public,
      request_per_second_for_private: res.request_per_second_for_private,
      request_max_parallel_for_private: res.request_max_parallel_for_private,
      isMine: res.is_mine,
    });

    this.state.tab === 'general' &&
      this.initGeneralForm(res.description, res.chinese_name, res.scope, res.paper, res.github, res.tasks, res.tags);
    this.state.tab === 'general' && this.initUploadCoverPicFileList(res.cover_url);
    this.state.tab === 'document' && this.initDocumentForm(res.document);
    this.state.tab === 'param' &&
      this.initParamForm(
        res.request_per_second_for_public,
        res.request_max_parallel_for_public,
        res.request_per_second_for_private,
        res.request_max_parallel_for_private,
      );
  }

  async getAlgoTaskList() {
    const res = await getAlgoTaskList();
    this.setState({ algoTaskList: res.list });
  }

  getMenuItem(key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem {
    return {
      key,
      label: this.getMenuLabel(key),
      icon,
      children,
      type,
    } as MenuItem;
  }

  getMenuLabel(key: React.Key) {
    switch (key) {
      case 'general':
        return '基本信息';
      case 'document':
        return '文档编写';
      case 'param':
        return '调用控制';
      default:
        return '';
    }
  }

  /** 菜单栏项 */
  items: MenuItem[] = [
    this.getMenuItem('general', <SettingOutlined />),
    this.getMenuItem('document', <BookOutlined />),
    this.getMenuItem('param', <ApiOutlined />),
  ];

  /** 选择菜单项事件 */
  onSelectTab({ key }) {
    const tab = key;
    this.setState({ tab }, () => {
      tab === 'general' &&
        this.initGeneralForm(
          this.state.description || '',
          this.state.chineseName || '',
          this.state.scope || AlgoScope.PRIVATE,
          this.state.paper || '',
          this.state.github || '',
          this.state.tasks || [],
          this.state.tags || [],
        );
      tab === 'general' && this.initUploadCoverPicFileList(this.state.coverUrl || '');
      tab === 'document' && this.initDocumentForm(this.state.document || '');
      this.state.tab === 'param' &&
        this.initParamForm(
          this.state.request_per_second_for_public,
          this.state.request_max_parallel_for_public,
          this.state.request_per_second_for_private,
          this.state.request_max_parallel_for_private,
        );
    });
  }

  /** 基本信息设置表单引用 */
  generalFormRef = React.createRef<FormInstance>();
  /** 初始化基本信息设置表单 */
  initGeneralForm(
    description: string,
    chineseName: string,
    scope: AlgoScope,
    paper?: string,
    github?: string,
    tasks?: Task[],
    tags?: string[],
  ) {
    this.generalFormRef.current!.setFieldsValue({
      description,
      chinese_name: chineseName,
      scope,
      paper,
      github,
      task_ids: tasks?.map((task) => task.id) || [],
    });
    if (tags) {
      const tagsIndexList = [...Array(Object.keys(tags).length)].map((_, index) => index);
      this.setState({ tagsIndexList }, () => {
        for (const i in tags) {
          const tag = tags[i];
          const [tagContent, tagColor] = tag.split('###');
          this.generalFormRef.current!.setFieldsValue({
            [`tags_content_${i}`]: tagContent,
            [`tags_color_${i}`]: tagColor || '',
          });
        }
      });
    }
    if (tasks) {
    }
  }
  /** 监听基本信息表单完成 */
  async onGeneralFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      let coverPic: string | undefined = undefined;
      if (this.state.coverpicFileList.length > 0) {
        coverPic = this.state.coverpicFileList[0].url;
      }
      coverPic = coverPic || '';
      const tags: string[] = [];
      for (const tagIndex of this.state.tagsIndexList) {
        if (values[`tags_color_${tagIndex}`]) {
          tags.push(`${values[`tags_content_${tagIndex}`]}###${values[`tags_color_${tagIndex}`]}`);
        } else {
          tags.push(values[`tags_content_${tagIndex}`]);
        }
      }
      await updateAlgoItem(this.props.algoname, this.props.version, {
        cover_url: coverPic,
        description: values.description,
        chinese_name: values.chinese_name,
        scope: values.scope,
        paper: values.paper,
        github: values.github,
        tags,
        task_ids: values.task_ids,
      });
      message.success('更新成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '更新失败');
    }
  }
  initUploadCoverPicFileList(coverUrl: string) {
    const coverpicFileList: UploadFile[] = coverUrl
      ? [
          {
            uid: '-1',
            name: 'file',
            status: 'done',
            url: coverUrl,
          },
        ]
      : [];
    this.setState({ coverpicFileList, isCoverpicFileListInit: true });
  }
  onCoverpicFileListChange = ({ fileList }: UploadChangeParam<UploadFile>) => {
    this.setState({
      coverpicFileList: fileList.map((item) => ({
        url: item.response?.data?.filepath ? uploadCoverPicUrl + '/' + item.response?.data?.filepath : undefined,
        ...item,
      })),
    });
  };

  /** 文档编写设置表单引用 */
  documentFormRef = React.createRef<FormInstance>();
  /** 初始化文档编写设置表单 */
  initDocumentForm(document: string) {
    this.documentFormRef.current!.setFieldsValue({
      document,
    });
  }
  /** 监听文档编写表单完成 */
  async onDocumentFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      await updateAlgoItem(this.props.algoname, this.props.version, {
        document: values.document,
      });
      message.success('更新成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '更新失败');
    }
  }
  /** 展示文档预览 */
  openDocumentPreview() {
    const documentPreview = this.documentFormRef.current!.getFieldValue('document');
    this.setState({ openDocumentPreview: true, documentPreview });
  }
  /** 关闭文档预览 */
  closeDocumentPreview() {
    this.setState({ openDocumentPreview: false });
  }

  /** 参数设置表单引用 */
  paramFormRef = React.createRef<FormInstance>();
  /** 初始化参数设置表单 */
  initParamForm(
    request_per_second_for_public?: number,
    request_max_parallel_for_public?: number,
    request_per_second_for_private?: number,
    request_max_parallel_for_private?: number,
  ) {
    this.paramFormRef.current!.setFieldsValue({
      request_per_second_for_public,
      request_max_parallel_for_public,
      request_per_second_for_private,
      request_max_parallel_for_private,
    });
  }
  /** 监听参数表单完成 */
  async onParamFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      await updateAlgoItem(this.props.algoname, this.props.version, {
        request_per_second_for_public: values.request_per_second_for_public,
        request_max_parallel_for_public: values.request_max_parallel_for_public,
        request_per_second_for_private: values.request_per_second_for_private,
        request_max_parallel_for_private: values.request_max_parallel_for_private,
      });
      message.success('更新成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '更新失败');
    }
  }

  addTagsIndexListNum() {
    this.setState((state) => ({
      tagsIndexList: state.tagsIndexList.concat(
        state.tagsIndexList.length > 0 ? state.tagsIndexList[state.tagsIndexList.length - 1] + 1 : 0,
      ),
    }));
  }

  minusTagsIndexListNum(index: number) {
    const tagsIndexList = this.state.tagsIndexList;
    tagsIndexList.splice(index, 1);
    this.setState({ tagsIndexList });
  }

  async deleteAlgorithm() {
    try {
      await deleteAlgoItem(this.props.algoname, this.props.version);
      message.success('删除成功', 1, () => {
        window.location.reload();
      });
    } catch (err: any) {
      message.error(err.message || '删除失败');
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <div className="algo-setting-container">
          <div className="algo-setting-menu">
            <Menu
              mode="inline"
              selectedKeys={[this.state.tab]}
              onSelect={this.onSelectTab.bind(this)}
              style={{ width: '100%' }}
              items={this.items}
            />
          </div>
          <div className="algo-setting-content">
            <div className="algo-setting-content-header">
              <Divider orientation="left" style={{ fontSize: 18 }}>
                {this.getMenuLabel(this.state.tab)}
              </Divider>
            </div>
            <div className="algo-setting-content-body">
              {/* 基本信息设置 */}
              {this.state.tab === 'general' && (
                <div className="form-wrapper">
                  <Form
                    name="basic"
                    ref={this.generalFormRef}
                    onFinish={this.onGeneralFormFinish.bind(this)}
                    autoComplete="off"
                  >
                    <div className="form-title">算法名</div>
                    <div className="form-input">
                      <Form.Item name="algoname" initialValue={this.props.algoname}>
                        <Input disabled={true} size="middle" />
                      </Form.Item>
                    </div>
                    <div className="form-title">版本</div>
                    <div className="form-input">
                      <Form.Item name="version" initialValue={this.props.version}>
                        <Input disabled={true} size="middle" />
                      </Form.Item>
                    </div>
                    <div className="form-title">中文名</div>
                    <div className="form-input">
                      <Form.Item name="chinese_name">
                        <Input size="middle" placeholder="输入中文名（可选）" />
                      </Form.Item>
                    </div>
                    <div className="form-title">可见范围</div>
                    <div className="form-input">
                      <Form.Item name="scope">
                        <Select
                          options={[
                            {
                              value: AlgoScope.PRIVATE,
                              label: '自己可见',
                            },
                            ...(this.state.groupId && this.state.userPermission !== 'NORMAL'
                              ? [
                                  {
                                    value: AlgoScope.GROUP,
                                    label: '研究组内可见',
                                  },
                                ]
                              : []),
                            ...(this.state.institutionId && this.state.userPermission !== 'NORMAL'
                              ? [
                                  {
                                    value: AlgoScope.INSTITUTION,
                                    label: '机构内可见',
                                  },
                                ]
                              : []),
                            {
                              value: AlgoScope.PUBLIC,
                              label: '公开',
                            },
                          ]}
                        ></Select>
                      </Form.Item>
                    </div>
                    <div className="form-title">描述</div>
                    <div className="form-input">
                      <Form.Item name="description">
                        <Input.TextArea
                          autoSize={{ minRows: 5, maxRows: 10 }}
                          size="middle"
                          placeholder="输入算法描述（可选），可选格式：纯文本、HTML"
                        />
                      </Form.Item>
                    </div>
                    <div className="form-title">封面</div>
                    <div className="form-upload">
                      {this.state.isCoverpicFileListInit && (
                        <Upload
                          name="file"
                          listType="picture-card"
                          accept="image/gif,image/jpeg,image/jpg,image/png"
                          withCredentials
                          defaultFileList={this.state.coverpicFileList}
                          action={uploadCoverPicUrl}
                          onChange={this.onCoverpicFileListChange.bind(this)}
                          headers={checkIsNeedCustomCookie() ? getCustomCookieHeader() : {}}
                        >
                          {this.state.coverpicFileList.length >= 1 ? null : (
                            <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>上传</div>
                            </div>
                          )}
                        </Upload>
                      )}
                    </div>
                    <div className="form-title">任务领域</div>
                    <div className="form-input">
                      <Form.Item name="task_ids">
                        <Select
                          mode="multiple"
                          placeholder="选择任务领域（可选）"
                          options={this.state.algoTaskList.map((item) => ({
                            label: `${item.classify} (${item.count})`,
                            options: item.list.map((subItem) => ({ label: subItem.name, value: subItem.id })),
                          }))}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-title">Github 链接</div>
                    <div className="form-input">
                      <Form.Item name="github" rules={[{ type: 'url', message: '不是一个有效的 URL 链接' }]}>
                        <Input size="middle" placeholder="输入 Github 链接（可选）" />
                      </Form.Item>
                    </div>
                    <div className="form-title">文献链接</div>
                    <div className="form-input">
                      <Form.Item name="paper" rules={[{ type: 'url', message: '不是一个有效的 URL 链接' }]}>
                        <Input size="middle" placeholder="输入文献链接（可选）" />
                      </Form.Item>
                    </div>
                    <div className="form-title">应用标签</div>
                    <div className="form-input">
                      {this.state.tagsIndexList.map((paramIndex, index) => (
                        <div className="tags-wrapper" key={paramIndex}>
                          <div className="tags-icon" onClick={this.minusTagsIndexListNum.bind(this, index)}>
                            <MinusCircleOutlined />
                          </div>
                          <div className="tags-title">标签 {index + 1}</div>
                          <Form.Item
                            name={`tags_content_${paramIndex}`}
                            rules={[
                              { required: true, message: '请输入标签' },
                              { max: 20, message: '标签长度不能超过 20 字符' },
                            ]}
                          >
                            <Input size="middle" placeholder="输入标签" />
                          </Form.Item>
                          <Form.Item name={`tags_color_${paramIndex}`}>
                            <Select
                              placeholder="选择标签样式"
                              defaultValue=""
                              options={[
                                {
                                  value: '',
                                  label: <Tag>默认样式</Tag>,
                                },
                                {
                                  value: 'magenta',
                                  label: <Tag color="magenta">品红色</Tag>,
                                },
                                {
                                  value: 'red',
                                  label: <Tag color="red">红色</Tag>,
                                },
                                {
                                  value: 'orange',
                                  label: <Tag color="orange">橙色</Tag>,
                                },
                                {
                                  value: 'gold',
                                  label: <Tag color="gold">金色</Tag>,
                                },
                                {
                                  value: 'green',
                                  label: <Tag color="green">绿色</Tag>,
                                },
                                {
                                  value: 'cyan',
                                  label: <Tag color="cyan">青色</Tag>,
                                },
                                {
                                  value: 'blue',
                                  label: <Tag color="blue">蓝色</Tag>,
                                },
                                {
                                  value: 'purple',
                                  label: <Tag color="purple">紫色</Tag>,
                                },
                              ]}
                            ></Select>
                          </Form.Item>
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        style={{ width: '100%', marginBottom: '25px' }}
                        onClick={this.addTagsIndexListNum.bind(this)}
                      >
                        <PlusCircleOutlined />
                        添加一个标签
                      </Button>
                    </div>

                    <div className="btn-wrapper">
                      <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                          {this.state.isUpdating ? '更新中...' : '更新'}
                        </Button>
                      </Form.Item>

                      <Form.Item>
                        <Popconfirm
                          title={`确认删除算法应用？`}
                          onConfirm={() => this.deleteAlgorithm()}
                          okText="确认"
                          cancelText="取消"
                        >
                          <Button type="link" danger block size="large" disabled={this.state.isUpdating}>
                            删除算法应用
                          </Button>
                        </Popconfirm>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              )}

              {/* 文档编写设置 */}
              {this.state.tab === 'document' && (
                <div className="form-wrapper document-form-wrapper">
                  <Form
                    name="basic"
                    ref={this.documentFormRef}
                    onFinish={this.onDocumentFormFinish.bind(this)}
                    autoComplete="off"
                  >
                    <div className="form-input">
                      <Form.Item name="document">
                        <Input.TextArea
                          autoSize={{ minRows: 10 }}
                          size="middle"
                          placeholder="详细介绍算法的要点，可选格式：纯文本、Markdown、HTML"
                        />
                      </Form.Item>
                    </div>

                    <div className="btn-wrapper">
                      <Form.Item>
                        <Button type="default" block size="large" onClick={this.openDocumentPreview.bind(this)}>
                          预览
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                          {this.state.isUpdating ? '更新中...' : '更新'}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              )}

              {/* 参数设置 */}
              {this.state.tab === 'param' && (
                <div className="form-wrapper">
                  <Form
                    name="basic"
                    ref={this.paramFormRef}
                    onFinish={this.onParamFormFinish.bind(this)}
                    autoComplete="off"
                  >
                    {this.state.scope === AlgoScope.PUBLIC && (
                      <>
                        <div className="form-title">外部最大调用频率</div>
                        <div className="form-sub-title">
                          面向访客、非本机构用户的最大每秒调用次数，单位：次/(秒*用户)，范围：1~5
                        </div>
                        <div className="form-input">
                          <Form.Item
                            name="request_per_second_for_public"
                            rules={[
                              { type: 'integer', min: 1, max: 5, message: '取值范围为 1~5 的整数' },
                              { required: true, message: '请输入外部最大调用频率' },
                            ]}
                          >
                            <InputNumber size="middle" placeholder="请输入外部最大调用频率" />
                          </Form.Item>
                        </div>
                      </>
                    )}
                    <div className="form-title">内部最大调用频率</div>
                    <div className="form-sub-title">
                      面向
                      {this.state.scope === AlgoScope.PRIVATE
                        ? '自己'
                        : this.state.scope === AlgoScope.GROUP
                        ? '本研究组用户'
                        : '本机构用户'}
                      的最大每秒调用次数，单位：次/(秒*用户)，范围：1~50
                    </div>
                    <div className="form-input">
                      <Form.Item
                        name="request_per_second_for_private"
                        rules={[
                          { type: 'integer', min: 1, max: 50, message: '取值范围为 1~50 的整数' },
                          { required: true, message: '请输入内部最大调用频率' },
                        ]}
                      >
                        <InputNumber size="middle" placeholder="请输入内部最大调用频率" />
                      </Form.Item>
                    </div>
                    {this.state.scope === AlgoScope.PUBLIC && (
                      <>
                        <div className="form-title">外部最大并行数</div>
                        <div className="form-sub-title">
                          面向访客、非本机构用户的最大并行调用数量，单位：个/用户，范围：1~10
                        </div>
                        <div className="form-input">
                          <Form.Item
                            name="request_max_parallel_for_public"
                            rules={[
                              { type: 'integer', min: 1, max: 10, message: '取值范围为 1~10 的整数' },
                              { required: true, message: '请输入外部最大并行数' },
                            ]}
                          >
                            <InputNumber size="middle" placeholder="请输入外部最大并行数" />
                          </Form.Item>
                        </div>
                      </>
                    )}
                    <div className="form-title">内部最大并行数</div>
                    <div className="form-sub-title">
                      面向
                      {this.state.scope === AlgoScope.PRIVATE
                        ? '自己'
                        : this.state.scope === AlgoScope.GROUP
                        ? '本研究组用户'
                        : '本机构用户'}
                      的最大并行调用数量，单位：个/用户，范围：1~25
                    </div>
                    <div className="form-input">
                      <Form.Item
                        name="request_max_parallel_for_private"
                        rules={[
                          { type: 'integer', min: 1, max: 25, message: '取值范围为 1~25 的整数' },
                          { required: true, message: '请输入内部最大并行数' },
                        ]}
                      >
                        <InputNumber size="middle" placeholder="请输入内部最大并行数" />
                      </Form.Item>
                    </div>

                    <div className="btn-wrapper">
                      <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                          {this.state.isUpdating ? '更新中...' : '更新'}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* 文档预览抽屉 */}
        <Drawer
          title="文档编写预览"
          placement="bottom"
          height="80vh"
          onClose={this.closeDocumentPreview.bind(this)}
          open={this.state.openDocumentPreview}
        >
          <div className="document-preview-box">
            <Markdown>{this.state.documentPreview}</Markdown>
          </div>
        </Drawer>
      </>
    );
  }
}
