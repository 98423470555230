/**
 * @Description: 入口
 * @Author: Kermit
 * @Date: 2022-11-23 12:43:27
 * @LastEditors: Kermit
 * @LastEditTime: 2022-11-24 16:34:17
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
