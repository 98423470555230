/**
 * @Description: Markdown 解析组件
 * @Author: Kermit
 * @Date: 2022-12-01 17:35:24
 * @LastEditors: Kermit
 * @LastEditTime: 2022-12-01 17:39:17
 */

import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import './Markdown.css';
import 'highlight.js/styles/a11y-light.css'; // 引入代码高亮
// import 'highlight.js/styles/a11y-dark.css'; // 引入代码高亮

export default function Markdown(props: { children: string }) {
  return (
    <div className="react-markdown">
      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeHighlight]}>
        {props.children}
      </ReactMarkdown>
    </div>
  );
}
