/**
 * @Description: 算法卡片组件
 * @Author: Kermit
 * @Date: 2022-11-24 16:14:28
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-02 16:38:56
 */

import React, { Component, ReactNode } from 'react';
import { Avatar, Card, Tag } from 'antd';
import { MinusCircleOutlined, SyncOutlined, TeamOutlined, ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import './AlgoCard.css';
import { AlgoScope } from '@app/constants/algo';
import { AlgorithmDetailLink } from '@app/routes/Links';
import { getAlgoItemInfo } from '@app/api-client';

export interface IAlgoCardProps {
  /** 算法名 */
  name: string;
  /** 版本 */
  version: string;

  /** 是否自动获取详细信息 */
  isAutoFetch?: boolean;

  /** 是否可以调用 */
  canCall?: boolean;
  /** 算法描述 */
  description?: string;
  /** 封面 */
  coverUrl?: string;
  /** 中文名 */
  chineseName?: string;
  /** 用户名 */
  username?: string;
  /** 用户昵称 */
  usernickname?: string;
  /** 用户头像 URL */
  userHeadUrl?: string;
  /** 用户研究组名 */
  groupName?: string;
  /** 用户机构名 */
  institutionName?: string;
  /** 算法可见范围 */
  scope?: AlgoScope;
  /** 算法任务领域 */
  tasks?: Task[];
  /** 创建时间 */
  createTime?: string;
}

type Task = {
  id: string;
  name: string;
  classify: string;
  description: string;
  tag_color: string;
  icon: string;
};

export interface IAlgoCardState {
  background?: string;

  /** 是否可以调用 */
  canCall?: boolean;
  /** 算法描述 */
  description?: string;
  /** 封面 */
  coverUrl?: string;
  /** 中文名 */
  chineseName?: string;
  /** 用户名 */
  username?: string;
  /** 用户昵称 */
  usernickname?: string;
  /** 用户头像 URL */
  userHeadUrl?: string;
  /** 用户研究组名 */
  groupName?: string;
  /** 用户机构名 */
  institutionName?: string;
  /** 算法可见范围 */
  scope?: AlgoScope;
  /** 算法任务领域 */
  tasks?: Task[];
  /** 创建时间 */
  createTime?: string;
}

export default class AlgoCard extends Component<IAlgoCardProps, IAlgoCardState> {
  constructor(props) {
    super(props);
    this.state = {
      background: this.generateBackground(props.name),

      canCall: props.canCall,
      description: props.description,
      coverUrl: props.coverUrl,
      chineseName: props.chineseName,
      username: props.username,
      usernickname: props.usernickname,
      userHeadUrl: props.userHeadUrl,
      groupName: props.groupName,
      institutionName: props.institutionName,
      scope: props.scope,
      tasks: props.tasks,
      createTime: props.createTime,
    };
    if (this.props.isAutoFetch) {
      this.getAlgoInfo();
    }
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  /** 获取算法信息 */
  async getAlgoInfo() {
    const res = await getAlgoItemInfo(this.props.name, this.props.version);
    this.setState({
      canCall: res.can_call,
      description: res.description,
      coverUrl: res.cover_url,
      chineseName: res.chinese_name,
      username: res.user_id,
      usernickname: res.user.nickname,
      userHeadUrl: res.user.head_url,
      groupName: res.user.group?.group_name,
      institutionName: res.user.institution?.institution_name,
      scope: res.scope,
      tasks: res.tasks,
      createTime: res.create_day,
    });
  }

  private generateBackground(name: string) {
    return `linear-gradient(${this.randomIntFromHashCode(
      360,
      name + name + name + name + name,
    )}deg, ${this.randomColorFromHashCode(name + name + name + name)} 0%, ${this.randomColorFromHashCode(
      name + name + name + name + name + name + name,
    )} 100%)`;
  }

  private hashCode(str: string) {
    let h = 0;
    const len = str.length;
    const t = 2147483648;
    for (let i = 0; i < len; i++) {
      h = 31 * h + str.charCodeAt(i);
      if (h > 2147483647) h %= t;
    }
    return h;
  }

  private randomColorFromHashCode(str: string) {
    const r = ((this.hashCode(str) & 0xff0000) >> 16) % 0xff;
    const g = ((this.hashCode(str) & 0x00ff00) >> 8) % 0xff;
    const b = (this.hashCode(str) & 0x0000ff) % 0xff;
    const c = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}000`;
    return c.slice(0, 7);
  }

  private randomIntFromHashCode(max: number, str: string) {
    return this.hashCode(str) % max;
  }

  private getScopeName(scope: AlgoScope) {
    if (scope === AlgoScope.PUBLIC) {
      return '公开';
    } else if (scope === AlgoScope.INSTITUTION) {
      return '机构';
    } else if (scope === AlgoScope.GROUP) {
      return '研究组';
    } else {
      return '个人';
    }
  }

  render(): ReactNode {
    const title = (
      <div className="title">
        <span className="head-pic">
          {this.state.userHeadUrl ? (
            <Avatar size="small" src={this.state.userHeadUrl} />
          ) : (
            <Avatar size="small" icon={<UserOutlined />} />
          )}
        </span>
        <span className="title-text text-cut">
          <span>{this.state.usernickname || this.state.username}</span> /{' '}
          <span>{this.state.chineseName || this.props.name}</span>
        </span>
        <Tag color="default">{this.props.version}</Tag>
      </div>
    );

    const tag = (
      <div>
        {this.state.canCall ? (
          <Tag icon={<SyncOutlined spin />} color="success">
            运行中
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            已关闭
          </Tag>
        )}
        {this.state.scope === AlgoScope.PUBLIC ? (
          <Tag color="blue">{this.getScopeName(this.state.scope)}</Tag>
        ) : this.state.scope === AlgoScope.PRIVATE ? (
          <Tag color="magenta">{this.getScopeName(this.state.scope)}</Tag>
        ) : (
          <Tag color="purple">{this.getScopeName(this.state.scope || AlgoScope.PUBLIC)}</Tag>
        )}
      </div>
    );

    return (
      <AlgorithmDetailLink
        name={this.props.name}
        version={this.props.version}
        {...(this.props.isAutoFetch ? { target: '_blank' } : {})}
      >
        <Card
          className="c-algo-card"
          title={title}
          extra={tag}
          hoverable
          type="inner"
          cover={
            <img
              alt=""
              src={this.state.coverUrl || ''}
              style={{
                background: this.state.background,
              }}
            />
          }
        >
          <div className="algo-desc text-cut-2">
            {this.state.tasks &&
              this.state.tasks.map((item) => (
                <Tag
                  color={item.tag_color}
                  key={item.id}
                  style={{ backgroundColor: `${item.tag_color}10`, color: item.tag_color }}
                >
                  {item.name}
                </Tag>
              ))}
            <span>{this.state.description?.replace(/<[^>]+>/g, '') || '暂无描述'}</span>
          </div>
          <div className="remark-info text-cut">
            <div className="remark-info-item">
              <ClockCircleOutlined /> {this.state.createTime}{' '}
            </div>
            {this.state.institutionName && (
              <div className="remark-info-item">
                <TeamOutlined />{' '}
                <span>
                  {this.state.institutionName}
                  {this.state.groupName && <> / {this.state.groupName}</>}
                </span>
              </div>
            )}
          </div>
        </Card>
      </AlgorithmDetailLink>
    );
  }
}
