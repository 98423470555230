/**
 * @Description
 * @Author Kermit
 * @Date 2022-11-29 11:31:11
 * @LastEditors Kermit
 * @LastEditTime 2022-12-28 12:12:19
 */

/** 算法可见范围 */
export enum AlgoScope {
  /** 公开 */
  PUBLIC = 'PUBLIC',
  /** 机构内 */
  INSTITUTION = 'INSTITUTION',
  /** 研究组内 */
  GROUP = 'GROUP',
  /** 个人 */
  PRIVATE = 'PRIVATE',
}

/** 算法参数类型 */
export enum AlgoParamType {
  /** 字符串 */
  str = 'str',
  /** 定点数 */
  int = 'int',
  /** 浮点数 */
  float = 'float',
  /** 图片本地路径 */
  imagePath = 'image_path',
  /** 视频本地路径 */
  videoPath = 'video_path',
  /** 音频本地路径 */
  voicePath = 'voice_path',
}
