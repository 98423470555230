/**
 * @Description Location routes
 * @Author Kermit
 * @Date 2022-12-01 10:54:41
 * @LastEditors Kermit
 * @LastEditTime 2022-12-02 11:11:23
 */

import qs from 'qs';

export function locationToUserPage(userId: string) {
  const href = `/user/${userId}`;
  window.location.href = href;
}

export function locationToUserPageTab(userId: string, tab: string, params?: object) {
  let href = `/user/${userId}/${tab}`;
  if (params) {
    href += `?${qs.stringify(params)}`;
  }
  window.location.href = href;
}

export function locationToGroupPageTab(groupId: string, tab: string, params?: object) {
  let href = `/group/${groupId}/${tab}`;
  if (params) {
    href += `?${qs.stringify(params)}`;
  }
  window.location.href = href;
}

export function locationToInstitutionPageTab(institutionId: string, tab: string, params?: object) {
  let href = `/institution/${institutionId}/${tab}`;
  if (params) {
    href += `?${qs.stringify(params)}`;
  }
  window.location.href = href;
}

export function locationToAlgorithmDetailTab(name: string, version: string, tab: string, params?: object) {
  let href = `/algorithm/${name}/${version}/${tab}`;
  if (params) {
    href += `?${qs.stringify(params)}`;
  }
  window.location.href = href;
}

export function locationToPublishTab(tab: string) {
  const href = `/publish/${tab}`;
  window.location.href = href;
}
