/**
 * @Description: 发布页
 * @Author: Kermit
 * @Date: 2022-12-02 10:47:30
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-07 10:49:22
 */

import React from 'react';
import './Publish.css';
import GlobalLayout, { Nav } from '@app/layout-components/global-layout/GlobalLayout';
import { Alert, Divider, Radio } from 'antd';
import { useParams } from 'react-router-dom';
import { locationToPublishTab } from '@app/routes/location';
import Markdown from '@app/base-components/markdown/Markdown';
import { getAlgoDockerPublishGuide, getAlgoLocalPublishGuide, getMyInfo } from '@app/api-client';
import CloudDeploy from '@app/components/cloud-deploy/CloudDeploy';
import { UserPermission } from '@app/constants/user';

export default function Publish(props) {
  const params = useParams();
  return <CPublish tab={params.tab} {...props}></CPublish>;
}

export interface IPublishProps {
  tab?: string;
}

export interface IPublishState {
  localPublishMd?: string;
  dockerPublishMd?: string;
  isVisitor?: boolean;
}

class CPublish extends React.Component<IPublishProps, IPublishState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getPublishGuide();
    this.getIsVisitor();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  async getIsVisitor() {
    const { permission } = await getMyInfo();
    const isVisitor = permission === UserPermission.VISITOR;
    this.setState({ isVisitor });
  }

  onRadioGroupChange(e) {
    locationToPublishTab(e.target.value);
  }

  async getPublishGuide() {
    if (!this.state.localPublishMd) {
      const { md } = await getAlgoLocalPublishGuide();
      this.setState({ localPublishMd: md });
    }
    if (!this.state.dockerPublishMd) {
      const { md } = await getAlgoDockerPublishGuide();
      this.setState({ dockerPublishMd: md });
    }
  }

  render(): React.ReactNode {
    return (
      <GlobalLayout activeNav={Nav.EMPTY} isHeaderTopicBackgroud isHeaderWhiteColor title="创建算法应用">
        <div className="p-publish">
          <div className="publish-container">
            <div className="publish-divider">
              <Divider orientation="center" style={{ fontSize: 26 }}>
                创建我的算法应用
              </Divider>
            </div>
            <div className="publish-selector">
              <Radio.Group value={this.props.tab || 'local'} size="large" onChange={this.onRadioGroupChange.bind(this)}>
                <Radio.Button value="local">部署在本地</Radio.Button>
                <Radio.Button value="docker">部署在本地容器</Radio.Button>
                <Radio.Button value="remote">由 AlgoSpace 托管</Radio.Button>
              </Radio.Group>
            </div>

            {/* 部署在本地文档 */}
            {(!this.props.tab || this.props.tab === 'local') && (
              <div className="document-box">
                <Markdown>{this.state.localPublishMd || ''}</Markdown>
              </div>
            )}

            {/* 部署在本地容器文档 */}
            {this.props.tab === 'docker' && (
              <div className="document-box">
                <Markdown>{this.state.dockerPublishMd || ''}</Markdown>
              </div>
            )}

            {/* 部署在 AlgoSpace */}
            {this.props.tab === 'remote' && (
              <>
                {this.state.isVisitor && (
                  <Alert message="请登录" description="注册登录后即可使用 AlgoSpace 托管能力" type="warning" showIcon />
                )}
                <div className={'deploy-box' + (this.state.isVisitor ? ' disable' : '')}>
                  <CloudDeploy></CloudDeploy>
                </div>
              </>
            )}
          </div>
        </div>
      </GlobalLayout>
    );
  }
}
