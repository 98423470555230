/**
 * @Description 封装 Axios
 * @Author Kermit
 * @Date 2022-11-23 17:17:30
 * @LastEditors Kermit
 * @LastEditTime 2023-05-15 18:14:21
 */

import { sessionCookieName } from '@app/config';
import { checkIsNeedCustomCookie, getCustomCookieHeader, setCustomCookieByHeader } from '@app/config/customize';
import { checkIsNeedGradioComponentSession, setGradioComponentSession } from '@app/config/gradioComponent';
import { sleep } from '@app/utils';
import { getCookie } from '@app/utils/cookie';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
export * from 'axios';

/** 通用后端返回类型 */
export interface ResponseData {
  status: number;
  data: any;
  err_msg?: string;
}

/** 封装的 Axios 类 */
export class WrappedAxios<T extends ResponseData> {
  private axiosInstance: AxiosInstance;
  private isCreantialsReady: boolean = false;
  constructor(private baseUrl: string) {
    this.axiosInstance = this.create();
  }

  /** 调用接口 */
  public async request(config: AxiosRequestConfig, isNotNeedCredentialsReady?: boolean): Promise<Record<string, any>> {
    if (!isNotNeedCredentialsReady) {
      // 等待 Cookie 登录态准备好
      while (!this.getIsCredentialsReady()) {
        await sleep(100);
      }
    }
    const axiosInstance = this.axiosInstance;
    config = this.mergeConfig(config);
    return await axiosInstance(config);
  }

  /** 获取 Cookie 登录态是否准备好 */
  public getIsCredentialsReady(): boolean {
    return this.isCreantialsReady;
  }

  /** 设置 Cookie 登录态准备好了 */
  public setIsCredentialsReady() {
    this.isCreantialsReady = true;
  }

  /** 原生调用接口 */
  static oriRequest(config: AxiosRequestConfig): Promise<AxiosResponse> {
    return axios.request(config);
  }

  /** 用于合并基础路径配置和接口单独配置 */
  private mergeConfig(config: AxiosRequestConfig): AxiosRequestConfig {
    if (!config?.url?.startsWith('http')) {
      config.baseURL = this.baseUrl;
    }
    if (checkIsNeedCustomCookie()) {
      config.headers = config.headers || {};
      config.headers = {
        ...getCustomCookieHeader(),
        ...config.headers,
      };
      config.withCredentials = false;
    }

    return config;
  }

  /** 创建实例 */
  private create(): AxiosInstance {
    const axiosInstance: AxiosInstance = axios.create({
      withCredentials: true,
    });
    this.interceptors(axiosInstance);
    return axiosInstance;
  }

  /** 拦截器 */
  private interceptors(axiosInstance: AxiosInstance) {
    // 请求拦截
    axiosInstance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // 响应拦截
    axiosInstance.interceptors.response.use(
      (res: AxiosResponse<T>) => {
        if (res.data.status !== 200) {
          throw new Error(res.data.err_msg || 'Response error.');
        }
        if (checkIsNeedCustomCookie()) {
          setCustomCookieByHeader(res.headers as Record<string, string>);
        }
        if (checkIsNeedGradioComponentSession()) {
          setGradioComponentSession(getCookie(sessionCookieName, res.headers['set-cookie']?.join(';')) || '');
        }
        return {
          ...res.data.data,
          __meta: {
            status: res.status,
            dataStatus: res.data.status,
            headers: res.headers,
          },
        };
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
}
