/**
 * @Description 全局配置
 * @Author Kermit
 * @Date 2022-11-23 17:17:12
 * @LastEditors Kermit
 * @LastEditTime 2023-05-15 18:07:07
 */

export const domain = 'https://algospace.top';
export const rucDomain = 'https://ruc.algospace.top:4443';

export const sessionCookieName = 'session';
export const customCookieHeaderCookie = 'X-Cs-Cookie';
export const customCookieHeaderSetCookie = 'x-cs-set-cookie';
export const customCookieName = 'asc_session';
export const gradioComponentSessionStorageKey = 'asc_comp_session';
export const gradioComponentHost = 'algospace.top';
