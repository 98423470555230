/**
 * @Description:
 * @Author: Kermit
 * @Date: 2022-11-24 18:27:17
 * @LastEditors: Kermit
 * @LastEditTime: 2023-02-18 16:12:35
 */

import { getAlgoTaskList, getInstitutionList } from '@app/api-client';
import { getCurrInstitution } from '@app/config/customize';
import { Radio, RadioChangeEvent, Space, Tag } from 'antd';
import React, { Component } from 'react';
import './AlgoSelector.css';

export interface IAlgoSelectorProps {
  onTaskSelect: (value: string) => void;
  onStatusSelect: (value: '' | 'CAN_CALL') => void;
  onScopeSelect: (value: '' | 'ONLY_INSTITUTION' | 'ONLY_GROUP' | 'ONLY_MINE') => void;
  onInstitutionSelect: (value: string) => void;
}

export interface IAlgoSelectorState {
  taskIdSelect: string;
  statusSelect: string;
  scopeSelect: string;
  institutionSelect: string;

  institutionSelectList: { value: string; label: string }[];
  algoTaskList: TaskListItem[];
}

type TaskListItem = {
  classify: string;
  list: {
    id: string;
    name: string;
    description: string;
    tag_color: string;
    icon: string;
    is_root: boolean;
  }[];
  count: number;
};

export default class AlgoSelector extends Component<IAlgoSelectorProps, IAlgoSelectorState> {
  /** 状态选择列表 */
  statusSelectList = [
    {
      value: 'ALL',
      label: '全部',
    },
    {
      value: 'CAN_CALL',
      label: '运行中',
    },
  ];
  /** 范围选择列表 */
  scopeSelectList = getCurrInstitution()
    ? [
        {
          value: 'ALL',
          label: '全部',
        },
        {
          value: 'ONLY_GROUP',
          label: '只看我研究组',
        },
        {
          value: 'ONLY_MINE',
          label: '只看我',
        },
      ]
    : [
        {
          value: 'ALL',
          label: '全部',
        },
        {
          value: 'ONLY_INSTITUTION',
          label: '只看我机构',
        },
        {
          value: 'ONLY_GROUP',
          label: '只看我研究组',
        },
        {
          value: 'ONLY_MINE',
          label: '只看我',
        },
      ];
  /** 机构选择列表 */
  institutionSelectList = [
    {
      value: '',
      label: '全部',
    },
    {
      value: 'asdasdasdsad',
      label: '中国人民大学',
    },
    {
      value: 'asxaxasidj',
      label: '北京大学',
    },
    {
      value: 'ascxa786scas',
      label: '清华大学',
    },
    {
      value: 'ascxascas',
      label: '北京航天航空大学',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      taskIdSelect: '',
      statusSelect: 'ALL',
      scopeSelect: 'ALL',
      institutionSelect: '',
      institutionSelectList: [],
      algoTaskList: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.initAlgoTaskList();
    this.initInstitutionSelectList();
  }

  async initAlgoTaskList() {
    const res = await getAlgoTaskList();
    this.setState({ algoTaskList: res.list });
  }

  async initInstitutionSelectList() {
    const res = await getInstitutionList();
    this.setState({
      institutionSelectList: [{ value: '', label: '全部' }].concat(
        res.list.map((institution) => ({
          value: institution.institution_id,
          label: institution.institution_name,
        })),
      ),
    });
  }

  handleTaskSelect(taskId: string) {
    if (this.state.taskIdSelect === taskId) {
      // 重复点击，取消选择
      taskId = '';
    }
    this.setState({ taskIdSelect: taskId });
    this.props.onTaskSelect(taskId);
  }

  handleStatusChange(e: RadioChangeEvent) {
    this.setState({ statusSelect: e.target.value });
    this.props.onStatusSelect(e.target.value);
  }

  handleScopeChange(e: RadioChangeEvent) {
    this.setState({ scopeSelect: e.target.value });
    this.props.onScopeSelect(e.target.value);
  }

  handleIntitutionChange(e: RadioChangeEvent) {
    this.setState({ institutionSelect: e.target.value });
    this.props.onInstitutionSelect(e.target.value);
  }

  render(): React.ReactNode {
    return (
      <div className="c-algo-selector">
        <div className="selector-group">
          {this.state.algoTaskList.map((taskClass) => (
            <>
              <div className="selector-group-name">{taskClass.classify}</div>
              <div className="selector-tags-box">
                {taskClass.list.map((task) => (
                  <Tag
                    color={task.tag_color}
                    key={task.id}
                    className={`${this.state.taskIdSelect === task.id ? 'selected' : ''}`}
                    style={{
                      ...(this.state.taskIdSelect && this.state.taskIdSelect !== task.id
                        ? { backgroundColor: `${task.tag_color}10`, color: task.tag_color, opacity: 0.5 }
                        : {}),
                      ...(!this.state.taskIdSelect
                        ? { backgroundColor: `${task.tag_color}10`, color: task.tag_color }
                        : {}),
                    }}
                    onClick={() => this.handleTaskSelect(task.id)}
                  >
                    {task.name}
                  </Tag>
                ))}
              </div>
            </>
          ))}
        </div>

        <div className="selector-group">
          <div className="selector-group-name">运行状态</div>
          <Radio.Group value={this.state.statusSelect} onChange={this.handleStatusChange.bind(this)}>
            <Space direction="vertical">
              {this.statusSelectList.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>

        <div className="selector-group">
          <div className="selector-group-name">权限范围</div>
          <Radio.Group onChange={this.handleScopeChange.bind(this)} value={this.state.scopeSelect}>
            <Space direction="vertical">
              {this.scopeSelectList.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>

        {!getCurrInstitution() && (
          <div className="selector-group">
            <div className="selector-group-name">机构</div>
            <Radio.Group value={this.state.institutionSelect} onChange={this.handleIntitutionChange.bind(this)}>
              <Space direction="vertical">
                {this.state.institutionSelectList.map(({ value, label }) => (
                  <Radio key={value} value={value}>
                    {label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        )}
      </div>
    );
  }
}
