/**
 * @Description:
 * @Author: Kermit
 * @Date: 2022-12-12 16:16:26
 * @LastEditors: Kermit
 * @LastEditTime: 2023-01-15 23:31:23
 */

import React from 'react';
import './NotFound.css';
import GlobalLayout, { Nav } from '@app/layout-components/global-layout/GlobalLayout';
import { Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';

export interface INotFoundProps {
  status?: ResultStatusType;
  subTitle?: string;
}

export default class NotFound extends React.Component<INotFoundProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  render(): React.ReactNode {
    return (
      <GlobalLayout activeNav={Nav.EMPTY} isHeaderTopicBackgroud isHeaderWhiteColor>
        <Result
          status={this.props.status || '404'}
          title={this.props.status || '404'}
          subTitle={
            this.props.subTitle ||
            (!this.props.status || this.props.status === '404' ? '抱歉，你访问的页面不存在。' : '')
          }
        />
      </GlobalLayout>
    );
  }
}
