/**
 * @Description: 算法列表组件
 * @Author: Kermit
 * @Date: 2022-11-30 10:37:19
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-30 18:36:21
 */

import { getAlgoList } from '@app/api-client';
import { AlgoScope } from '@app/constants/algo';
import { Button, Divider, Empty, Pagination, Select } from 'antd';
import React from 'react';
import AlgoCard from '../algo-card/AlgoCard';
import AlgoPinModal from '../algo-pin-modal/AlgoPinModal';
import './AlgoList.css';

export interface IAlgoListProps {
  title?: string;
  isHidePagination?: boolean;
  isHideOrderBy?: boolean;
  isDisplayNoneWhenEmpty?: boolean;
  isShowEditPin?: boolean;

  nameFilter?: string;
  taskFilter?: string;
  statusFilter?: '' | 'CAN_CALL';
  scopeFilter?: '' | 'ONLY_INSTITUTION' | 'ONLY_GROUP' | 'ONLY_MINE';
  institutionFilter?: string;
  groupFilter?: string;
  userFilter?: string;

  isOnlyLatest?: boolean;
  isHot?: boolean;
  isPin?: boolean;
}

export interface IAlgoListState {
  orderBy: string;

  page: number;
  perpage: number;
  pages: number;
  total: number;

  /** 算法列表 */
  list: {
    /** 算法名 */
    name: string;
    /** 算法版本 */
    version: string;
    /** 是否可以调用 */
    canCall: boolean;
    /** 算法描述 */
    description: string;
    /** 封面 */
    coverUrl?: string;
    /** 用户名 */
    username: string;
    /** 用户昵称 */
    usernickname: string;
    /** 用户头像 */
    userHeadUrl: string;
    /** 机构 ID */
    institutionId: string;
    /** 用户机构名 */
    institutionName: string;
    /** 机构头像 */
    institutionHeadUrl: string;
    /** 机构简介 */
    institutionDescription: string;
    /** 研究组 ID */
    groupId: string;
    /** 用户研究组名 */
    groupName: string;
    /** 研究组头像 */
    groupHeadUrl: string;
    /** 研究组简介 */
    groupDescription: string;
    /** 算法可见范围 */
    scope: AlgoScope;
    /** 算法任务领域 */
    tasks?: Task[];
    /** 创建时间 */
    createTime: string;
    /** 中文名 */
    chineseName: string;
    /** 是否是我的 */
    isMine: boolean;
  }[];

  isShowPinEditModal?: boolean;
}

type Task = {
  id: string;
  name: string;
  classify: string;
  description: string;
  tag_color: string;
  icon: string;
};

export default class AlgoList extends React.Component<IAlgoListProps, IAlgoListState> {
  orderBySelectList = [
    {
      value: '-create_date',
      label: '按发布时间倒序',
    },
    {
      value: '+create_date',
      label: '按发布时间顺序',
    },
    {
      value: '-name',
      label: '按算法名倒序',
    },
    {
      value: '+name',
      label: '按算法名顺序',
    },
    {
      value: '-username',
      label: '按用户名倒序',
    },
    {
      value: '+username',
      label: '按用户名顺序',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',

      page: 1,
      perpage: 20,
      pages: 0,
      total: 0,
      list: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.handleSearch();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 生命周期：更新后 */
  componentDidUpdate(prevProps: IAlgoListProps) {
    if (
      prevProps.nameFilter !== this.props.nameFilter ||
      prevProps.taskFilter !== this.props.taskFilter ||
      prevProps.statusFilter !== this.props.statusFilter ||
      prevProps.scopeFilter !== this.props.scopeFilter ||
      prevProps.institutionFilter !== this.props.institutionFilter ||
      prevProps.groupFilter !== this.props.groupFilter ||
      prevProps.userFilter !== this.props.userFilter
    ) {
      this.handleSearch();
    }
  }

  /** 搜索 */
  async handleSearch() {
    const { list, page, perpage, pages, total } = await getAlgoList(this.state.page, this.state.perpage, {
      ...(this.props.isOnlyLatest ? { only_latest: true } : {}),
      ...(this.props.isHot ? { is_hot: true } : {}),
      ...(this.props.nameFilter ? { name: this.props.nameFilter } : {}),
      ...(this.props.taskFilter ? { task_id: this.props.taskFilter } : {}),
      ...(this.props.statusFilter === 'CAN_CALL' ? { can_call: true } : {}),
      ...(this.props.scopeFilter === 'ONLY_INSTITUTION' ? { only_in_my_institution: true } : {}),
      ...(this.props.scopeFilter === 'ONLY_GROUP' ? { only_in_my_group: true } : {}),
      ...(this.props.scopeFilter === 'ONLY_MINE' ? { only_mine: true } : {}),
      ...(this.props.institutionFilter ? { institution_id: this.props.institutionFilter } : {}),
      ...(this.props.institutionFilter && this.props.isPin ? { is_institution_pin: true } : {}),
      ...(this.props.groupFilter ? { group_id: this.props.groupFilter } : {}),
      ...(this.props.groupFilter && this.props.isPin ? { is_group_pin: true } : {}),
      ...(this.props.userFilter ? { user_id: this.props.userFilter } : {}),
      ...(this.props.userFilter && this.props.isPin ? { is_user_pin: true } : {}),
      ...(this.state.orderBy ? { order_by: this.state.orderBy } : {}),
    });
    this.setState({
      page,
      perpage,
      pages,
      total,
      list: list.map((item) => ({
        name: item.name,
        version: item.version,
        canCall: item.can_call,
        description: item.description,
        coverUrl: item.cover_url,
        username: item.user.user_id,
        usernickname: item.user.nickname,
        userHeadUrl: item.user.head_url,
        institutionId: item.user.institution?.id,
        institutionName: item.user.institution?.institution_name,
        institutionHeadUrl: item.user.institution?.head_url,
        institutionDescription: item.user.institution?.description,
        groupId: item.user.group?.id,
        groupName: item.user.group?.group_name,
        groupHeadUrl: item.user.group?.head_url,
        groupDescription: item.user.group?.description,
        scope: item.scope,
        tasks: item.tasks,
        createTime: item.create_day,
        chineseName: item.chinese_name,
        isMine: item.is_mine,
      })),
    });
  }

  showPinEditModal() {
    this.setState({ isShowPinEditModal: true });
  }

  hidePinEditModal() {
    this.setState({ isShowPinEditModal: false });
  }

  onPageChange(page: number, perpage: number) {
    this.setState({ page, perpage }, () => this.handleSearch());
  }

  onChangeOrderBy(value: string) {
    this.setState({ orderBy: value }, () => this.handleSearch());
  }

  render(): React.ReactNode {
    return (
      (!this.props.isDisplayNoneWhenEmpty || this.state.list.length > 0) && (
        <div className="c-algo-list">
          <div className="title-divider">
            <div className="divider">
              <Divider orientation="left" style={{ fontSize: 20 }}>
                {this.props.title || '全部算法应用'}
              </Divider>
            </div>
            {this.props.isPin && this.props.isShowEditPin && (
              <div className="btn">
                <Button onClick={this.showPinEditModal.bind(this)}>编辑置顶</Button>
              </div>
            )}
            {!this.props.isHideOrderBy && (
              <div className="selector">
                <Select
                  defaultValue="-create_date"
                  style={{ width: 150 }}
                  onChange={this.onChangeOrderBy.bind(this)}
                  options={this.orderBySelectList}
                />
              </div>
            )}
          </div>
          {this.state.list.length > 0 ? (
            <>
              <div className="algo-container">
                {this.state.list.map((item) => (
                  <div key={`${item.name}_${item.version}`} className="algo-item">
                    <AlgoCard
                      name={item.name}
                      version={item.version}
                      description={item.description}
                      coverUrl={item.coverUrl}
                      chineseName={item.chineseName}
                      canCall={item.canCall}
                      username={item.username}
                      usernickname={item.usernickname}
                      userHeadUrl={item.userHeadUrl}
                      groupName={item.groupName}
                      institutionName={item.institutionName}
                      scope={item.scope}
                      tasks={item.tasks}
                      createTime={item.createTime}
                    ></AlgoCard>
                  </div>
                ))}
              </div>
              {!this.props.isHidePagination && (
                <div className="pagi-container">
                  <Pagination
                    showSizeChanger
                    responsive
                    onChange={this.onPageChange.bind(this)}
                    pageSize={this.state.perpage}
                    current={this.state.page}
                    total={this.state.total}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="algo-empty">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>{this.props.nameFilter ? '没有搜索到算法应用' : '暂无算法应用'}</span>}
              />
            </div>
          )}

          <AlgoPinModal
            isModalOpen={this.state.isShowPinEditModal || false}
            institutionFilter={this.props.institutionFilter}
            groupFilter={this.props.groupFilter}
            userFilter={this.props.userFilter}
            onOk={this.handleSearch.bind(this)}
            onCompleted={this.hidePinEditModal.bind(this)}
          ></AlgoPinModal>
        </div>
      )
    );
  }
}
