/**
 * @Description: 用户设置页面
 * @Author: Kermit
 * @Date: 2022-12-02 17:36:15
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-30 18:27:11
 */

import React from 'react';
import './UserSetting.css';
import GlobalLayout from '@app/layout-components/global-layout/GlobalLayout';
import {
  createSecret,
  deleteSecret,
  getMyInfo,
  getSecrets,
  resetPassword,
  updateMyUserInfo,
  uploadHeadPicUrl,
} from '@app/api-client';
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  Menu,
  MenuProps,
  message,
  Popconfirm,
  Select,
  Tag,
  Upload,
  UploadFile,
} from 'antd';
import {
  CopyOutlined,
  KeyOutlined,
  LockOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import UserCard from '@app/components/user-card/UserCard';
import { UserPermission } from '@app/constants/user';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { UploadChangeParam } from 'antd/es/upload';
import { checkIsNeedCustomCookie, getCustomCookieHeader } from '@app/config/customize';

export interface IUserSettingProps {
  a?: string;
}

export interface IUserSettingState {
  tab: string;
  isUpdating: boolean;
  isHeadpicFileListInit: boolean;
  headpicFileList: UploadFile[];
  secretList: {
    name: string;
    secret: string;
    privilege: string;
    status: string;
    create_date: string;
    update_date: string;
  }[];

  userId?: string;
  nickname?: string;
  headUrl?: string;
  description?: string;
  email?: string;
  github?: string;
  website?: string;
  tags?: string[];
  permission?: UserPermission;
  institution?: {
    institutionId: string;
    institutionName: string;
    headUrl: string;
    description: string;
  };
  group?: {
    groupId: string;
    groupName: string;
    headUrl: string;
    description: string;
  };

  tagsIndexList: number[];
}

type MenuItem = Required<MenuProps>['items'][number];

export default class UserSetting extends React.Component<IUserSettingProps, IUserSettingState> {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'general',
      isUpdating: false,
      isHeadpicFileListInit: false,
      headpicFileList: [],
      tagsIndexList: [],
      secretList: [],
    };
  }

  /** 生命周期：挂载后 */
  async componentDidMount() {
    await this.getMyInfo();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 获取我的信息 */
  async getMyInfo() {
    const {
      user_id: userId,
      nickname,
      head_url: headUrl,
      description,
      email,
      github,
      website,
      tags,
      permission,
      institution,
      group,
    } = await getMyInfo();
    this.setState({
      userId,
      nickname,
      headUrl,
      description,
      email,
      github,
      website,
      tags,
      permission,
    });
    if (institution) {
      this.setState({
        institution: {
          institutionId: institution.institution_id,
          institutionName: institution.institution_name,
          headUrl: institution.head_url,
          description: institution.description,
        },
      });
    }
    if (group) {
      this.setState({
        group: {
          groupId: group.group_id,
          groupName: group.group_name,
          headUrl: group.head_url,
          description: group.description,
        },
      });
    }

    this.state.tab === 'general' && this.initGeneralForm(userId, nickname, description, email, github, website, tags);
    this.state.tab === 'general' && this.initUploadHeadPicFileList(headUrl);
    this.state.tab === 'secret' && this.initSecretList();
  }

  getMenuItem(key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem {
    return {
      key,
      label: this.getMenuLabel(key),
      icon,
      children,
      type,
    } as MenuItem;
  }

  getMenuLabel(key: React.Key) {
    switch (key) {
      case 'general':
        return '基本信息';
      case 'secret':
        return '访问密钥';
      case 'password':
        return '重置密码';
      default:
        return '';
    }
  }

  /** 菜单栏项 */
  items: MenuItem[] = [
    this.getMenuItem('general', <SettingOutlined />),
    this.getMenuItem('secret', <KeyOutlined />),
    this.getMenuItem('password', <LockOutlined />),
  ];

  /** 选择菜单项事件 */
  onSelectTab({ key }) {
    const tab = key;
    this.setState({ tab }, () => {
      tab === 'general' &&
        this.initGeneralForm(
          this.state.userId || '',
          this.state.nickname || '',
          this.state.description || '',
          this.state.email || '',
          this.state.github || '',
          this.state.website || '',
          this.state.tags || [],
        );
      tab === 'general' && this.initUploadHeadPicFileList(this.state.headUrl || '');
      tab === 'secret' && this.initSecretList();
    });
  }

  /** 基本信息设置表单引用 */
  generalFormRef = React.createRef<FormInstance>();
  /** 初始化基本信息设置表单 */
  initGeneralForm(
    userId: string,
    nickname: string,
    description: string,
    email?: string,
    github?: string,
    website?: string,
    tags?: string[],
  ) {
    this.generalFormRef.current!.setFieldsValue({
      user_id: userId,
      nickname,
      description,
      email,
      github,
      website,
    });
    if (tags) {
      const tagsIndexList = [...Array(Object.keys(tags).length)].map((_, index) => index);
      this.setState({ tagsIndexList }, () => {
        for (const i in tags) {
          const tag = tags[i];
          const [tagContent, tagColor] = tag.split('###');
          this.generalFormRef.current!.setFieldsValue({
            [`tags_content_${i}`]: tagContent,
            [`tags_color_${i}`]: tagColor || '',
          });
        }
      });
    }
  }
  /** 完成基本信息表单 */
  async onGeneralFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      let headPic: string | undefined = undefined;
      if (this.state.headpicFileList.length > 0) {
        headPic = this.state.headpicFileList[0].url;
      }
      const tags: string[] = [];
      for (const tagIndex of this.state.tagsIndexList) {
        if (values[`tags_color_${tagIndex}`]) {
          tags.push(`${values[`tags_content_${tagIndex}`]}###${values[`tags_color_${tagIndex}`]}`);
        } else {
          tags.push(values[`tags_content_${tagIndex}`]);
        }
      }
      await updateMyUserInfo(
        headPic,
        values.nickname,
        values.description,
        values.email,
        values.github,
        values.website,
        tags,
      );
      message.success('更新成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '更新失败');
    }
  }
  initUploadHeadPicFileList(headUrl: string) {
    const headpicFileList: UploadFile[] = headUrl
      ? [
          {
            uid: '-1',
            name: 'file',
            status: 'done',
            url: headUrl,
          },
        ]
      : [];
    this.setState({ headpicFileList, isHeadpicFileListInit: true });
  }
  onHeadpicFileListChange = ({ fileList }: UploadChangeParam<UploadFile>) => {
    this.setState({
      headpicFileList: fileList.map((item) => ({
        url: item.response?.data?.filepath ? uploadHeadPicUrl + '/' + item.response?.data?.filepath : undefined,
        ...item,
      })),
    });
  };

  /** 密码重置设置表单引用 */
  passwordFormRef = React.createRef<FormInstance>();
  /** 完成密码重置表单 */
  async onPasswordFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      await resetPassword(values.password, values.newpassword);
      message.success('更新成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '更新失败');
    }
  }

  addTagsIndexListNum() {
    this.setState((state) => ({
      tagsIndexList: state.tagsIndexList.concat(
        state.tagsIndexList.length > 0 ? state.tagsIndexList[state.tagsIndexList.length - 1] + 1 : 0,
      ),
    }));
  }

  minusTagsIndexListNum(index: number) {
    const tagsIndexList = this.state.tagsIndexList;
    tagsIndexList.splice(index, 1);
    this.setState({ tagsIndexList });
  }

  /** 访问密钥设置表单引用 */
  secretFormRef = React.createRef<FormInstance>();
  /** 完成访问密钥表单 */
  async onSecretFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      await createSecret(values.name, values.privilege);
      this.initSecretList();
      message.success('创建成功', 1, () => {
        this.setState({ isUpdating: false });
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '创建失败');
    }
  }

  async initSecretList() {
    const { list: secretList } = await getSecrets();
    this.setState({ secretList });
  }

  async deleteSecret(name: string) {
    await deleteSecret(name);
    await this.initSecretList();
  }

  render(): React.ReactNode {
    return (
      <GlobalLayout
        isHeaderTopicBackgroud
        isHeaderWhiteColor
        title={(this.state.nickname || this.state.userId || '') + '_设置'}
      >
        <div className="p-user-setting">
          <div className="user-setting-container">
            <div className="user-setting-menu">
              <div className="user-card-wrapper">
                <UserCard
                  username={this.state.userId}
                  usernickname={this.state.nickname}
                  userHeadUrl={this.state.headUrl}
                  userDescription={this.state.description}
                  userPermission={this.state.permission}
                  institutionId={this.state.institution?.institutionId}
                  institutionHeadUrl={this.state.institution?.headUrl}
                  institutionName={this.state.institution?.institutionName}
                  institutionDescription={this.state.institution?.description}
                  groupId={this.state.group?.groupId}
                  groupHeadUrl={this.state.group?.headUrl}
                  groupName={this.state.group?.groupName}
                  groupDescription={this.state.group?.description}
                ></UserCard>
              </div>
              <Menu
                mode="inline"
                selectedKeys={[this.state.tab]}
                onSelect={this.onSelectTab.bind(this)}
                style={{ width: '100%' }}
                items={this.items}
              />
            </div>
            <div className="user-setting-content">
              <div className="user-setting-content-header">
                <Divider orientation="left" style={{ fontSize: 18 }}>
                  {this.getMenuLabel(this.state.tab)}
                </Divider>
              </div>
              <div className="user-setting-content-body">
                {/* 基本信息设置 */}
                {this.state.tab === 'general' && (
                  <div className="form-wrapper">
                    <Form
                      name="basic"
                      ref={this.generalFormRef}
                      onFinish={this.onGeneralFormFinish.bind(this)}
                      autoComplete="off"
                    >
                      <div className="form-title">用户名</div>
                      <div className="form-input">
                        <Form.Item name="user_id">
                          <Input disabled={true} size="middle" />
                        </Form.Item>
                      </div>
                      <div className="form-title">头像</div>
                      <div className="form-upload">
                        {this.state.isHeadpicFileListInit && (
                          <Upload
                            name="file"
                            listType="picture-card"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            withCredentials
                            defaultFileList={this.state.headpicFileList}
                            action={uploadHeadPicUrl}
                            onChange={this.onHeadpicFileListChange.bind(this)}
                            headers={checkIsNeedCustomCookie() ? getCustomCookieHeader() : {}}
                          >
                            {this.state.headpicFileList.length >= 1 ? null : (
                              <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>上传</div>
                              </div>
                            )}
                          </Upload>
                        )}
                      </div>
                      <div className="form-title">昵称</div>
                      <div className="form-input">
                        <Form.Item name="nickname">
                          <Input size="middle" placeholder="输入昵称（可选）" />
                        </Form.Item>
                      </div>
                      <div className="form-title">个人简介</div>
                      <div className="form-input">
                        <Form.Item name="description">
                          <Input.TextArea
                            autoSize={{ minRows: 5, maxRows: 10 }}
                            size="middle"
                            placeholder="输入个人简介（可选）"
                          />
                        </Form.Item>
                      </div>
                      <div className="form-title">Github 用户名</div>
                      <div className="form-input">
                        <Form.Item name="github">
                          <Input size="middle" placeholder="输入Github 用户名（可选）" />
                        </Form.Item>
                      </div>
                      <div className="form-title">电子邮箱</div>
                      <div className="form-input">
                        <Form.Item name="email" rules={[{ type: 'email', message: '不是一个有效的电子邮箱' }]}>
                          <Input size="middle" placeholder="输入电子邮箱（可选）" />
                        </Form.Item>
                      </div>
                      <div className="form-title">个人网站</div>
                      <div className="form-input">
                        <Form.Item name="website" rules={[{ type: 'url', message: '不是一个有效的 URL 链接' }]}>
                          <Input size="middle" placeholder="输入个人网站（可选）" />
                        </Form.Item>
                      </div>

                      <div className="form-title">我的标签</div>
                      <div className="form-input">
                        {this.state.tagsIndexList.map((paramIndex, index) => (
                          <div className="tags-wrapper" key={paramIndex}>
                            <div className="tags-icon" onClick={this.minusTagsIndexListNum.bind(this, index)}>
                              <MinusCircleOutlined />
                            </div>
                            <div className="tags-title">标签 {index + 1}</div>
                            <Form.Item
                              name={`tags_content_${paramIndex}`}
                              rules={[
                                { required: true, message: '请输入标签' },
                                { max: 20, message: '标签长度不能超过 20 字符' },
                              ]}
                            >
                              <Input size="middle" placeholder="输入标签" />
                            </Form.Item>
                            <Form.Item name={`tags_color_${paramIndex}`}>
                              <Select
                                placeholder="选择标签样式"
                                defaultValue=""
                                options={[
                                  {
                                    value: '',
                                    label: <Tag>默认样式</Tag>,
                                  },
                                  {
                                    value: 'magenta',
                                    label: <Tag color="magenta">品红色</Tag>,
                                  },
                                  {
                                    value: 'red',
                                    label: <Tag color="red">红色</Tag>,
                                  },
                                  {
                                    value: 'orange',
                                    label: <Tag color="orange">橙色</Tag>,
                                  },
                                  {
                                    value: 'gold',
                                    label: <Tag color="gold">金色</Tag>,
                                  },
                                  {
                                    value: 'green',
                                    label: <Tag color="green">绿色</Tag>,
                                  },
                                  {
                                    value: 'cyan',
                                    label: <Tag color="cyan">青色</Tag>,
                                  },
                                  {
                                    value: 'blue',
                                    label: <Tag color="blue">蓝色</Tag>,
                                  },
                                  {
                                    value: 'purple',
                                    label: <Tag color="purple">紫色</Tag>,
                                  },
                                ]}
                              ></Select>
                            </Form.Item>
                          </div>
                        ))}
                        <Button
                          type="dashed"
                          style={{ width: '100%', marginBottom: '25px' }}
                          onClick={this.addTagsIndexListNum.bind(this)}
                        >
                          <PlusCircleOutlined />
                          添加一个标签
                        </Button>
                      </div>

                      <div className="btn-wrapper">
                        <Form.Item>
                          <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                            {this.state.isUpdating ? '更新中...' : '更新'}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                )}

                {/* 访问密钥 */}
                {this.state.tab === 'secret' && (
                  <div className="form-wrapper">
                    {this.state.secretList.map((secret) => (
                      <div className="block-wrapper" key={secret.create_date}>
                        <div className="block-title">
                          <span>{secret.name}</span>
                          <Popconfirm
                            title={`确认删除该密钥？删除后将无法恢复`}
                            onConfirm={() => this.deleteSecret(secret.name)}
                            okText="确认"
                            cancelText="取消"
                          >
                            <span className="text-btn op-hover">删除密钥</span>
                          </Popconfirm>
                        </div>
                        <div className="form-input">
                          <div style={{ display: 'flex' }}>
                            <Input.Password
                              contentEditable="false"
                              value={secret.secret}
                              style={{ flex: 1, marginRight: 7 }}
                            />
                            <CopyToClipboard text={secret.secret} onCopy={() => message.success('密钥已复制')}>
                              <Button>
                                <CopyOutlined />
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </div>
                        <div className="block-tip">
                          {secret.privilege === 'ADMINISTER' && <Tag>所有权限</Tag>}
                          {secret.privilege === 'PROVIDER' && <Tag>发布者权限</Tag>}
                          {secret.privilege === 'CUSTOMER' && <Tag>调用者权限</Tag>}
                          <span>{secret.create_date}</span>
                        </div>
                      </div>
                    ))}

                    <Divider style={{ marginTop: 30 }}>创建访问密钥</Divider>

                    <Form
                      name="basic"
                      ref={this.secretFormRef}
                      onFinish={this.onSecretFormFinish.bind(this)}
                      autoComplete="off"
                    >
                      <div className="form-title">密钥名</div>
                      <div className="form-input">
                        <Form.Item name="name" rules={[{ required: true, message: '请输入密钥名' }]}>
                          <Input size="middle" placeholder="输入密钥名" />
                        </Form.Item>
                      </div>

                      <div className="form-title">权限</div>
                      <div className="form-input">
                        <Form.Item name="privilege">
                          <Select
                            placeholder="选择权限"
                            defaultValue="ADMINISTER"
                            options={[
                              {
                                value: 'ADMINISTER',
                                label: '所有权限',
                              },
                              {
                                value: 'PROVIDER',
                                label: '发布者权限',
                              },
                              {
                                value: 'CUSTOMER',
                                label: '调用者权限',
                              },
                            ]}
                          ></Select>
                        </Form.Item>
                      </div>

                      <div className="btn-wrapper">
                        <Form.Item>
                          <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                            {this.state.isUpdating ? '创建中...' : '创建'}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                )}

                {/* 密码重置 */}
                {this.state.tab === 'password' && (
                  <div className="form-wrapper">
                    <Form
                      name="basic"
                      ref={this.passwordFormRef}
                      onFinish={this.onPasswordFormFinish.bind(this)}
                      autoComplete="off"
                    >
                      <div className="form-title">旧密码</div>
                      <div className="form-input">
                        <Form.Item name="password" rules={[{ required: true, message: '请输入旧密码' }]}>
                          <Input.Password size="middle" placeholder="输入旧密码" />
                        </Form.Item>
                      </div>
                      <div className="form-title">新密码</div>
                      <div className="form-input">
                        <Form.Item
                          name="newpassword"
                          rules={[
                            { required: true, message: '请输入新密码' },
                            { min: 6, message: '密码长度不得小于 6 位' },
                            { max: 20, message: '密码长度不得大于 20 位' },
                            {
                              pattern: new RegExp('^[a-zA-Z0-9~@#\\$%\\*_\\-\\+\\=\\:,\\.\\?\\{\\}]+$'),
                              message: '密码仅支持大小写字母与数字、特殊字符 ~@#$%*_-+=:,.?{}',
                            },
                          ]}
                        >
                          <Input.Password size="middle" placeholder="输入新密码" />
                        </Form.Item>
                        <Form.Item
                          name="renewpassword"
                          rules={[
                            { required: true, message: '请重复新密码' },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('newpassword') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不一致'));
                              },
                            }),
                          ]}
                        >
                          <Input.Password size="middle" placeholder="重复新密码" />
                        </Form.Item>
                      </div>

                      <div className="btn-wrapper">
                        <Form.Item>
                          <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                            {this.state.isUpdating ? '更新中...' : '更新'}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </GlobalLayout>
    );
  }
}
