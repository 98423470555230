/**
 * @Description: 研究组列表组件
 * @Author: Kermit
 * @Date: 2022-12-01 20:46:49
 * @LastEditors: Kermit
 * @LastEditTime: 2023-01-27 18:41:59
 */

import { getGroupList, updateGroupSequence } from '@app/api-client';
import DragList from '@app/base-components/drag-list/DragList';
import { GroupPageLink } from '@app/routes/Links';
import { Card, Divider, Empty } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';
import './GroupList.css';

export interface IGroupListProps {
  institution_id: string;
  canUpdateSequence?: boolean;
}

export interface IGroupListState {
  groupList: {
    groupId: string;
    groupName: string;
    headUrl: string;
    description: string;
    isMine: boolean;
  }[];
}

export default class GroupList extends React.Component<IGroupListProps, IGroupListState> {
  constructor(props) {
    super(props);
    this.state = {
      groupList: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getGroupList();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  async getGroupList() {
    const res = await getGroupList(this.props.institution_id);
    this.setState({
      groupList: res.list.map((group) => ({
        groupId: group.group_id,
        groupName: group.group_name,
        headUrl: group.head_url,
        description: group.description,
        isMine: group.is_mine,
      })),
    });
  }

  updateGroupSequence(idList: string[]) {
    this.setState({
      groupList: this.state.groupList.sort((a, b) => idList.indexOf(a.groupId) - idList.indexOf(b.groupId)),
    });
    updateGroupSequence(idList);
  }

  render(): React.ReactNode {
    return (
      <div className="c-group-list">
        {this.state.groupList.length > 0 ? (
          <>
            <div className="title-divider">
              <div className="divider">
                <Divider orientation="left" style={{ fontSize: 20 }}>
                  研究组
                </Divider>
              </div>
              <div className="selector"></div>
            </div>
            <div className="group-container">
              <DragList
                isShowDragger={this.props.canUpdateSequence}
                onDragEnd={this.updateGroupSequence.bind(this)}
                containers={this.state.groupList.map((group) => ({
                  id: group.groupId,
                  children: (
                    <div key={group.groupId} className="group-item">
                      <GroupPageLink groupId={group.groupId}>
                        <Card
                          hoverable
                          style={{ width: '100%' }}
                          cover={<img className="group-head" src={group.headUrl}></img>}
                        >
                          <Meta
                            title={group.groupName}
                            description={<div className="text-cut-1">{group.description}</div>}
                          />
                        </Card>
                      </GroupPageLink>
                    </div>
                  ),
                }))}
              />
            </div>
          </>
        ) : (
          <div className="group-empty">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>暂无研究组</span>} />
          </div>
        )}
      </div>
    );
  }
}
