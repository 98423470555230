/**
 * @Description: 成员集合组件
 * @Author: Kermit
 * @Date: 2022-11-30 19:28:03
 * @LastEditors: Kermit
 * @LastEditTime: 2023-01-27 18:49:56
 */

import { getUserList } from '@app/api-client';
import { UserPermission } from '@app/constants/user';
import { locationToUserPage } from '@app/routes/location';
import { Avatar, Tooltip } from 'antd';
import React from 'react';
import './MemberSet.css';

export interface IMemberSetProps {
  id: string;
  type: 'GROUP' | 'INSTITUTION';
}

export interface IMemberSetState {
  memberList: {
    userId: string;
    nickname: string;
    headUrl: string;
    description: string;
    isMine: boolean;
  }[];
  leaderList: {
    userId: string;
    nickname: string;
    headUrl: string;
    description: string;
    isMine: boolean;
  }[];
}

export default class MemberSet extends React.Component<IMemberSetProps, IMemberSetState> {
  constructor(props) {
    super(props);
    this.state = {
      memberList: [],
      leaderList: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getLeaderList();
    this.getMemberList();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 获取普通成员列表 */
  async getMemberList() {
    const res = await getUserList(1, 1000, {
      ...(this.props.type === 'GROUP' ? { group_id: this.props.id } : {}),
      ...(this.props.type === 'INSTITUTION' ? { institution_id: this.props.id } : {}),
      permission: UserPermission.MEMBER,
    });

    if (this.props.type === 'INSTITUTION') {
      // 如果是机构，那么 GROUP_LEADER 也是普通成员
      const res2 = await getUserList(1, 1000, {
        institution_id: this.props.id,
        permission: UserPermission.GROUP_LEADER,
      });
      this.setState({
        memberList: res2.list.concat(res.list).map((user) => ({
          userId: user.user_id,
          nickname: user.nickname,
          headUrl: user.head_url,
          description: user.description,
          isMine: user.is_mine,
        })),
      });
    } else {
      this.setState({
        memberList: res.list.map((user) => ({
          userId: user.user_id,
          nickname: user.nickname,
          headUrl: user.head_url,
          description: user.description,
          isMine: user.is_mine,
        })),
      });
    }
  }

  locationToUserPage(userId: string) {
    locationToUserPage(userId);
  }

  /** 获取领导成员列表 */
  async getLeaderList() {
    const res = await getUserList(1, 1000, {
      ...(this.props.type === 'GROUP' ? { group_id: this.props.id } : {}),
      ...(this.props.type === 'INSTITUTION' ? { institution_id: this.props.id } : {}),
      permission: this.props.type === 'GROUP' ? UserPermission.GROUP_LEADER : UserPermission.INSTITUTION_LEADER,
    });
    this.setState({
      leaderList: res.list.map((user) => ({
        userId: user.user_id,
        nickname: user.nickname,
        headUrl: user.head_url,
        description: user.description,
        isMine: user.is_mine,
      })),
    });
  }

  render(): React.ReactNode {
    return (
      <div className="c-member-set">
        <Avatar.Group
          maxCount={7}
          maxPopoverTrigger="click"
          size="large"
          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
        >
          {this.state.leaderList.map((member) => (
            <Tooltip key={member.userId} title={member.nickname || member.userId} placement="top">
              <Avatar
                src={member.headUrl}
                style={{ cursor: 'pointer' }}
                onClick={this.locationToUserPage.bind(this, member.userId)}
              />
            </Tooltip>
          ))}
          {this.state.memberList.map((member) => (
            <Tooltip key={member.userId} title={member.nickname || member.userId} placement="top">
              <Avatar
                src={member.headUrl}
                style={{ cursor: 'pointer' }}
                onClick={this.locationToUserPage.bind(this, member.userId)}
              />
            </Tooltip>
          ))}
        </Avatar.Group>
      </div>
    );
  }
}
