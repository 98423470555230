/**
 * @Description:
 * @Author: Kermit
 * @Date: 2022-11-25 13:56:37
 * @LastEditors: Kermit
 * @LastEditTime: 2023-03-10 14:20:26
 */

import { FontColorsOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, FormInstance, Input, message } from 'antd';
import React from 'react';
import './LoginDrawer.css';
import { getCurrVcode, getVcode, login, signUp, verifyUserId } from '@app/api-client';

export interface ILoginDrawerProps {
  isOpen: boolean;
  onClose?: () => void;
  onLogin?: () => void;
}

export interface ILoginDrawerState {
  isLogining: boolean;
  isOpenSignUp: boolean;
  isSignUping: boolean;
  vcode?: string;
}

export default class LoginDrawer extends React.Component<ILoginDrawerProps, ILoginDrawerState> {
  formRef = React.createRef<FormInstance>();
  signUpFormRef = React.createRef<FormInstance>();

  constructor(props) {
    super(props);
    this.state = {
      isLogining: false,
      isOpenSignUp: false,
      isSignUping: false,
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  async onLoginFinish(values: any) {
    try {
      this.setState({ isLogining: true });
      await login(values.userid, values.password, values.vcode);
      this.props.onLogin && this.props.onLogin();
      message.success('登录成功', undefined, () => {
        this.setState({ isLogining: false });
      });
    } catch (err: any) {
      this.formRef.current!.resetFields([this.state.vcode ? 'vcode' : 'password']);
      this.formRef.current!.setFields([
        { name: this.state.vcode ? 'vcode' : 'password', errors: [err?.message || '登录失败'] },
      ]);
      this.setState({ isLogining: false });
      await this.refreshVcode();
    }
  }

  async onSignUpFinish(values: any) {
    try {
      this.setState({ isSignUping: true });
      await signUp(values.userid, values.password, values.vcode);
      message.success('注册成功', undefined, () => {
        this.setState({ isSignUping: false });
      });
      this.closeSignUp();
    } catch (err: any) {
      // err?.message && message.error(err.message);
      this.setState({ isSignUping: false });
      this.refreshVcode();
      this.signUpFormRef.current!.resetFields(['vcode']);
      this.signUpFormRef.current!.setFields([{ name: 'vcode', errors: [err.message] }]);
    }
  }

  openSignUp() {
    this.setState({ isOpenSignUp: true });
  }

  closeSignUp() {
    this.setState({ isOpenSignUp: false });
  }

  async onAfterLoginOpenChange(open: boolean) {
    if (open) {
      await this.getCurrVcode();
    }
  }

  async onAfterSignUpOpenChange(open: boolean) {
    if (open) {
      await this.refreshVcode();
    }
  }

  async verifyUserId(userId: string) {
    const { result } = await verifyUserId(userId);
    return result;
  }

  async refreshVcode() {
    const { vcode } = await getVcode();
    this.setState({ vcode });
  }

  async getCurrVcode() {
    const { vcode } = await getCurrVcode();
    if (!vcode) {
      this.setState({ vcode });
    } else {
      await this.refreshVcode();
    }
  }

  render(): React.ReactNode {
    return (
      <Drawer
        title="登录 AlgoSpace"
        width={378}
        placement="right"
        onClose={this.props.onClose}
        open={this.props.isOpen}
        afterOpenChange={this.onAfterLoginOpenChange.bind(this)}
      >
        <div className="c-login-drawer">
          <div style={{ width: '95%', margin: 'auto' }}>
            <Form name="basic" ref={this.formRef} onFinish={this.onLoginFinish.bind(this)} autoComplete="off">
              <div className="form-title">用户名</div>
              <div className="login-form-input">
                <Form.Item name="userid" rules={[{ required: true, message: '请输入用户名' }]}>
                  <Input
                    disabled={this.state.isLogining}
                    size="middle"
                    name="userid"
                    autoComplete="userid"
                    placeholder="输入用户名"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
              </div>

              <div className="form-title">密码</div>
              <div className="login-form-input">
                <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
                  <Input.Password
                    disabled={this.state.isLogining}
                    size="middle"
                    name="password"
                    autoComplete="current-password"
                    placeholder="输入密码"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              </div>

              {this.state.vcode && (
                <>
                  <div className="form-title">验证码</div>
                  <div className="login-form-input">
                    <Form.Item name="vcode" rules={[{ required: true, message: '请输入验证码' }]}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input
                          disabled={this.state.isLogining}
                          size="middle"
                          placeholder="输入验证码"
                          prefix={<FontColorsOutlined />}
                        />
                        <img
                          style={{
                            background: '#f0f0f0',
                            height: 40,
                            width: 100,
                            borderRadius: 8,
                            border: 0,
                            marginLeft: 8,
                            cursor: 'pointer',
                          }}
                          src={this.state.vcode}
                          onClick={this.refreshVcode.bind(this)}
                        ></img>
                      </div>
                    </Form.Item>
                  </div>
                </>
              )}

              <div className="btn-wrapper">
                <Form.Item>
                  <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isLogining}>
                    {this.state.isLogining ? '登录中...' : '登录'}
                  </Button>
                </Form.Item>
                <Button type="link" block onClick={this.openSignUp.bind(this)}>
                  没有账户？注册一个新用户
                </Button>
              </div>
            </Form>
          </div>
        </div>

        <Drawer
          title="注册新用户"
          width={378}
          afterOpenChange={this.onAfterSignUpOpenChange.bind(this)}
          onClose={this.closeSignUp.bind(this)}
          open={this.state.isOpenSignUp}
        >
          <div className="c-login-drawer">
            <div style={{ width: '95%', margin: 'auto' }}>
              <Form name="basic" ref={this.signUpFormRef} onFinish={this.onSignUpFinish.bind(this)} autoComplete="off">
                <div className="form-title">用户名</div>
                <div className="login-form-input">
                  <Form.Item
                    name="userid"
                    rules={[
                      { required: true, message: '请输入用户名' },
                      {
                        pattern: new RegExp('^[a-zA-Z0-9_\\-@\\.]+$'),
                        message: '用户名只能包含字母、数字和 _ - @ . 字符',
                      },
                      () => ({
                        validator: async (_, value) => {
                          if (value && !(await this.verifyUserId(value))) {
                            return Promise.reject('用户名已被注册');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      disabled={this.state.isLogining}
                      size="middle"
                      name="userid"
                      autoComplete="userid"
                      placeholder="输入用户名"
                      prefix={<UserOutlined />}
                    />
                  </Form.Item>
                </div>

                <div className="form-title">密码</div>
                <div className="login-form-input">
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: '请输入密码' },
                      { min: 6, message: '密码长度不得小于 6 位' },
                      { max: 20, message: '密码长度不得大于 20 位' },
                      {
                        pattern: new RegExp('^[a-zA-Z0-9~@#\\$%\\*_\\-\\+\\=\\:,\\.\\?\\{\\}]+$'),
                        message: '密码仅支持大小写字母与数字、特殊字符 ~@#$%*_-+=:,.?{}',
                      },
                    ]}
                  >
                    <Input.Password
                      disabled={this.state.isLogining}
                      size="middle"
                      name="password"
                      autoComplete="new-password"
                      placeholder="输入密码"
                      prefix={<LockOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="repassword"
                    rules={[
                      { required: true, message: '请输入重复密码' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('两次输入的密码不一致'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      disabled={this.state.isLogining}
                      size="middle"
                      name="repassword"
                      autoComplete="new-password"
                      placeholder="输入重复密码"
                      prefix={<LockOutlined />}
                    />
                  </Form.Item>
                </div>

                <div className="form-title">验证码</div>
                <div className="login-form-input">
                  <Form.Item name="vcode" rules={[{ required: true, message: '请输入验证码' }]}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Input
                        disabled={this.state.isLogining}
                        size="middle"
                        placeholder="输入验证码"
                        prefix={<FontColorsOutlined />}
                      />
                      <img
                        style={{
                          background: '#f0f0f0',
                          height: 40,
                          width: 100,
                          borderRadius: 8,
                          border: 0,
                          marginLeft: 8,
                          cursor: 'pointer',
                        }}
                        src={this.state.vcode}
                        onClick={this.refreshVcode.bind(this)}
                      ></img>
                    </div>
                  </Form.Item>
                </div>

                <div className="btn-wrapper">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isLogining}>
                      {this.state.isLogining ? '注册中...' : '注册'}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Drawer>
      </Drawer>
    );
  }
}
