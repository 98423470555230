/**
 * @Description:
 * @Author: Kermit
 * @Date: 2023-05-01 14:35:23
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-05 18:15:31
 */

import React from 'react';
import './AlgorithmGradio.css';
import { Alert, Button, message } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import IframeResizer from 'iframe-resizer-react';
import { checkIsNeedCustomCookie } from '@app/config/customize';
import { getCookie } from '@app/utils/cookie';
import AlgoEmbedModal from '@app/components/algo-embed-modal/AlgoEmbedModal';
import { AlgoScope } from '@app/constants/algo';

export interface IAlgorithmGradioProps {
  scope?: AlgoScope;
  canCall?: boolean;
  isGradioEmpty?: boolean;
  gradioUrl?: string;
  cardUrl?: string;
}

export interface IAlgorithmGradioState {
  isShowEmbedModal: boolean;
}

export default class AlgorithmGradio extends React.Component<IAlgorithmGradioProps, IAlgorithmGradioState> {
  constructor(props) {
    super(props);
    this.state = {
      isShowEmbedModal: false,
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {}

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  showEmbedModal() {
    this.setState({ isShowEmbedModal: true });
  }

  hideEmbedModal() {
    this.setState({ isShowEmbedModal: false });
  }

  render(): React.ReactNode {
    return (
      <div className="c-algorithm-gradio">
        {this.props.canCall ? (
          !this.props.isGradioEmpty ? (
            <div>
              {this.props.scope === AlgoScope.PUBLIC && (
                <div className="gradio-btn-wrapper">
                  <Button type="text" className="grey" onClick={this.showEmbedModal.bind(this)}>
                    <AppstoreAddOutlined />
                    嵌入我自己的网站
                  </Button>
                </div>
              )}
              <IframeResizer
                src={
                  this.props.gradioUrl +
                  '?__theme=light&asc_session=' +
                  (checkIsNeedCustomCookie() ? getCookie('asc_session') : getCookie('session'))
                } // 强制使用浅色主题，跳过自动登录
                style={{ width: '1px', minWidth: '100%', border: 'none' }}
                heightCalculationMethod="max"
              />
            </div>
          ) : (
            <>
              <Alert
                message="算法服务初始化中"
                description="算法服务正在初始化，请稍等一会儿刷新页面。"
                type="info"
                showIcon
              />
            </>
          )
        ) : (
          <>
            <Alert
              message="提示"
              description="发布者已暂时关闭算法服务，请等待或联系发布者开启。"
              type="warning"
              showIcon
            />
            <div onClick={() => message.warning('发布者已暂时关闭算法服务，请等待或联系发布者开启。')}>
              <iframe
                src={
                  this.props.gradioUrl +
                  '?__theme=light&asc_session=' +
                  (checkIsNeedCustomCookie() ? getCookie('asc_session') : getCookie('session'))
                } // 强制使用浅色主题，跳过自动登录
                width="100%"
                style={{ border: 'none', minHeight: 800, opacity: 0.5, pointerEvents: 'none' }}
              ></iframe>
            </div>
          </>
        )}

        <AlgoEmbedModal
          gradioUrl={this.props.gradioUrl ? this.props.gradioUrl + '?__theme=light' : ''}
          cardUrl={this.props.cardUrl || ''}
          isModalOpen={this.state.isShowEmbedModal}
          onCompleted={this.hideEmbedModal.bind(this)}
        />
      </div>
    );
  }
}
