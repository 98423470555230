/**
 * @Description: 导航栏
 * @Author: Kermit
 * @Date: 2022-11-24 13:40:43
 * @LastEditors: Kermit
 * @LastEditTime: 2023-02-18 16:03:35
 */

import {
  ExperimentFilled,
  HddOutlined,
  HomeOutlined,
  ReadOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoginEntry from '../login-entry/LoginEntry';
import './Header.css';
import { Button } from 'antd';
import { getCurrInstitution } from '@app/config/customize';

export enum Nav {
  EMPTY = '',
  INDEX = 'INDEX',
  INSTITUTION = 'INSTITUTION',
  ALGORITHM = 'ALGORITHM',
  DOCUMENT = 'DOCUMENT',
}

export interface IHeaderProps {
  activeNav?: Nav;
  isLogin?: boolean;
  isWhiteColor?: boolean;
  isTopicBackgroud?: boolean;
  onNavClick?: () => void;
  onLogin?: () => void;
  onLogout?: () => void;
}

export default class Header extends Component<IHeaderProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // do nothing
  }

  render() {
    return (
      <div
        className={`c-header ${this.props.isWhiteColor ? 'white-color' : ''} ${
          this.props.isTopicBackgroud ? 'topic-bg' : ''
        }`}
      >
        <header className="container">
          <Link className="navbar-header" to="/">
            {/* <img src={require('@app/static/images/favicon.ico')} /> */}
            <span>
              {/* 临时占位 Logo */}
              <ExperimentFilled style={{ marginRight: '0.5em' }} />
              <span>{getCurrInstitution() && getCurrInstitution()?.enShortName + ' '}AlgoSpace</span>
            </span>
          </Link>
          <div className="navbar-right">
            <div className="navbar-btn">
              <div
                className={`navbar-item ${this.props.activeNav === Nav.INDEX ? 'active' : ''}`}
                onClick={this.props.onNavClick}
              >
                <Link to="/">
                  <HomeOutlined />
                  <span>首页</span>
                </Link>
              </div>
              <div
                className={`navbar-item ${this.props.activeNav === Nav.ALGORITHM ? 'active' : ''}`}
                onClick={this.props.onNavClick}
              >
                <Link to="/algorithm">
                  <HddOutlined />
                  {!getCurrInstitution() ? <span>算法社区</span> : <span>算法池</span>}
                </Link>
              </div>
              {!getCurrInstitution() && (
                <div
                  className={`navbar-item ${this.props.activeNav === Nav.INSTITUTION ? 'active' : ''}`}
                  onClick={this.props.onNavClick}
                >
                  <Link to="/institution">
                    <TeamOutlined />
                    <span>入驻机构</span>
                  </Link>
                </div>
              )}
              {getCurrInstitution() && (
                <div
                  className={`navbar-item ${this.props.activeNav === Nav.INSTITUTION ? 'active' : ''}`}
                  onClick={this.props.onNavClick}
                >
                  <Link to={`/institution/${getCurrInstitution()?.id}/group`}>
                    <TeamOutlined />
                    <span>研究组</span>
                  </Link>
                </div>
              )}
              <div
                className={`navbar-item ${this.props.activeNav === Nav.DOCUMENT ? 'active' : ''}`}
                onClick={this.props.onNavClick}
              >
                <a target="_blank" href="https://www.wolai.com/algospace/2eUfozRR8sUph3r3dNiUfS" rel="noreferrer">
                  <ReadOutlined />
                  <span>文档</span>
                </a>
              </div>
            </div>
            <div className="navbar-login">
              <LoginEntry></LoginEntry>
            </div>
            <div className="navbar-btn-narrow">
              <Button icon={<UnorderedListOutlined />}></Button>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
