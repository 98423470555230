/**
 * @Description: 研究组设置组件
 * @Author: Kermit
 * @Date: 2022-12-02 17:36:15
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-30 18:29:25
 */

import React from 'react';
import './GroupSetting.css';
import { addMyGroupMember, getGroupInfo, updateMyGroupInfo, uploadHeadPicUrl } from '@app/api-client';
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  Menu,
  MenuProps,
  message,
  Select,
  Tag,
  Upload,
  UploadFile,
} from 'antd';
import {
  UserOutlined,
  PlusOutlined,
  SettingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { checkIsNeedCustomCookie, getCustomCookieHeader } from '@app/config/customize';

export interface IGroupSettingProps {
  groupId: string;
}

export interface IGroupSettingState {
  tab: string;
  isUpdating: boolean;
  isHeadpicFileListInit: boolean;
  headpicFileList: UploadFile[];

  /** 用户研究组名 */
  groupName?: string;
  /** 研究组头像 */
  groupHeadUrl?: string;
  /** 研究组简介 */
  groupDescription?: string;
  groupWebsite?: string;
  groupTags?: string[];
  /** 机构 ID */
  institutionId?: string;
  /** 用户机构名 */
  institutionName?: string;
  /** 机构头像 */
  institutionHeadUrl?: string;
  /** 机构简介 */
  institutionDescription?: string;
  /** 是否我的 */
  isMine?: boolean;
  /** 是否是管理员 */
  isMineLeader?: boolean;

  tagsIndexList: number[];
}

type MenuItem = Required<MenuProps>['items'][number];

export default class GroupSetting extends React.Component<IGroupSettingProps, IGroupSettingState> {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'general',
      isUpdating: false,
      isHeadpicFileListInit: false,
      headpicFileList: [],
      tagsIndexList: [],
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getGroupInfo();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  async getGroupInfo() {
    const res = await getGroupInfo(this.props.groupId);
    this.setState({
      groupName: res.group_name,
      groupHeadUrl: res.head_url,
      groupDescription: res.description,
      groupWebsite: res.website,
      groupTags: res.tags,
      institutionId: res.institution?.institution_id,
      institutionName: res.institution?.institution_name,
      institutionHeadUrl: res.institution?.head_url,
      institutionDescription: res.institution?.description,
      isMine: res.is_mine,
      isMineLeader: res.is_mine_leader,
    });

    this.state.tab === 'general' && this.initGeneralForm(res.group_name, res.description, res.website, res.tags);
    this.state.tab === 'general' && this.initUploadHeadPicFileList(res.head_url);
  }

  getMenuItem(key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem {
    return {
      key,
      label: this.getMenuLabel(key),
      icon,
      children,
      type,
    } as MenuItem;
  }

  getMenuLabel(key: React.Key) {
    switch (key) {
      case 'general':
        return '基本信息';
      case 'new_member':
        return '新增成员';
      default:
        return '';
    }
  }

  /** 菜单栏项 */
  items: MenuItem[] = [
    this.getMenuItem('general', <SettingOutlined />),
    this.getMenuItem('new_member', <UserOutlined />),
  ];

  /** 选择菜单项事件 */
  onSelectTab({ key }) {
    const tab = key;
    this.setState({ tab }, () => {
      tab === 'general' &&
        this.initGeneralForm(
          this.state.groupName || '',
          this.state.groupDescription || '',
          this.state.groupWebsite || '',
          this.state.groupTags || [],
        );
      tab === 'general' && this.initUploadHeadPicFileList(this.state.groupHeadUrl || '');
    });
  }

  /** 基本信息设置表单引用 */
  generalFormRef = React.createRef<FormInstance>();
  /** 初始化基本信息设置表单 */
  initGeneralForm(groupName: string, description: string, website?: string, tags?: string[]) {
    this.generalFormRef.current!.setFieldsValue({
      group_name: groupName,
      description,
      website,
    });
    if (tags) {
      const tagsIndexList = [...Array(Object.keys(tags).length)].map((_, index) => index);
      this.setState({ tagsIndexList }, () => {
        for (const i in tags) {
          const tag = tags[i];
          const [tagContent, tagColor] = tag.split('###');
          this.generalFormRef.current!.setFieldsValue({
            [`tags_content_${i}`]: tagContent,
            [`tags_color_${i}`]: tagColor || '',
          });
        }
      });
    }
  }
  /** 初始化基本信息表单 */
  async onGeneralFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      let headPic: string | undefined = undefined;
      if (this.state.headpicFileList.length > 0) {
        headPic = this.state.headpicFileList[0].url;
      }
      const tags: string[] = [];
      for (const tagIndex of this.state.tagsIndexList) {
        if (values[`tags_color_${tagIndex}`]) {
          tags.push(`${values[`tags_content_${tagIndex}`]}###${values[`tags_color_${tagIndex}`]}`);
        } else {
          tags.push(values[`tags_content_${tagIndex}`]);
        }
      }
      await updateMyGroupInfo(headPic, values.group_name, values.description, values.website, tags);
      message.success('更新成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '更新失败');
    }
  }
  initUploadHeadPicFileList(headUrl: string) {
    const headpicFileList: UploadFile[] = headUrl
      ? [
          {
            uid: '-1',
            name: 'file',
            status: 'done',
            url: headUrl,
          },
        ]
      : [];
    this.setState({ headpicFileList, isHeadpicFileListInit: true });
  }
  onHeadpicFileListChange = ({ fileList }) => {
    this.setState({
      headpicFileList: fileList.map((item) => ({
        url: item.response?.data?.filepath ? uploadHeadPicUrl + '/' + item.response?.data?.filepath : undefined,
        ...item,
      })),
    });
  };

  /** 成员新增设置表单引用 */
  memberFormRef = React.createRef<FormInstance>();
  /** 初始化成员新增表单 */
  async onNewMemberFormFinish(values) {
    try {
      this.setState({ isUpdating: true });
      await addMyGroupMember(values.user_id);
      message.success('新增成员成功', 1, () => {
        this.setState({ isUpdating: false });
        window.location.reload();
      });
    } catch (err: any) {
      this.setState({ isUpdating: false });
      message.error(err.message || '新增成员失败');
    }
  }

  addTagsIndexListNum() {
    this.setState((state) => ({
      tagsIndexList: state.tagsIndexList.concat(
        state.tagsIndexList.length > 0 ? state.tagsIndexList[state.tagsIndexList.length - 1] + 1 : 0,
      ),
    }));
  }

  minusTagsIndexListNum(index: number) {
    const tagsIndexList = this.state.tagsIndexList;
    tagsIndexList.splice(index, 1);
    this.setState({ tagsIndexList });
  }

  render(): React.ReactNode {
    return (
      <div className="group-setting-container">
        <div className="group-setting-menu">
          <Menu
            mode="inline"
            selectedKeys={[this.state.tab]}
            onSelect={this.onSelectTab.bind(this)}
            style={{ width: '100%' }}
            items={this.items}
          />
        </div>
        <div className="group-setting-content">
          <div className="group-setting-content-header">
            <Divider orientation="left" style={{ fontSize: 18 }}>
              {this.getMenuLabel(this.state.tab)}
            </Divider>
          </div>
          <div className="group-setting-content-body">
            {/* 基本信息设置 */}
            {this.state.tab === 'general' && (
              <div className="form-wrapper">
                <Form
                  name="basic"
                  ref={this.generalFormRef}
                  onFinish={this.onGeneralFormFinish.bind(this)}
                  autoComplete="off"
                >
                  <div className="form-title">研究组名</div>
                  <div className="form-input">
                    <Form.Item name="group_name" rules={[{ required: true, message: '请输入研究组名' }]}>
                      <Input size="middle" placeholder="输入研究组名" />
                    </Form.Item>
                  </div>
                  <div className="form-title">头像</div>
                  <div className="form-upload">
                    {this.state.isHeadpicFileListInit && (
                      <Upload
                        name="file"
                        listType="picture-card"
                        accept="image/gif,image/jpeg,image/jpg,image/png"
                        withCredentials
                        defaultFileList={this.state.headpicFileList}
                        action={uploadHeadPicUrl}
                        onChange={this.onHeadpicFileListChange.bind(this)}
                        headers={checkIsNeedCustomCookie() ? getCustomCookieHeader() : {}}
                      >
                        {this.state.headpicFileList.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上传</div>
                          </div>
                        )}
                      </Upload>
                    )}
                  </div>
                  <div className="form-title">研究组简介</div>
                  <div className="form-input">
                    <Form.Item name="description">
                      <Input.TextArea
                        autoSize={{ minRows: 5, maxRows: 10 }}
                        size="middle"
                        placeholder="输入研究组简介（可选）"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-title">研究组网站</div>
                  <div className="form-input">
                    <Form.Item name="website" rules={[{ type: 'url', message: '不是一个有效的 URL 链接' }]}>
                      <Input size="middle" placeholder="输入研究组网站（可选）" />
                    </Form.Item>
                  </div>
                  <div className="form-title">研究组标签</div>
                  <div className="form-input">
                    {this.state.tagsIndexList.map((paramIndex, index) => (
                      <div className="tags-wrapper" key={paramIndex}>
                        <div className="tags-icon" onClick={this.minusTagsIndexListNum.bind(this, index)}>
                          <MinusCircleOutlined />
                        </div>
                        <div className="tags-title">标签 {index + 1}</div>
                        <Form.Item
                          name={`tags_content_${paramIndex}`}
                          rules={[
                            { required: true, message: '请输入标签' },
                            { max: 20, message: '标签长度不能超过 20 字符' },
                          ]}
                        >
                          <Input size="middle" placeholder="输入标签" />
                        </Form.Item>
                        <Form.Item name={`tags_color_${paramIndex}`}>
                          <Select
                            placeholder="选择标签样式"
                            defaultValue=""
                            options={[
                              {
                                value: '',
                                label: <Tag>默认样式</Tag>,
                              },
                              {
                                value: 'magenta',
                                label: <Tag color="magenta">品红色</Tag>,
                              },
                              {
                                value: 'red',
                                label: <Tag color="red">红色</Tag>,
                              },
                              {
                                value: 'orange',
                                label: <Tag color="orange">橙色</Tag>,
                              },
                              {
                                value: 'gold',
                                label: <Tag color="gold">金色</Tag>,
                              },
                              {
                                value: 'green',
                                label: <Tag color="green">绿色</Tag>,
                              },
                              {
                                value: 'cyan',
                                label: <Tag color="cyan">青色</Tag>,
                              },
                              {
                                value: 'blue',
                                label: <Tag color="blue">蓝色</Tag>,
                              },
                              {
                                value: 'purple',
                                label: <Tag color="purple">紫色</Tag>,
                              },
                            ]}
                          ></Select>
                        </Form.Item>
                      </div>
                    ))}
                    <Button
                      type="dashed"
                      style={{ width: '100%', marginBottom: '25px' }}
                      onClick={this.addTagsIndexListNum.bind(this)}
                    >
                      <PlusCircleOutlined />
                      添加一个标签
                    </Button>
                  </div>

                  <div className="btn-wrapper">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                        {this.state.isUpdating ? '更新中...' : '更新'}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}

            {/* 成员新增 */}
            {this.state.tab === 'new_member' && (
              <div className="form-wrapper">
                <Form
                  name="basic"
                  ref={this.memberFormRef}
                  onFinish={this.onNewMemberFormFinish.bind(this)}
                  autoComplete="off"
                >
                  <div className="form-title">输入用户名</div>
                  <div className="form-input">
                    <Form.Item name="user_id" rules={[{ required: true, message: '请输入用户名' }]}>
                      <Input size="middle" placeholder="输入用户名" />
                    </Form.Item>
                  </div>

                  <div className="btn-wrapper">
                    <Form.Item>
                      <Button type="primary" htmlType="submit" block size="large" disabled={this.state.isUpdating}>
                        {this.state.isUpdating ? '提交中...' : '提交'}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
