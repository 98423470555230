/**
 * @Description: 全局布局
 * @Author: Kermit
 * @Date: 2022-11-24 13:15:06
 * @LastEditors: Kermit
 * @LastEditTime: 2023-04-03 15:09:26
 */

import Footer from '@app/components/footer/Footer';
import './GlobalLayout.css';
import Header, { Nav } from '@app/components/header/Header';
import React from 'react';
import { Helmet } from 'react-helmet';

export { Nav } from '@app/components/header/Header';

export interface IGlobalLayoutProps {
  title?: string;
  activeNav?: Nav;
  showFooter?: boolean;
  cancelContainer?: boolean;
  isHeaderWhiteColor?: boolean;
  isHeaderTopicBackgroud?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export default class GlobalLayout extends React.Component<IGlobalLayoutProps> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ overflow: 'hidden' }}>
        {this.props.title && (
          <Helmet>
            <title>{this.props.title} · AlgoSpace</title>
          </Helmet>
        )}

        <Header
          isWhiteColor={this.props.isHeaderWhiteColor}
          isTopicBackgroud={this.props.isHeaderTopicBackgroud}
          activeNav={this.props.activeNav}
        ></Header>
        <div className={this.props.cancelContainer ? '' : 'body'}>{this.props.children}</div>
        {this.props.showFooter && <Footer></Footer>}
      </div>
    );
  }
}
