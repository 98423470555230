/**
 * @Description: 云端托管算法部署过程组件
 * @Author: Kermit
 * @Date: 2022-12-28 16:57:53
 * @LastEditors: Kermit
 * @LastEditTime: 2023-03-16 16:46:56
 */

import React from 'react';
import './CloudDeployProcess.css';
import {
  getAlgoBuildLogWsUrl,
  getAlgoCloudDeployStatus,
  getAlgoDeployLogWsUrl,
  resetAlgoBuildImage,
  resetAlgoDeployed,
  startAlgoBuildImage,
  startAlgoDeploy,
  getAlgoServiceResourceCurve,
} from '@app/api-client';
import { Button, Popconfirm } from 'antd';
import {
  CheckOutlined,
  MinusCircleOutlined,
  PauseOutlined,
  PlayCircleFilled,
  PlayCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import * as echarts from 'echarts';
import { sleep } from '@app/utils';

export interface ICloudDeployProcessProps {
  algoname: string;
  version: string;
  onProcessChange?: (isProcessing: boolean) => void;
}

export interface ICloudDeployProcessState {
  isInit: boolean;

  algoStatus?: string;
  isRunLogMode?: boolean; // 是否是只查看运行日志模式

  buildLog: string;
  lastBuildLogRowNum: number;
  deployLog: string;
  lastDeployLogRowNum: number;
}

export default class CloudDeployProcess extends React.Component<ICloudDeployProcessProps, ICloudDeployProcessState> {
  constructor(props) {
    super(props);
    this.state = {
      isInit: false,
      buildLog: '',
      lastBuildLogRowNum: 0,
      deployLog: '',
      lastDeployLogRowNum: 0,
    };
  }

  /** 生命周期：挂载后 */
  async componentDidMount() {
    await this.initProcess();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 初始化过程 */
  async initProcess() {
    const status = await this.getAlgoStatus();
    if (status === 'deployed') {
      this.setState({ isRunLogMode: true, isInit: true });
      this.getAlgoServiceResourceCurve();
      await this.startGetDeployLog();
    } else if (['built', 'unready'].includes(status)) {
      await this.startProcess();
      this.setState({ isInit: true });
    } else {
      this.setState({ isInit: true });
      await this.startProcess();
    }
  }

  /** 开始构建 */
  async startAlgoBuild(isReset: boolean = false) {
    if (isReset && (await this.getAlgoStatus()) === 'built') {
      await resetAlgoBuildImage(this.props.algoname, this.props.version);
    }
    if ((await this.getAlgoStatus()) === 'unready') {
      this.setState({ buildLog: '', lastBuildLogRowNum: 0, deployLog: '', lastDeployLogRowNum: 0 });
      await startAlgoBuildImage(this.props.algoname, this.props.version);
      await this.startProcess(true); // 过程中自动发起开始部署
    }
  }

  /** 开始部署 */
  async startAlgoDeploy() {
    if ((await this.getAlgoStatus()) === 'built') {
      this.setState({
        deployLog: '',
        lastDeployLogRowNum: 0,
      });
      await startAlgoDeploy(this.props.algoname, this.props.version);
      await this.startProcess();
    }
  }

  /** 停止运行并重置部署 */
  async resetAlgoDeploy() {
    await resetAlgoDeployed(this.props.algoname, this.props.version);
    window.location.reload();
  }

  /** 开始完整过程 */
  async startProcess(isAutoDeploy: boolean = false) {
    if (['built', 'building', 'pending', 'unready'].includes(await this.getAlgoStatus())) {
      await this.startGetBuildLog();
    }
    if (isAutoDeploy && (await this.getAlgoStatus()) === 'built') {
      this.setState({
        deployLog: '',
        lastDeployLogRowNum: 0,
      });
      await startAlgoDeploy(this.props.algoname, this.props.version);
    }
    if (['deployed', 'deploying', 'waiting', 'built'].includes(await this.getAlgoStatus())) {
      await this.startGetDeployLog();
    }
  }

  async getAlgoStatus() {
    const { status } = await getAlgoCloudDeployStatus(this.props.algoname, this.props.version);
    if (this.state.algoStatus !== status) {
      this.props.onProcessChange?.(!['unready', 'built'].includes(status));
    }
    this.setState({ algoStatus: status });
    return status;
  }

  startGetBuildLog() {
    return new Promise<void>((resolve) => {
      const webSocket = new WebSocket(getAlgoBuildLogWsUrl);
      webSocket.onopen = () => {
        webSocket.send(
          JSON.stringify({
            algoname: this.props.algoname,
            version: this.props.version,
            last_log_row_num: this.state.lastBuildLogRowNum,
          }),
        );
      };
      webSocket.onmessage = (e) => {
        const result = JSON.parse(e.data);
        this.setState(
          (state) => ({
            buildLog: state.buildLog + '\n' + result.log,
            lastBuildLogRowNum: result.last_log_row_num,
          }),
          () => this.scrollLogBoxToEnd(),
        );
      };
      webSocket.onclose = async (e) => {
        if (e.code !== 1000 && e.code < 4000) {
          // 非应用的异常码则重新连接
          await this.startGetBuildLog();
        } else {
          while (['building', 'pending'].includes(await this.getAlgoStatus())) {
            await sleep(1000);
          }
        }
        return resolve();
      };
    });
  }

  startGetDeployLog() {
    return new Promise<void>((resolve) => {
      const webSocket = new WebSocket(getAlgoDeployLogWsUrl);
      webSocket.onopen = () => {
        webSocket.send(
          JSON.stringify({
            algoname: this.props.algoname,
            version: this.props.version,
            keep_after_success: this.state.isRunLogMode ? true : '',
            last_log_row_num: this.state.lastDeployLogRowNum,
          }),
        );
      };
      webSocket.onmessage = (e) => {
        const result = JSON.parse(e.data);
        if (result.is_cover_previous) {
          const isNeedScrollToEnd = this.state.lastDeployLogRowNum < result.last_log_row_num;
          this.setState(
            {
              deployLog: result.log,
              lastDeployLogRowNum: result.last_log_row_num,
            },
            () => isNeedScrollToEnd && this.scrollLogBoxToEnd(),
          );
        } else {
          this.setState(
            (state) => ({
              deployLog: state.deployLog + '\n' + result.log,
              lastDeployLogRowNum: result.last_log_row_num,
            }),
            () => this.scrollLogBoxToEnd(),
          );
        }
      };
      webSocket.onclose = async (e) => {
        if (e.code !== 1000 && e.code < 4000) {
          // 非应用的异常码则重新连接
          return await this.startGetDeployLog();
        } else {
          while (['deploying', 'waiting'].includes(await this.getAlgoStatus())) {
            await sleep(1000);
          }
          return resolve();
        }
      };
    });
  }

  scrollLogBoxToEnd() {
    const element = document.getElementsByClassName('log-box');
    if (element) element[0].scrollTop = element[0].scrollHeight;
  }

  async getAlgoServiceResourceCurve() {
    const { list } = await getAlgoServiceResourceCurve(this.props.algoname, this.props.version);
    this.renderCallCurve(
      'cpu',
      list.map((item) => ({ x: item.time, y: item.cpu })),
      list[0].max_cpu + ' 毫核',
    );
    this.renderCallCurve(
      'mem',
      list.map((item) => ({ x: item.time, y: item.mem })),
      list[0].max_mem + ' MB',
    );
  }

  renderCallCurve(type: 'cpu' | 'mem', data: { x: string; y: number }[], max_value: string) {
    const chart = echarts.init(document.getElementById(`curve-${type}`) as HTMLElement);
    chart.setOption({
      title: {
        text: type === 'cpu' ? `CPU 使用（共 ${max_value}）` : `内存使用（共 ${max_value}）`,
      },
      tooltip: {},
      xAxis: {
        data: data.map((item) => item.x),
      },
      yAxis: {},
      series: [
        {
          name: '时间',
          type: 'line',
          symbol: 'none',
          data: data.map((item) => item.y),
          smooth: true,
          itemStyle: {
            color: '#5864ff',
          },
          lineStyle: {
            normal: {
              color: '#5864ff',
              width: 2,
            },
          },
        },
      ],
    });
  }

  render(): React.ReactNode {
    return (
      <div className="c-cloud-deploy-process">
        {this.state.isInit && this.state.algoStatus === 'deployed' && (
          <div className="chart-container">
            <div className="chart-item">
              <div className="chart" id="curve-cpu" />
            </div>
            <div className="chart-item">
              <div className="chart" id="curve-mem" />
            </div>
          </div>
        )}
        <div className="log-container">
          {!this.state.isInit ? (
            <div className="status-box">
              <span>部署信息加载中...</span>
            </div>
          ) : (
            <div className="status-box">
              {['unready'].includes(this.state.algoStatus || '') && !this.state.buildLog && (
                <span>
                  <PlayCircleOutlined style={{ marginRight: '0.5em' }} />
                  待开始部署
                </span>
              )}
              {['unready'].includes(this.state.algoStatus || '') && this.state.buildLog && (
                <span className="red">
                  <WarningOutlined style={{ marginRight: '0.5em' }} />
                  镜像构建失败，请根据下方构建日志调整算法代码和配置，之后重新开始构建和部署
                </span>
              )}
              {['building', 'pending'].includes(this.state.algoStatus || '') && (
                <span>
                  <SyncOutlined spin style={{ marginRight: '0.5em' }} />
                  构建镜像中...
                </span>
              )}
              {['built'].includes(this.state.algoStatus || '') && !this.state.deployLog && (
                <span>
                  <PauseOutlined style={{ marginRight: '0.5em' }} />
                  镜像构建完成，待继续部署容器
                </span>
              )}
              {['built'].includes(this.state.algoStatus || '') && this.state.deployLog && (
                <span className="red">
                  <WarningOutlined style={{ marginRight: '0.5em' }} />
                  容器部署失败，请根据下方部署日志调整算法代码和配置，之后重新开始构建和部署
                </span>
              )}
              {['deploying', 'waiting'].includes(this.state.algoStatus || '') && (
                <span>
                  <SyncOutlined spin style={{ marginRight: '0.5em' }} />
                  部署容器中... 这可能需要几分钟的时间将镜像分发至计算节点
                </span>
              )}
              {['deployed'].includes(this.state.algoStatus || '') && this.state.isRunLogMode && (
                <span>
                  <SyncOutlined spin style={{ marginRight: '0.5em' }} />
                  运行中
                </span>
              )}
              {['deployed'].includes(this.state.algoStatus || '') && !this.state.isRunLogMode && (
                <span>
                  <CheckOutlined style={{ marginRight: '0.5em' }} />
                  部署完成！
                </span>
              )}
            </div>
          )}

          <div className="log-box">
            {this.state.buildLog && (
              <>
                <div>************************ 构建镜像日志 ************************</div>
                <div>{this.state.buildLog}</div>
              </>
            )}
            {this.state.deployLog && (
              <>
                {this.state.isRunLogMode ? (
                  <div>************************ 运行日志 ************************</div>
                ) : (
                  <div>************************ 部署容器日志 ************************</div>
                )}
                <div>{this.state.deployLog}</div>
              </>
            )}
          </div>
        </div>

        {this.state.isInit && this.state.algoStatus === 'unready' && (
          <div className="btn-wrapper">
            <Button type="primary" size="large" style={{ height: 50 }} block onClick={() => this.startAlgoBuild()}>
              <PlayCircleFilled />
              开始构建和部署
            </Button>
          </div>
        )}
        {this.state.isInit && this.state.algoStatus === 'built' && (
          <div className="btn-wrapper">
            <Button
              type={this.state.deployLog ? 'primary' : 'default'}
              size="large"
              style={{ height: 50, marginRight: '0.5em' }}
              block
              onClick={() => this.startAlgoBuild(true)}
            >
              <PlayCircleFilled />
              重新开始构建和部署
            </Button>
            <Button
              type={this.state.deployLog ? 'default' : 'primary'}
              size="large"
              style={{ height: 50 }}
              block
              onClick={this.startAlgoDeploy.bind(this)}
            >
              <PlayCircleFilled />
              直接部署（不重新构建镜像）
            </Button>
          </div>
        )}
        {this.state.isInit && this.state.algoStatus === 'deployed' && (
          <div className="btn-wrapper">
            <Popconfirm
              title={`确认停止运行并重置部署？`}
              onConfirm={() => this.resetAlgoDeploy()}
              okText="确认"
              cancelText="取消"
            >
              <Button type="dashed" danger size="large" style={{ height: 50 }} block>
                <MinusCircleOutlined />
                停止运行并重置部署
              </Button>
            </Popconfirm>
          </div>
        )}
      </div>
    );
  }
}
