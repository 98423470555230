/**
 * @Description: 算法详情页
 * @Author: Kermit
 * @Date: 2022-11-25 16:04:44
 * @LastEditors: Kermit
 * @LastEditTime: 2023-05-02 17:02:14
 */

import React from 'react';
import './AlgorithmDetail.css';
import GlobalLayout, { Nav } from '@app/layout-components/global-layout/GlobalLayout';
import { useParams } from 'react-router-dom';
import { Avatar, Divider, Empty, Result, Tabs, Tag, Typography } from 'antd';
import {
  ApiOutlined,
  AppstoreOutlined,
  BookOutlined,
  ClockCircleOutlined,
  CloudSyncOutlined,
  CommentOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  GithubOutlined,
  LineChartOutlined,
  MinusCircleOutlined,
  SettingOutlined,
  SyncOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { AlgoScope } from '@app/constants/algo';
import { getAlgoItem, getAlgoVersion, requestGetRaw } from '@app/api-client';
import UserCard from '@app/components/user-card/UserCard';
import { UserPermission } from '@app/constants/user';
import { AlgorithmDetailLink, GroupPageLink, InstitutionPageLink, UserPageLink } from '@app/routes/Links';
import { locationToAlgorithmDetailTab } from '@app/routes/location';
import Markdown from '@app/base-components/markdown/Markdown';
import AlgorithmSetting from './algorithm-setting/AlgorithmSetting';
import CloudDeploy from '@app/components/cloud-deploy/CloudDeploy';
import AlgorithmStatistic from './algorithm-statistic/AlgorithmStatistic';
import AlgorithmGradio from './algorithm-gradio/AlgorithmGradio';

export default function AlgorithmDetail(props) {
  const params = useParams();
  return (
    <CAlgorithmDetail
      algoname={params.name as string}
      version={params.version as string}
      tab={params.tab}
      {...props}
    ></CAlgorithmDetail>
  );
}

interface IAlgorithmDetailProps {
  algoname: string;
  version: string;
  tab?: string;
}

export interface IAlgorithmDetailState {
  /** 是否初始化 */
  isInit: boolean;
  /** 是否有权限访问 */
  hasPermission: boolean;

  /** 是否可以调用 */
  canCall?: boolean;
  /** 算法描述 */
  description?: string;
  /** 用户名 */
  username?: string;
  /** 用户昵称 */
  usernickname?: string;
  /** 用户头像 */
  userHeadUrl?: string;
  /** 用户描述 */
  userDescription?: string;
  /** 用户身份 */
  userPermission?: UserPermission;
  /** 机构 ID */
  institutionId?: string;
  /** 用户机构名 */
  institutionName?: string;
  /** 机构头像 */
  institutionHeadUrl?: string;
  /** 机构简介 */
  institutionDescription?: string;
  /** 研究组 ID */
  groupId?: string;
  /** 用户研究组名 */
  groupName?: string;
  /** 研究组头像 */
  groupHeadUrl?: string;
  /** 研究组简介 */
  groupDescription?: string;
  /** 算法可见范围 */
  scope?: AlgoScope;
  /** 创建时间 */
  createTime?: string;
  /** Gradio 应用链接 */
  gradioUrl?: string;
  /** Gradio 网页是否为空 */
  isGradioEmpty?: boolean;
  /** 卡片链接 */
  cardUrl?: string;
  /** 输入参数 */
  inputParam?: any[];
  /** 输出参数 */
  outputParam?: any[];
  /** 中文名 */
  chineseName?: string;
  paper?: string;
  github?: string;
  tasks?: Task[];
  tags?: string[];
  /** 文档 */
  document?: string;
  /** 调用方法 */
  apiMd?: string;
  /** 调用示例 */
  example?: string;
  /** 调用输出示例 */
  outputExample?: string;
  /** 是否是我的 */
  isMine?: boolean;
  /** 是否独立的转发组件 */
  isIndependentComponent?: boolean;

  /** 版本列表 */
  versionList?: string[];
}

type Task = {
  id: string;
  name: string;
  classify: string;
  description: string;
  tag_color: string;
  icon: string;
};

class CAlgorithmDetail extends React.Component<IAlgorithmDetailProps, IAlgorithmDetailState> {
  constructor(props) {
    super(props);
    this.state = {
      isInit: false,
      hasPermission: true,
    };
  }

  /** 生命周期：挂载后 */
  componentDidMount() {
    this.getAlgoInfo();
    this.getAlgoVersion();
  }

  /** 生命周期：卸载前 */
  componentWillUnmount() {}

  /** 获取算法信息 */
  async getAlgoInfo() {
    try {
      const res = await getAlgoItem(this.props.algoname, this.props.version);
      this.setState({
        isInit: true,
        hasPermission: true,
        canCall: res.can_call,
        description: res.description,
        username: res.user.user_id,
        usernickname: res.user.nickname,
        userHeadUrl: res.user.head_url,
        userDescription: res.user.description,
        userPermission: res.user.permission,
        institutionId: res.user.institution?.id,
        institutionName: res.user.institution?.institution_name,
        institutionHeadUrl: res.user.institution?.head_url,
        institutionDescription: res.user.institution?.description,
        groupId: res.user.group?.id,
        groupName: res.user.group?.group_name,
        groupHeadUrl: res.user.group?.head_url,
        groupDescription: res.user.group?.description,
        scope: res.scope,
        createTime: res.create_day,
        gradioUrl: res.gradio_url,
        cardUrl: `https://algospace.top/component/algo-card?name=${encodeURIComponent(
          this.props.algoname,
        )}&version=${encodeURIComponent(this.props.version)}`,
        inputParam: res.input_param,
        outputParam: res.output_param,
        chineseName: res.chinese_name,
        paper: res.paper,
        github: res.github,
        tasks: res.tasks,
        tags: res.tags,
        document: res.document,
        apiMd: res.api_md,
        example: res.example,
        outputExample: res.output_example,
        isMine: res.is_mine,
        isIndependentComponent: res.is_independent_component,
      });
      // 检测 Gradio 网页是否为空
      const isGradioEmpty = !Boolean(await requestGetRaw(res.gradio_url));
      this.setState({ isGradioEmpty });
    } catch (e) {
      this.setState({ isInit: true, hasPermission: false });
    }
  }

  /** 获取算法版本 */
  async getAlgoVersion() {
    const { list } = await getAlgoVersion(this.props.algoname);
    this.setState({
      versionList: list,
    });
  }

  getTabItems() {
    const items = [
      {
        label: (
          <span>
            <AppstoreOutlined />
            演示
          </span>
        ),
        key: '',
      },
      {
        label: (
          <span>
            <ApiOutlined />
            API
          </span>
        ),
        key: 'api',
      },
      {
        label: (
          <span>
            <BookOutlined />
            文档
          </span>
        ),
        key: 'document',
      },
      {
        label: (
          <span>
            <CommentOutlined />
            讨论
          </span>
        ),
        key: 'forum',
      },
    ];
    if (this.state.isMine) {
      items.push(
        {
          label: (
            <span>
              <LineChartOutlined />
              数据
            </span>
          ),
          key: 'statistic',
        },
        {
          label: (
            <span>
              <CloudSyncOutlined />
              托管
            </span>
          ),
          key: 'cloud',
        },
        {
          label: (
            <span>
              <SettingOutlined />
              设置
            </span>
          ),
          key: 'setting',
        },
      );
    }
    return items;
  }

  onTabClick(key: string) {
    locationToAlgorithmDetailTab(this.props.algoname, this.props.version, key);
  }

  private getScopeName(scope: AlgoScope) {
    if (scope === AlgoScope.PUBLIC) {
      return '公开';
    } else if (scope === AlgoScope.INSTITUTION) {
      return '机构';
    } else if (scope === AlgoScope.GROUP) {
      return '研究组';
    } else {
      return '个人';
    }
  }

  render(): React.ReactNode {
    const tag = (
      <div>
        <Tag icon={<ClockCircleOutlined />}>{this.state.createTime}</Tag>
        {this.state.canCall ? (
          <Tag icon={<SyncOutlined spin />} color="success">
            运行中
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            已关闭
          </Tag>
        )}
        {this.state.scope === AlgoScope.PUBLIC ? (
          <Tag color="blue">{this.getScopeName(this.state.scope)}</Tag>
        ) : this.state.scope === AlgoScope.PRIVATE ? (
          <Tag color="magenta">{this.getScopeName(this.state.scope)}</Tag>
        ) : (
          <Tag color="purple">{this.getScopeName(this.state.scope || AlgoScope.PUBLIC)}</Tag>
        )}
      </div>
    );

    return (
      <GlobalLayout
        activeNav={Nav.EMPTY}
        isHeaderTopicBackgroud
        isHeaderWhiteColor
        title={(this.state.chineseName || this.props.algoname) + '_' + this.props.version}
      >
        <div className="p-algorithm-detail">
          {this.state.hasPermission ? (
            <>
              {/* 顶栏 */}
              <div className="top-container">
                <div className="detail-wrapper">
                  {/* 名字 */}
                  <div className="algo-body-box">
                    {this.state.institutionName && (
                      <>
                        <InstitutionPageLink institutionId={this.state.institutionId || ''}>
                          <span className="algo-body-item-institution">
                            <span className="icon">
                              {this.state.institutionHeadUrl ? (
                                <Avatar size="small" src={this.state.institutionHeadUrl} />
                              ) : (
                                <Avatar size="small" icon={<TeamOutlined />} />
                              )}
                            </span>
                            <span>{this.state.institutionName}</span>
                          </span>
                        </InstitutionPageLink>
                        <span className="divider">/</span>
                        {this.state.groupName && (
                          <>
                            <GroupPageLink groupId={this.state.groupId || ''}>
                              <span className="algo-body-item-group">{this.state.groupName}</span>
                            </GroupPageLink>
                            <span className="divider">/</span>
                          </>
                        )}
                      </>
                    )}
                    <UserPageLink userId={this.state.username || ''}>
                      <span className="algo-body-item-user">
                        {/* 没有机构的话就显示自己的头像 */}
                        {!this.state.institutionHeadUrl &&
                          (this.state.userHeadUrl ? (
                            <span className="icon">
                              <Avatar size="small" src={this.state.userHeadUrl} />
                            </span>
                          ) : (
                            <span className="icon">
                              <Avatar size="small" icon={<UserOutlined />} />
                            </span>
                          ))}
                        <span>{this.state.usernickname || this.state.username}</span>
                      </span>
                    </UserPageLink>
                    <span className="divider">/</span>
                    <span className="algo-body-item-algo">
                      <span className="icon">
                        <DatabaseOutlined />
                      </span>
                      <span>{this.state.chineseName || this.props.algoname}</span>
                      <span className="algo-body-item-algo-version">
                        <Tag>{this.props.version}</Tag>
                      </span>
                    </span>
                  </div>
                  {/* 标签 */}
                  <div className="algo-tag-box">{tag}</div>
                </div>

                <div className="tab-wrapper">
                  <Tabs
                    activeKey={this.props.tab || ''}
                    // type="card"
                    onTabClick={this.onTabClick.bind(this)}
                    animated={false}
                    items={this.getTabItems()}
                  />
                </div>
              </div>

              {/* 主栏 */}
              <div className="algo-detail-container">
                {/* 左侧栏 */}
                <div className={`left-container ${this.props.tab === 'setting' ? ' full-width' : ''}`}>
                  {/* 演示 */}
                  {!this.props.tab && this.state.gradioUrl && (
                    <AlgorithmGradio
                      scope={this.state.scope}
                      canCall={this.state.canCall}
                      isGradioEmpty={this.state.isGradioEmpty}
                      gradioUrl={this.state.gradioUrl}
                      cardUrl={this.state.cardUrl}
                    />
                  )}

                  {/* 调用 */}
                  {this.props.tab === 'api' && (
                    <div className="api-box">
                      <Markdown>{this.state.apiMd || ''}</Markdown>
                    </div>
                  )}

                  {/* 文档 */}
                  {this.props.tab === 'document' &&
                    (this.state.document ? (
                      <div className="document-box">
                        <Markdown>{this.state.document}</Markdown>
                      </div>
                    ) : (
                      <div className="document-empty">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>发布者未编写文档</span>} />
                      </div>
                    ))}

                  {/* 讨论 */}
                  {this.props.tab === 'forum' && (
                    <div className="document-empty">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>暂未开放</span>} />
                    </div>
                  )}

                  {/* 数据 */}
                  {this.props.tab === 'statistic' && this.state.isInit && (
                    <AlgorithmStatistic
                      isIndependentComponent={this.state.isIndependentComponent}
                      algoname={this.props.algoname}
                      version={this.props.version}
                    ></AlgorithmStatistic>
                  )}

                  {/* 托管 */}
                  {this.props.tab === 'cloud' && (
                    <CloudDeploy algoname={this.props.algoname} version={this.props.version}></CloudDeploy>
                  )}

                  {/* 设置 */}
                  {this.props.tab === 'setting' && (
                    <AlgorithmSetting algoname={this.props.algoname} version={this.props.version}></AlgorithmSetting>
                  )}
                </div>

                {/* 右侧栏 */}
                {this.props.tab !== 'setting' && (
                  <div className="right-container">
                    {((this.state.tasks && this.state.tasks.length > 0) ||
                      (this.state.tags && this.state.tags.length > 0)) && (
                      <div className="content-box tags-line">
                        {this.state.tasks?.map((task) => (
                          <Tag color={task.tag_color} key={task.id}>
                            {task.name}
                          </Tag>
                        ))}
                        {this.state.tags?.map((tag) => {
                          const [tagContent, tagColor] = tag.split('###');
                          return (
                            <Tag color={tagColor} key={tag}>
                              {tagContent}
                            </Tag>
                          );
                        })}
                      </div>
                    )}

                    <Divider orientation="left">描述</Divider>
                    <div className="content-box algo-desc-box">
                      <Typography.Paragraph>
                        <div dangerouslySetInnerHTML={{ __html: this.state.description || '暂无描述' }}></div>
                      </Typography.Paragraph>
                    </div>
                    {this.state.github && (
                      <div className="content-box algo-desc-line text-cut">
                        <GithubOutlined />
                        <span className="detail-text">
                          <a target="_blank" rel="noreferrer" href={this.state.github}>
                            {this.state.github}
                          </a>
                        </span>
                      </div>
                    )}
                    {this.state.paper && (
                      <div className="content-box algo-desc-line text-cut">
                        <FileTextOutlined />
                        <span className="detail-text">
                          <a target="_blank" rel="noreferrer" href={this.state.paper}>
                            {this.state.paper}
                          </a>
                        </span>
                      </div>
                    )}

                    <Divider orientation="left">发布者</Divider>
                    <div className="content-box author-box">
                      <UserCard
                        username={this.state.username}
                        usernickname={this.state.usernickname}
                        userHeadUrl={this.state.userHeadUrl}
                        userDescription={this.state.userDescription}
                        userPermission={this.state.userPermission}
                        institutionId={this.state.institutionId}
                        institutionHeadUrl={this.state.institutionHeadUrl}
                        institutionName={this.state.institutionName}
                        institutionDescription={this.state.institutionDescription}
                        groupId={this.state.groupId}
                        groupHeadUrl={this.state.groupHeadUrl}
                        groupName={this.state.groupName}
                        groupDescription={this.state.groupDescription}
                      />
                    </div>

                    <Divider orientation="left">版本</Divider>
                    <div className="content-box algo-history-box">
                      {this.state.versionList &&
                        this.state.versionList.map((version) => (
                          <AlgorithmDetailLink
                            key={version}
                            name={this.props.algoname}
                            version={version}
                            reloadDocument={true}
                          >
                            <span className="op-hover">
                              <Tag>{version}</Tag>
                            </span>
                          </AlgorithmDetailLink>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Result status="403" title="403 无权限" subTitle="没有访问该算法的权限，请登录有权限的用户后再试。" />
            </>
          )}
        </div>
      </GlobalLayout>
    );
  }
}
