/**
 * @Description
 * @Author Kermit
 * @Date 2022-11-29 09:43:55
 * @LastEditors Kermit
 * @LastEditTime 2023-05-02 16:44:29
 */

/** Sleep */
export const sleep = (ms: number) => {
  return new Promise<void>((resolve) => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      resolve();
    }, ms);
  });
};

/** 复制到剪贴板 */
export function copyText(text: string) {
  const el = document.createElement('input');
  el.setAttribute('value', text);
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

/** 获取 query 值 */
export function getQueryString(name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}
